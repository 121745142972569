import { entry as arrayLayoutRendererEntry } from './arrayLayoutRenderer';
import { entry as verticalLayoutRendererEntry } from './verticalLayoutRenderer';
import { entry as horizontalLayoutRendererEntry } from './horizontalLayoutRenderer';
import { entry as groupRendererEntry } from './groupLayoutRenderer';

export { arrayLayoutRendererEntry, verticalLayoutRendererEntry, horizontalLayoutRendererEntry, groupRendererEntry };

export const layoutRenderers = [
  arrayLayoutRendererEntry,
  verticalLayoutRendererEntry,
  horizontalLayoutRendererEntry,
  groupRendererEntry,
];
