import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-09310ebd"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "label-block"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dispatch_renderer = _resolveComponent("dispatch-renderer")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_ctx.layout.visible)
    ? (_openBlock(), _createBlock(_component_q_card, {
        key: 0,
        flat: "",
        style: {"width":"100%"},
        class: "q-py-none q-px-sm"
      }, {
        default: _withCtx(() => [
          (_ctx.layout.label)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.layout.label), 1))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.layout.uischema.elements, (element, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: `${_ctx.layout.path}-${index}`,
              class: "q-px-none q-mb-md q-pb-md"
            }, [
              _createVNode(_component_dispatch_renderer, {
                schema: _ctx.layout.schema,
                uischema: element,
                path: _ctx.layout.path,
                enabled: _ctx.layout.enabled,
                renderers: _ctx.layout.renderers,
                cells: _ctx.layout.cells,
                manifest: _ctx.manifest,
                freezeManifest: _ctx.freezeManifest,
                currentEnv: _ctx.currentEnv,
                networkPermissions: _ctx.networkPermissions,
                cloudentePermissions: _ctx.cloudentePermissions,
                approvalGroupState: _ctx.approvalGroupState,
                disableUpdateOpass: _ctx.disableUpdateOpass,
                dense: _ctx.dense,
                showFilters: _ctx.showFilters,
                uiConfig: _ctx.uiConfig
              }, null, 8, ["schema", "uischema", "path", "enabled", "renderers", "cells", "manifest", "freezeManifest", "currentEnv", "networkPermissions", "cloudentePermissions", "approvalGroupState", "disableUpdateOpass", "dense", "showFilters", "uiConfig"])
            ]))
          }), 128))
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}