<template>
  <div class="environments" v-if="loadData" :class="{ 'read-widget': mode === 'read' }">
    <div class="flex items-center justify-between q-mb-md block-label">
      <span class="label-block">Environments:</span>
      <q-btn
        v-if="mode === 'edit'"
        flat
        no-caps
        class="q-ml-md btn--no-hover text-weight-regular"
        round
        color="light-blue-5"
        size="15px"
        style="min-height: 30px"
        :disabled="addEnvError?.length"
        :ripple="false"
        @click="showForm = true"
      >
        + Add Environment
        <q-tooltip v-if="addEnvError?.length">{{ addEnvError }}</q-tooltip>
      </q-btn>
    </div>
    <div v-if="showForm" class="env-form hide-label">
      <div class="flex items-center no-wrap">
        <div class="full-width">
          <q-banner v-if="generalErrors.length" class="text-white approval-error-banner q-mt-sm q-mb-sm">
            <span class="block" v-for="(error, key) in generalErrors" :key="key">{{ error.message }}</span>
          </q-banner>
          <json-forms
            :data="formData"
            :renderers="renderers"
            :schema="schema"
            :uischema="uiSchema"
            @change="onChange"
          />
        </div>

        <div class="btns flex items-center justify-end q-ml-auto q-mb-sm">
          <q-btn
            @click="send"
            no-caps
            text-color="dark"
            :loading="loadSend"
            :disabled="
              environments.length === formData.environments.length ||
              storedEnvironments().length === formData.environments.length ||
              loadSend ||
              Boolean(formErrors.length) ||
              Boolean(errors.length) ||
              Boolean(generalErrors.length)
            "
            color="primary"
            >Save</q-btn
          >
          <q-btn @click="cancel" class="btn--no-hover" no-caps flat>Cancel</q-btn>
        </div>
      </div>
    </div>
    <ul v-if="environments.length || storedEnvironments().length">
      <li class="q-mb-xs" v-for="(environment, key) in environments" :key="key">
        {{ environment.value }}
        <StatusCell showLabel :status="environment.status" class="q-ml-auto" />
        <q-btn
          flat
          round
          size="12px"
          icon="sym_r_work_history"
          style="width: 30px; height: 40px"
          class="btn--no-hover q-ml-md"
          @click="showWorkflow(environment.id)"
        >
          <q-tooltip>Show related workflows</q-tooltip>
        </q-btn>
        <q-btn
          v-if="mode === 'edit' && !isShowOffboard"
          class="btn--no-hover q-ml-sm"
          size="12px"
          icon="sym_o_delete"
          :disabled="disableOffboardEnvs[environment.value]"
          flat
          padding="0"
          :ripple="false"
          @click="openConfirmationPopUp(environment)"
        >
          <q-tooltip v-if="disableOffboardEnvs[environment.value]" style="white-space: pre-line">{{
            disableOffboardEnvs[environment.value]
          }}</q-tooltip>
          <q-tooltip v-else>Offboard</q-tooltip>
        </q-btn>
      </li>
      <li class="q-mb-xs" v-for="(environment, key) in storedEnvironments()" :key="key">
        {{ environment }}
        <q-spinner size="18px" class="q-ml-auto"> </q-spinner>
        <q-tooltip>Workflow in the process of creation... </q-tooltip>
      </li>
    </ul>

    <q-dialog v-model="confirmOffboardPopup" class="single-service-page" persistent>
      <q-card style="min-width: 768px; border-radius: 0px">
        <q-toolbar class="shadow-2 q-py-sm q-px-md">
          <q-toolbar-title class="text-weight-bold">
            <strong>Are you sure?</strong>
          </q-toolbar-title>
          <q-btn icon="close" round flat class="btn--no-hover" @click="confirmOffboardPopup = false"></q-btn>
        </q-toolbar>
        <q-card-section class="q-mx-md q-px-none q-pb-none" style="border-top: 2px solid var(--q-primary)">
          <p>
            You are about to offboard <strong>{{ this.registrationData.infrastructureServiceApm }}</strong> from
            <strong>{{ this.registrationData.digitalServiceApm }}</strong> on
            <strong>{{ this.offboardEnv.value }}</strong
            >. Please, keep in mind that before you can offboard application, you should:
          </p>
          <ul>
            <li>
              Remove all access products in SNOW. PLease read more
              <a target="_blank" href="https://wiki.swissre.com/pages/viewpage.action?pageId=519249684">here</a>
            </li>
            <li v-if="['CLOUDENTE', 'NETWORK'].includes(this.registrationData.infrastructureServiceApm)">
              Remove all attached vnets/external-dns records (if exists)
            </li>
          </ul>
          <p>If above conditions are met, you can proceed with this action.</p>
        </q-card-section>
        <q-card-actions class="q-pb-md">
          <q-btn color="primary" @click="offboardRegistration" size="md" class="q-ml-sm" no-caps
            >Confirm conditions are met</q-btn
          >
          <q-btn flat size="md" class="btn--no-hover" style="height: 40px" no-caps @click="confirmOffboardPopup = false"
            >No</q-btn
          >
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { JsonForms } from '@jsonforms/vue';
import emitter from 'tiny-emitter/instance';
import { quasarRenderers } from '@/shared/jsonformsQuasarRenderers/renderers';
import { createWorkFlow, getRegistration, subValWorkflow, validateWorkFlow } from '../api/api';
import StatusCell from './registration-network/components/StatusCell.vue';

const renderers = [...quasarRenderers];

export default {
  name: 'EnvRegistration',
  components: {
    JsonForms,
    StatusCell,
  },
  props: {
    registrationId: {
      type: String,
    },
    registrationData: {
      type: Object,
    },
    envs: {
      type: Array,
    },
    existEnvironments: {
      type: [Array, undefined],
    },
    mode: {
      type: String,
      default: () => 'read',
    },
  },
  data() {
    return {
      submitDisable: true,
      offboardEnv: undefined,
      confirmOffboardPopup: false,
      loadData: false,
      showForm: false,
      renderers: Object.freeze(renderers),
      schema: {
        description: 'A set of attributes defining the state of an object',
        type: 'object',
        properties: {
          environments: {
            type: 'array',
            uniqueItems: true,
            items: {
              type: 'string',
              enum: this.envs,
            },
          },
        },
      },
      uiSchema: {
        type: 'VerticalLayout',
        elements: [
          {
            type: 'Control',
            scope: '#/properties/environments',
          },
        ],
      },
      environments: [],
      formData: {
        environments: [],
      },
      addEnvError: undefined,
      disableOffboardEnvs: {},
      formErrors: [],
      errors: [],
      generalErrors: [],
      loadSend: false,
    };
  },
  computed: {
    newEnvs() {
      return this.formData.environments
        .filter((env) => !this.environments.find((e) => env === e.value))
        .filter((env) => !this.storedEnvironments().find((e) => env === e));
    },
    isShowOffboard() {
      return ['DBAAS', 'MAS', 'APIMGMT'].includes(this.registrationData.infrastructureServiceApm);
    },
    infraServiceApmId() {
      switch (this.registrationData.infrastructureServiceApm) {
        case 'CLOUDENTE':
          return 'cloudente';
        case 'APIMGMT':
          return 'apimgmt';
        default:
          return 'default';
      }
    },
  },
  methods: {
    openConfirmationPopUp(env) {
      this.confirmOffboardPopup = true;
      this.offboardEnv = env;
    },
    offboardRegistration() {
      const offboardRegistrationPayload = {
        workflowId: null,
        inputVariables: {
          digitalServiceApmId: this.registrationData.digitalServiceApm,
          environmentId: this.offboardEnv.value,
        },
      };

      if (this.registrationData.infrastructureServiceApm === 'NETWORK') {
        offboardRegistrationPayload.workflowId = 'engprt.offboard-application-network';
      } else if (this.registrationData.infrastructureServiceApm === 'CLOUDENTE') {
        offboardRegistrationPayload.workflowId = 'engprt.offboard-application-cloudente';
        delete offboardRegistrationPayload.inputVariables.environmentId;
      } else {
        offboardRegistrationPayload.workflowId = 'engprt.offboard-application-default';
        offboardRegistrationPayload.inputVariables.infraServiceApmId = this.registrationData.infrastructureServiceApm;
      }

      createWorkFlow(offboardRegistrationPayload)
        .then(() => {
          this.$notify('positive', 'Success', 'Offboarding started successfull.');
        })
        .catch((error) => {
          this.$notify('negative', 'Error', error.message);
        })
        .finally(async () => {
          this.confirmOffboardPopup = false;
          await this.load();
        });
    },
    storedEnvironments() {
      const storedData = JSON.parse(localStorage.getItem('registrationData')) || {};
      return storedData[this.registrationId] || [];
    },
    showWorkflow(id) {
      this.$router.push(`/workflows?itemId=${id}`);
    },
    cancel() {
      this.showForm = false;
      this.formData.environments = [];
      this.generalErrors = [];
    },
    onChange(value) {
      this.formErrors = value.errors;
      if (JSON.stringify(value.data) !== JSON.stringify(this.formData) && !value.errors.length) {
        this.formErrors = value.errors;
        this.formData = value.data;
        this.loadSend = true;
        if (this.validationTimeout) clearTimeout(this.validationTimeout);
        this.validationTimeout = setTimeout(async () => {
          const promises = this.newEnvs.map(async (env) => {
            return validateWorkFlow({
              workflowId: `engprt.onboard-application-${this.infraServiceApmId}`,
              inputVariables: {
                digitalServiceApmId: this.registrationData.digitalServiceApm,
                infraServiceApmId: this.registrationData.infrastructureServiceApm,
                environmentId: env,
              },
            })
              .then(() => {
                this.errors = [];
                this.generalErrors = [];
                this.submitDisable = false;
              })
              .catch((error) => {
                if (error?.response?.data?.errors?.length) {
                  this.errors = error.response.data.errors.filter((error) => error.instancePath.length);
                  this.generalErrors =
                    error.response.data.errors.filter(
                      (error) => !error.instancePath.length && error?.params.toDisplay,
                    ) || [];
                } else {
                  this.$notify('negative', 'Error', error.message);
                  this.errors = [];
                  this.generalErrors = [];
                  clearTimeout(this.validationTimeout);
                }
              });
          });
          await Promise.all([...promises]).finally(() => {
            this.loadSend = false;
          });
        }, 3000);
      }
    },
    async load() {
      this.$showLoading();
      this.loadData = false;
      try {
        if (Array.isArray(this.existEnvironments)) {
          this.environments = JSON.parse(JSON.stringify(this.existEnvironments));
          this.formData.environments = this.environments.map((env) => env.value);
        } else {
          const data = await getRegistration(this.registrationId, true, false);
          this.formData.environments = data.environments.map((env) => env.value);
          this.environments = data.environments.map((env) => ({ status: env.status, value: env.value, id: env.id }));
        }
        let storedData = JSON.parse(localStorage.getItem('registrationData')) || {};
        let storedEnvs = storedData[this.registrationId] || [];

        storedData[this.registrationId] = storedEnvs.filter((env) => !this.environments.some((e) => e.value === env));

        if (storedData[this.registrationId].length === 0) {
          delete storedData[this.registrationId];
        }

        this.formData.environments = [...new Set([...this.formData.environments, ...storedEnvs])];

        localStorage.setItem('registrationData', JSON.stringify(storedData));
        if (this.mode === 'edit') {
          const offboardEnvPromises = this.environments.map((env) => {
            const offboardPayload = {
              workflowId: null,
              inputVariables: {
                digitalServiceApmId: this.registrationData.digitalServiceApm,
                environmentId: env.value,
              },
            };

            if (this.registrationData.infrastructureServiceApm === 'NETWORK') {
              offboardPayload.workflowId = 'engprt.offboard-application-network';
            } else if (this.registrationData.infrastructureServiceApm === 'CLOUDENTE') {
              offboardPayload.workflowId = 'engprt.offboard-application-cloudente';
              delete offboardPayload.inputVariables.environmentId;
            } else {
              offboardPayload.workflowId = 'engprt.offboard-application-default';
              offboardPayload.inputVariables.infraServiceApmId = this.registrationData.infrastructureServiceApm;
            }
            return validateWorkFlow(offboardPayload).catch((error) => {
              this.disableOffboardEnvs[env.value] = error.message;
            });
          });
          const subValPromise = subValWorkflow({
            workflowId: `engprt.onboard-application-${this.infraServiceApmId}`,
            inputVariables: {
              digitalServiceApmId: this.registrationData.digitalServiceApm,
              infraServiceApmId: this.registrationData.infrastructureServiceApm,
            },
            subSchemaId: 'add-button',
          })
            .then(() => {
              this.addEnvError = undefined;
            })
            .catch((error) => {
              this.addEnvError = error.message;
            });
          await Promise.all([...offboardEnvPromises, subValPromise]);
        }
      } catch (error) {
        this.$notify('negative', 'Error', error.message);
      } finally {
        this.loadData = true;
        this.$hideLoading();
      }
    },
    async send() {
      this.loadSend = true;
      const promises = this.newEnvs.map((env) => {
        const data = {
          workflowId: `engprt.onboard-application-${this.infraServiceApmId}`,
          inputVariables: {
            digitalServiceApmId: this.registrationData.digitalServiceApm,
            infraServiceApmId: this.registrationData.infrastructureServiceApm,
            environmentId: env,
          },
        };
        return createWorkFlow(data)
          .then(() => {
            this.$notify('positive', 'Success', `${env} environment was added successfull.`);
            let storedData = JSON.parse(localStorage.getItem('registrationData')) || {};

            storedData[this.registrationId] = storedData[this.registrationId] || [];
            if (!storedData[this.registrationId].includes(env)) {
              storedData[this.registrationId].push(env);
            }
            localStorage.setItem('registrationData', JSON.stringify(storedData));
          })
          .catch((error) => {
            this.$notify('negative', 'Error', error.message);
          });
      });
      await Promise.all(promises).finally(async () => {
        this.loadSend = false;
        this.showForm = false;
        await this.load();
      });
    },
  },
  async mounted() {
    await this.load();
  },
  created() {
    emitter.on('reloadEnvironments', async () => {
      await this.load();
    });
  },
  unmounted() {
    emitter.off('reloadEnvironments');
  },
};
</script>

<style lang="scss" scoped>
.label-block {
  font-size: 20px;
  font-weight: bold;
}
.btns {
  min-width: 250px;
}
.env-form {
  background-color: var(--q-accent);
  padding-top: 8px;
  margin-bottom: 15px;
  border-top: 1px solid $grey-4;
  border-bottom: 1px solid $grey-4;
}
.environments {
  &.read-widget {
    .block-label {
      margin-bottom: 5px;
    }
    li {
      padding: 10px;
    }
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
    padding: 0;
  }

  li {
    width: calc(33.333% - 10px);
    margin: 0 5px;
    font-weight: bold;
    padding: 25px;
    border: 1px solid $grey-4;
    border-radius: 10px;
    display: flex;
    align-items: center;

    @media (max-width: $breakpoint-sm-max) {
      width: 100%;
      margin-bottom: 5px;
    }
  }
}
</style>
