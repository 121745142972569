
import { ControlElement } from '@jsonforms/core';
import { defineComponent } from 'vue';
import { rendererProps, useJsonFormsControl, RendererProps } from '@jsonforms/vue';

const controlRenderer = defineComponent({
  name: 'string-control-renderer',
  components: {},
  props: {
    ...rendererProps<ControlElement>(),
    itemLabel: {
      type: String,
    },
  },
  data() {
    return {
      disable: false,
    };
  },
  mounted() {
    if (this.control?.data?.length && !this.control?.uischema?.options?.editable) {
      this.disable = true;
    }
    this.onChange(this.control.data);
  },
  methods: {
    onChange(value: any) {
      if (this.control?.uischema?.options?.toUppercase) {
        this.handleChange(this.control.path, value ? value.toUpperCase() : undefined);
      } else {
        this.handleChange(this.control.path, value);
      }
    },
  },
  computed: {
    computedErrors() {
      return this.control.errors;
    },
  },
  setup(props: RendererProps<ControlElement>) {
    return useJsonFormsControl(props);
  },
});

export default controlRenderer;
