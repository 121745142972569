import axios from 'axios';
import msalInstance from './apollo';

const apiPrefixServiceCatalog = `${process.env.VUE_APP_API_HOST}` || '/service-catalog-api/v1';
const apiPrefixBackbone = process.env.VUE_APP_API_BACKBONE || 'http://3000/backbone';

const DEFAULT_HEADERS = {
  Accept: 'application/json',
};

// eslint-disable-next-line
let resolveHandleRedirect: any = () => {};
// eslint-disable-next-line
let rejectHandleRedirect: any = () => {};

msalInstance.msalInstance
  .handleRedirectPromise()
  .then((response: any) => resolveHandleRedirect(response && response.account))
  .catch((error: any) => rejectHandleRedirect(error));

const waitForMsalHandleRedirect = new Promise((resolve, reject) => {
  resolveHandleRedirect = resolve;
  rejectHandleRedirect = reject;
});

const clientId = process.env.VUE_APP_AAD_CLIENT_ID;

const request = {
  scopes: [`${clientId}/Profile.Read`],
};

async function getTokenOrRedirect() {
  const accountAfterRedirect = await waitForMsalHandleRedirect;

  // get account back from redirect or first account from MSAL
  const account = accountAfterRedirect || msalInstance.msalInstance.getAllAccounts().shift();

  if (!account) {
    // redirect to microsoft - code never continues after this call
    await msalInstance.msalInstance.acquireTokenRedirect(request);
  }

  // get token from cache or get token from microsoft with refresh token
  // or get new pair of tokens from microsoft if session is alive but tokens expired
  const tokenData = await msalInstance.msalInstance
    .acquireTokenSilent({
      ...request,
      account,
    })
    .catch(async () => {
      // or make user to log in
      await msalInstance.msalInstance.acquireTokenRedirect(request);
    });

  return tokenData.accessToken;
}

export async function callServiceCatalog(url: string, options = {}) {
  const token = await getTokenOrRedirect();
  const response = await axios(
    apiPrefixServiceCatalog + url,
    Object.assign(options, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }),
  ).catch((error: any) => {
    if (error.response && error.response.data && error.response.data.message) {
      error.message = error.response.data.message;
    } else {
      error.message = 'Service is unavailable right now';
    }

    throw error;
  });

  return response.data;
}

export async function getServiceCatalog(url: string, params = {}, headers = {}) {
  return callServiceCatalog(url, {
    method: 'get',
    params,
    headers: {
      ...DEFAULT_HEADERS,
      ...headers,
    },
  });
}

export async function postServiceCatalog(url: string, data = {}, headers = {}) {
  return callServiceCatalog(url, {
    method: 'post',
    dataType: 'json',
    data,
    headers: {
      ...DEFAULT_HEADERS,
      ...headers,
    },
  });
}

export async function callBackbone(url: string, options = {}) {
  const token = await getTokenOrRedirect();
  const response = await axios(
    apiPrefixBackbone + url,
    Object.assign(options, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }),
  ).catch((error: any) => {
    if (error.response && error.response.data && error.response.data.message) {
      error.message = error.response.data.message;
    } else {
      error.message = 'Service is unavailable right now';
    }

    throw error;
  });

  return response.data;
}

export async function postBackbone(url: string, data = {}, headers = {}) {
  return callBackbone(url, {
    method: 'post',
    dataType: 'json',
    data,
    headers: {
      ...DEFAULT_HEADERS,
      ...headers,
    },
  });
}

export async function getBackbone(url: string, params = {}, data = {}, headers = {}) {
  return callBackbone(url, {
    method: 'get',
    params,
    data,
    headers: {
      ...DEFAULT_HEADERS,
      ...headers,
    },
  });
}

export async function deleteBackbone(url: string, data = {}, headers = {}) {
  return callBackbone(url, {
    method: 'delete',
    dataType: 'json',
    data,
    headers: {
      ...DEFAULT_HEADERS,
      ...headers,
    },
  });
}

export async function putBackbone(url: string, data = {}, headers = {}) {
  return callBackbone(url, {
    method: 'put',
    dataType: 'json',
    data,
    headers: {
      ...DEFAULT_HEADERS,
      ...headers,
    },
  });
}
