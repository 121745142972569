<template>
  <q-dialog :model-value="showReassignDialog" class="single-service-page" persistent>
    <q-card style="min-width: 480px; border-radius: 0px">
      <q-toolbar class="shadow-2 q-py-sm q-px-md">
        <q-toolbar-title class="text-weight-bold">
          <strong>Reassign {{ entity === 'external-dns' ? 'external DNS record' : 'zone' }}</strong>
        </q-toolbar-title>
        <q-btn icon="close" style="min-width: 20px" round flat class="btn--no-hover" @click="$emit('cancel')"></q-btn>
      </q-toolbar>
      <q-card-section class="q-mx-md q-px-none q-pb-none" style="border-top: 2px solid var(--q-primary)">
        <div class="new-ds-select q-pb-md">
          <service-select
            onlyActive
            fullWidth
            :label="
              newDigitalService?.digitalServiceName ? newDigitalService.digitalServiceName : 'Choose digital service'
            "
            @reset="$emit('resetService')"
            @addService="$emit('setService', $event)"
          />
          <span class="reassign-errors" v-if="errors?.length">{{ errors }}</span>
        </div>
      </q-card-section>
      <q-card-actions class="q-px-md q-pb-lg">
        <q-btn
          :disable="!newDigitalService || loading || Boolean(errors?.length)"
          color="primary"
          text-color="text-color"
          no-caps
          :ripple="false"
          :loading="loading"
          @click="$emit('submit')"
        >
          Submit
        </q-btn>
        <q-btn
          style="border-radius: 10px; text-transform: capitalize"
          class="btn--no-hover"
          flat
          @click="$emit('cancel')"
          >Close</q-btn
        >
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import ServiceSelect from '@/shared/components/ServiceSelect.vue';

export default {
  name: 'ReassignDialog',
  components: {
    ServiceSelect,
  },
  props: {
    showReassignDialog: {
      type: Boolean,
      default: false,
    },
    entity: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    newDigitalService: {
      type: [Object, undefined],
    },
    errors: {
      type: [String, undefined],
    },
  },
};
</script>

<style lang="scss" scoped>
.reassign-errors {
  display: block;
  width: 100%;
  font-size: 12px;
  color: red;
  white-space: pre-line;
  margin-top: 5px;
  padding-left: 5px;
}
</style>
