import { getServiceCatalog, postBackbone, getBackbone } from '@/library/http';

export const getRegistration = async (id: string, envs: boolean, details: boolean) =>
  getServiceCatalog(`/registration/${id}?environments=${envs}&details=${details}`);

export const getExternalDnsRecords = async (
  id: string,
  limit: number,
  skip: number,
  sortBy: string,
  descending: boolean,
  domain: string,
  type: string,
  name: string,
  value: string,
) => {
  let filter = '';
  filter += `&order=${descending ? 'ASC' : 'DESC'}`;
  sortBy?.length ? (filter += `&orderBy=${sortBy}`) : false;
  domain?.length ? (filter += `&domain=${domain}`) : false;
  type?.length ? (filter += `&type=${type}`) : false;
  name?.length ? (filter += `&name=${name}`) : false;
  value?.length ? (filter += `&value=${value}`) : false;

  return getServiceCatalog(`/registration/${id}/external-dns?limit=${limit}&skip=${skip}${filter}`);
};

export const getZones = async (
  id: string,
  limit: number,
  skip: number,
  sortBy: string,
  descending: boolean,
  name: string,
  license: string,
  dns: string,
) => {
  let filter = '';
  filter += `&order=${descending ? 'ASC' : 'DESC'}`;
  sortBy?.length ? (filter += `&orderBy=${sortBy}`) : false;
  name?.length ? (filter += `&zoneName=${name}`) : false;
  license?.length ? (filter += `&license=${license}`) : false;
  dns?.length ? (filter += `&dns=${dns === 'enabled' ? 'true' : 'false'}`) : false;

  return getServiceCatalog(`/registration/${id}/zones?limit=${limit}&skip=${skip}${filter}`);
};

export const getFirewallRules = async (
  limit: number,
  skip: number,
  sortBy: string,
  srcApmId: string,
  dstApmId: string,
  descending: boolean,
  port: string,
  protocol: string,
  srcIp: string,
  dstIp: string,
) => {
  let filter = '';
  filter += `&order=${descending ? 'ASC' : 'DESC'}`;
  sortBy?.length ? (filter += `&orderBy=${sortBy}`) : false;
  port?.length ? (filter += `&port=${port}`) : false;
  protocol?.length ? (filter += `&protocol=${protocol}`) : false;
  srcIp?.length ? (filter += `&srcIp=${srcIp}`) : false;
  dstIp?.length ? (filter += `&dstIp=${dstIp}`) : false;
  srcApmId?.length ? (filter += `&srcApmId=${srcApmId}`) : false;
  dstApmId?.length ? (filter += `&dstApmId=${dstApmId}`) : false;

  return getServiceCatalog(`/registration/firewall-rules?limit=${limit}&skip=${skip}${filter}`);
};

export const getVnets = async (
  id: string,
  env: string,
  sortBy: string,
  descending: boolean,
  limit: number,
  skip: number,
  name: string,
  resourceGroup: string,
  size: string,
  region: string,
) => {
  let filter = '';
  filter += `&order=${descending ? 'ASC' : 'DESC'}`;
  sortBy?.length ? (filter += `&orderBy=${sortBy}`) : false;
  name?.length ? (filter += `&vnetName=${name}`) : false;
  resourceGroup?.length ? (filter += `&resourceGroup=${resourceGroup}`) : false;
  size ? (filter += `&size=${size}`) : false;
  region?.length ? (filter += `&region=${region}`) : false;

  return getServiceCatalog(`/registration/${id}/vnets?limit=${limit}&skip=${skip}&environment=${env}${filter}`);
};

export const getCertifiedProductsArtifacts = async (id: string) => {
  return getServiceCatalog(`/registration/${id}/certified-products/artifacts`);
  //return ['Art1', 'Art2'];
};

export const getCertifiedProducts = async (
  id: string,
  artifactName: string,
  limit: number,
  skip: number,
  sortBy: string,
  descending: boolean,
  resourceName: string,
  resourceGroup: string,
  environment: string,
) => {
  let filter = '';
  filter += `&order=${descending ? 'ASC' : 'DESC'}`;
  sortBy?.length ? (filter += `&orderBy=${sortBy}`) : false;
  resourceName?.length ? (filter += `&resourceName=${resourceName}`) : false;
  resourceGroup?.length ? (filter += `&resourceGroup=${resourceGroup}`) : false;
  environment ? (filter += `&environment=${environment}`) : false;
  return getServiceCatalog(
    `/registration/${id}/certified-products/${artifactName}?limit=${limit}&skip=${skip}${filter}`,
  );
};

export const subValWorkflow = (data: any) => postBackbone(`/engine/subval/create-workflow`, data);

export const createWorkFlow = (data: any) => postBackbone(`/engine/create-workflow`, data);

export const authWorkflow = (data: any) => postBackbone('/engine/preauth/create-workflow', data);

export const validateWorkFlow = (data: any) => postBackbone(`/engine/preval/create-workflow`, data);

export const getBluePrint = (id: string) => getBackbone(`/blueprints/${id}`);
