<template>
  <div class="status">
    <span :class="status ? status.toLowerCase() : ''">
      <q-tooltip v-if="!showLabel">{{ status.toLowerCase() || '' }}</q-tooltip>
    </span>
    <p v-if="showLabel">{{ status.toLowerCase() || '' }}</p>
  </div>
</template>

<script>
export default {
  name: 'StatusCell',
  props: {
    status: {
      type: String,
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.status {
  display: flex;
  align-items: center;

  p {
    text-transform: capitalize;
    margin: 0;
  }

  span {
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 5px;
    background-color: $grey-6;

    &.inprogress {
      background-color: $orange;
    }

    &.processing {
      background-color: goldenrod;
    }

    &.pending {
      background-color: $grey-6;
    }

    &.completed,
    &.abandoned {
      background-color: $green;
    }

    &.failed {
      background-color: $red;
    }

    &.reverted {
      background-color: #d00fa0;
    }
  }
}
</style>
