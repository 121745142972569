<template>
  <q-page
    class="main-wrapper-page single-service-page single-page digital-service-page"
    style="background-color: var(--q-accent)"
  >
    <div class="row no-wrap q-col-gutter-md">
      <div class="col col-xl-2 col-lg-3 col-md-4">
        <div class="nav-holder">
          <q-scroll-area
            :thumb-style="{
              borderRadius: '5px',
              width: '3px',
              right: '-2px',
              background: '#9f9f9f',
              height: '3px',
              opacity: 1,
            }"
            :style="`max-width: 100%; height: calc(100vh - ${currentBanner ? '215px' : '165px'})`"
          >
            <div class="flex items-center justify-between">
              <span
                class="block q-my-none q-mx-none"
                style="cursor: pointer; font-size: 20px; font-weight: 500"
                @click="scrollToItem('info')"
                ><q-icon name="info" size="14px" class="q-mr-xs"></q-icon>Info</span
              >
              <q-btn
                v-if="!acccesProductsLoaindg"
                flat
                icon="view_in_ar"
                @click="getAllAccessProducts"
                round
                size="12px"
              >
                <q-tooltip>Show all access products</q-tooltip>
              </q-btn>
              <q-spinner v-else size="20px"></q-spinner>
            </div>
            <div v-for="category in serviceCategories" :key="category.name">
              <span class="services-category">{{ category.name }}</span>
              <ul class="services-list">
                <li v-for="service in category.infraServices" :key="service.id" @click="scrollToItem(service)">
                  <div class="flex items-center">
                    <strong
                      >{{ service.infraService.displayName || service.infraService.serviceName }}
                      <sup v-if="service.kind === 'RegistrationDraft'" class="draft-icon q-ml-xs q-mb-md">
                        <q-icon name="mdi-alert-circle" size="16px" color="warning"></q-icon>
                        <q-tooltip anchor="top right"
                          >This registration is in Draft mode, please edit them to add details and start processing
                        </q-tooltip>
                      </sup>
                    </strong>
                  </div>
                  <template v-if="showAccessProducts && service.accessProducts?.length">
                    <q-list class="access-product">
                      <q-item v-for="(role, index) in service.accessProducts" :key="index">
                        <q-checkbox
                          v-model="selectedAccessProducts"
                          @update:model-value="itemSelected(role.name)"
                          :disable="existingRolesSet.includes(role.name)"
                          size="sm"
                          dense
                          :label="role.name"
                          :val="role.name"
                        />
                        <q-btn
                          icon="bookmark_added"
                          class="q-ml-sm btn--no-hover"
                          style="min-width: 20px; min-height: 20px; width: 20px"
                          size="12px"
                          color="blue-grey-8"
                          flat
                          round
                          @click="orderAccessProduct(role.name)"
                        >
                          <q-tooltip max-width="280px">Order Access Product {{ role.name }}</q-tooltip>
                        </q-btn>
                      </q-item>
                    </q-list>
                  </template>
                </li>
              </ul>
            </div>
          </q-scroll-area>
          <q-btn
            style="border-radius: 10px; text-transform: capitalize; bottom: -4px; left: 20%"
            color="primary"
            text-color="text-color"
            :disabled="!newRolesCount"
            v-if="showAccessProducts"
            @click="openConfirmOrderDialog"
            >Order products</q-btn
          >
        </div>
      </div>
      <div class="col col-xl-10 col-lg-9 col-md-8">
        <q-scroll-area
          :style="`height: calc(100vh - ${currentBanner ? '300px' : '250px'})`"
          :thumb-style="{ borderRadius: '5px', background: '#9f9f9f', width: '3px', opacity: 1, right: 0 }"
        >
          <div v-if="application" class="q-px-md q-py-none">
            <div class="block-holder service-info">
              <h5 class="q-my-none q-mb-md text-weight-bold" ref="info">Info</h5>
              <div class="row no-wrap">
                <div class="col col-12">
                  <div class="row">
                    <div class="col col-6">
                      <table class="info-table bordered">
                        <tbody>
                          <tr>
                            <th class="text-left">APMID:</th>
                            <td class="text-left">{{ application.apmId }}</td>
                          </tr>
                          <tr>
                            <th class="text-left">Service Name:</th>
                            <td class="text-left">{{ application.digitalServiceName }}</td>
                          </tr>
                          <tr>
                            <th class="text-left">Created At:</th>
                            <td class="text-left">
                              {{ $options.dayjs(application.createdAt).format('DD.MM.YYYY HH:mm') }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="col col-6">
                      <table class="info-table bordered">
                        <tbody>
                          <tr>
                            <th class="text-left">Status:</th>
                            <td class="text-left">{{ application.status }}</td>
                          </tr>
                          <tr>
                            <th class="text-left">Link:</th>
                            <td class="text-left">
                              <a :href="application.url" target="_blank">{{ application.url }}</a>
                            </td>
                          </tr>
                          <tr>
                            <th class="text-left">Major Incidents:</th>
                            <td class="text-left">{{ application.majorIncidents?.length || 0 }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="owner-info row q-pt-sm">
                    <div class="col-6 q-pt-sm" v-if="application.itOwnerName">
                      <div class="row items-center">
                        <div class="col-2">
                          <img
                            width="60"
                            height="60"
                            style="border-radius: 50%"
                            :src="`https://whoswho.swissre.com/photos/size300/${application.itOwnerId}.jpg`"
                            :alt="application.itOwnerName"
                          />
                        </div>
                        <div class="col-10">
                          <div class="flex column justify-center items-start">
                            <strong style="font-size: 11px" class="block">IT Owner:</strong>
                            <span class="block" style="font-size: 16px">{{ application.itOwnerName }}</span>
                            <span>{{ application.itOwnerEmail }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 q-pt-sm" v-if="application.itCustodianName">
                      <div class="row items-center">
                        <div class="col-2">
                          <img
                            width="60"
                            height="60"
                            style="border-radius: 50%"
                            :src="`https://whoswho.swissre.com/photos/size300/${application.itCustodianId}.jpg`"
                            :alt="application.itCustodianName"
                          />
                        </div>
                        <div class="col-10">
                          <div class="flex column justify-center items-start">
                            <strong style="font-size: 11px" class="block">IT Owner Custodian:</strong>
                            <span class="block" style="font-size: 16px">{{ application.itCustodianName }}</span>
                            <span>{{ application.itCustodianEmail }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 q-pt-sm" v-if="application.businessOwnerName">
                      <div class="row items-center">
                        <div class="col-2">
                          <img
                            width="60"
                            height="60"
                            style="border-radius: 50%"
                            :src="`https://whoswho.swissre.com/photos/size300/${application.businessOwnerId}.jpg`"
                            :alt="application.businessOwnerName"
                          />
                        </div>
                        <div class="col-10">
                          <div class="flex column justify-center items-start">
                            <strong style="font-size: 11px" class="block">Business Owner:</strong>
                            <span class="block" style="font-size: 16px">{{ application.businessOwnerName }}</span>
                            <span>{{ application.businessOwnerEmail }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 q-pt-sm" v-if="application.businessOwnerCustodianName">
                      <div class="row items-center">
                        <div class="col-2">
                          <img
                            width="60"
                            height="60"
                            style="border-radius: 50%"
                            :src="`https://whoswho.swissre.com/photos/size300/${application.businessOwnerCustodianId}.jpg`"
                            :alt="application.businessOwnerCustodianName"
                          />
                        </div>
                        <div class="col-10">
                          <div class="flex column justify-center items-start">
                            <strong style="font-size: 11px" class="block">Business Owner Custodian:</strong>
                            <span class="block" style="font-size: 16px">{{
                              application.businessOwnerCustodianName
                            }}</span>
                            <span>{{ application.businessOwnerCustodianEmail }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="q-mt-sm" style="border-top: 1px solid rgba(0, 0, 0, 0.12)">
                    <q-tabs
                      v-model="infoTab"
                      inline-label
                      content-class="justify-start"
                      class="q-mt-md widget-tabs"
                      indicator-color="primary"
                    >
                      <q-tab name="costData" label="Cost data" />
                      <q-tab v-if="adrs.length" name="adrs" label="ADRs you may be interested in">
                        <span class="indicator">
                          <span
                            style="background-color: #9e9e9e"
                            :style="`width: ${
                              (allAdrs.filter((i) => i.hasDigitalServiceAdherence === null).length / totalAdrs) * 100
                            }%`"
                          ></span>
                          <span
                            style="background-color: #4caf50"
                            :style="`width: ${
                              (allAdrs.filter((i) => i.hasDigitalServiceAdherence).length / totalAdrs) * 100
                            }%`"
                          ></span>
                          <span
                            style="background-color: #c62828"
                            :style="`width: ${
                              (allAdrs.filter((i) => i.hasDigitalServiceAdherence === false).length / totalAdrs) * 100
                            }%`"
                          ></span>
                        </span>
                      </q-tab>
                    </q-tabs>
                    <q-tab-panels v-model="infoTab" animated>
                      <q-tab-panel name="costData">
                        <div class="flex items-center justify-between q-mt-sm">
                          <strong class="block">Detailed cost data:</strong>
                          <q-select
                            :model-value="costDate"
                            option-label="label"
                            option-value="value"
                            borderless
                            :options="costDates"
                            emit-value
                            map-options
                            @update:model-value="setCostDate"
                            class="cost-select"
                            dense
                          />
                        </div>
                        <cost-chart
                          v-if="!loadCostData && costData && Object.keys(costData).length"
                          :cost-data="costData"
                        />
                        <div v-else-if="loadCostData" class="flex items-center justify-center q-py-lg">
                          <q-spinner-bars size="2em" color="primary"></q-spinner-bars>
                        </div>
                        <div v-else class="flex items-center justify-center">
                          <strong>No cost data</strong>
                        </div>
                      </q-tab-panel>
                      <q-tab-panel name="adrs" class="q-px-lg q-py-lg">
                        <div class="row q-col-gutter-xs q-pt-md adrs-holder">
                          <div
                            v-for="adr in adrs"
                            :key="adr.id"
                            @click="goToAdr(adr.adrId)"
                            class="col-lg-4 col-md-6 q-px-xs q-py-xs adr-holder"
                          >
                            <div class="adr">
                              <span class="adr-title">{{ adr.name }}</span>
                              <span class="scope" style="color: #009688; font-size: 14px"
                                >For {{ adr.scope?.units }}, {{ adr.scope?.regions }}</span
                              >
                              <div
                                class="adr-content service-description-markdown"
                                v-html="dataMarkdown(adr.requirements || adr.content)"
                              ></div>
                              <div class="adr-title-holder flex items-center justify-between no-wrap">
                                <span class="block"><b>Valid date:</b> {{ adr.validDate }}</span>
                                <div class="adherence">
                                  <span
                                    :style="`background-color:${
                                      adr.hasDigitalServiceAdherence === null
                                        ? '#9e9e9e'
                                        : adr.hasDigitalServiceAdherence
                                        ? '#4caf50'
                                        : '#c62828'
                                    }`"
                                  ></span>
                                  <b>{{
                                    adr.hasDigitalServiceAdherence === null
                                      ? 'Undefined'
                                      : adr.hasDigitalServiceAdherence
                                      ? 'Adherent'
                                      : 'Not Adherent'
                                  }}</b>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-if="totalAdrs > 6" class="flex flex-center q-mt-lg">
                          <q-pagination
                            :max="Math.ceil(totalAdrs / 6)"
                            v-model="currentPage"
                            @update:model-value="loadAdrs"
                          ></q-pagination>
                        </div>
                      </q-tab-panel>
                    </q-tab-panels>
                  </div>
                </div>
              </div>
            </div>
            <template v-if="application.registrations && application.registrations.length">
              <div
                class="block-holder"
                v-for="service in application.registrations"
                :key="service.infraService.id"
                :ref="service.infraService.apmid"
              >
                <q-expansion-item
                  :ref="service.infraService.id"
                  @before-show="getWidgetInfo(service)"
                  :hide-expand-icon="service.kind === 'RegistrationDraft'"
                  class="service-item"
                >
                  <template v-slot:header>
                    <div v-if="service.kind === 'RegistrationDraft'" class="draft-label">
                      DRAFT
                      <q-tooltip anchor="top right"
                        >This registration is in Draft mode, please edit them to add details and start processing
                      </q-tooltip>
                    </div>
                    <div class="service-header">
                      <div class="service-name flex items-center">
                        <span
                          class="icon-service"
                          :class="[
                            `${service.infraService.cloudType ? service.infraService.cloudType.toLowerCase() : 'na'}`,
                            { tool: service.type === 'Tool' },
                          ]"
                        >
                          <infra-service-logo
                            v-if="service.infraService.icon && service.infraService.icon !== 'default'"
                            :logo="service.infraService.icon"
                          />
                          <q-icon v-else name="settings" class="default-icon" size="30px"></q-icon>
                        </span>
                        <strong>{{ service.infraService.displayName || service.infraService.serviceName }}</strong>
                      </div>

                      <q-btn
                        @click.stop
                        class="q-px-none q-py-none q-ml-auto q-mr-sm btn--no-hover"
                        :ripple="false"
                        flat
                        icon="more_vert"
                      >
                        <q-menu self="top right" class="service-actions">
                          <q-list dense style="min-width: 100px">
                            <q-item
                              v-if="
                                service.kind !== 'RegistrationCertifiedProduct' &&
                                service.infraService.kind &&
                                service.infraService.kind !== 'RegistrationCertifiedProduct'
                              "
                              @click="goToRegistration(service)"
                              clickable
                              :disable="isEditDisable(service)"
                              v-close-popup
                            >
                              <q-item-section avatar>
                                <q-icon color="text-color" name="edit" size="20px" />
                              </q-item-section>
                              <q-item-section>Edit or view workflow details</q-item-section>
                            </q-item>
                            <q-item
                              clickable
                              v-close-popup
                              v-if="service.kind === 'RegistrationDraft'"
                              @click="confirmRemoveDraftRegistration(service)"
                            >
                              <q-item-section avatar>
                                <q-icon color="text-color" name="delete" size="20px" />
                              </q-item-section>
                              <q-item-section>Remove</q-item-section>
                            </q-item>
                            <q-item clickable @click="showFeedbackDialog(service)" v-close-popup>
                              <q-item-section avatar>
                                <q-icon color="text-color" name="contact_support" size="20px" />
                              </q-item-section>
                              <q-item-section>Give Feedback</q-item-section>
                            </q-item>
                          </q-list>
                        </q-menu>
                      </q-btn>
                    </div>
                  </template>
                  <div v-if="service.kind !== 'RegistrationDraft'" class="service-data">
                    <div v-if="service.infraService.widgetDescription" class="description q-mt-md">
                      <span class="label-block q-mb-sm">Description:</span>
                      <div
                        class="service-description q-mb-none"
                        v-html="dataMarkdown(service.infraService.widgetDescription)"
                      ></div>
                    </div>
                    <div style="max-width: 100%">
                      <component
                        v-if="infoWidgets[service.infraService.id]"
                        :is="widgetType(service.kind)"
                        :accessProducts="widgetAccessProducts[service.infraService.id]?.data || {}"
                        :loadAccessProducts="widgetAccessProducts[service.infraService.id]?.load"
                        :infraService="service.infraService"
                        :registrationId="service.registrationId"
                      >
                      </component>
                    </div>
                  </div>
                </q-expansion-item>
              </div>
            </template>
          </div>
        </q-scroll-area>
        <div v-if="application" style="padding: 0 16px">
          <q-btn
            v-if="!showServiceList && newServices.length < 1"
            @click="showServiceListPopup"
            style="margin-top: 20px"
            color="primary"
            rounded
            unelevated
            :disable="!application.digitalServiceActive || application.status === 'Under Evaluation'"
            class="block full-width add-service-btn"
            >Add Service</q-btn
          >
          <div v-show="showServiceList || newServices.length" class="service-list flex">
            <div class="selected-services flex items-center" ref="inputServices">
              <q-menu ref="serviceList" fit max-width="500px" :offset="[0, 15]" @hide="() => (showServiceList = false)">
                <div class="orderable-services q-px-lg q-py-lg">
                  <q-input
                    :model-value="serviceSearch"
                    @update:model-value="(event) => (serviceSearch = event)"
                    dense
                    label="Search"
                    class="q-mb-lg"
                  >
                    <template v-slot:prepend>
                      <q-icon name="search"></q-icon>
                    </template>
                  </q-input>
                  <q-scroll-area
                    style="height: 400px; width: 100%; padding-right: 10px"
                    :thumb-style="{ borderRadius: '5px', background: '#9f9f9f', width: '3px', opacity: 1, right: 0 }"
                  >
                    <div v-for="category in filteredItems" :key="category.id" style="max-width: 100%" class="q-mb-sm">
                      <template v-if="category.infraServices.length">
                        <span>{{ category.categoryName }}</span>
                        <div class="infraservices-holder q-pt-xs">
                          <infra-service
                            :class="{ disable: isDisable(service) }"
                            style="cursor: pointer"
                            @click="addService(service)"
                            v-for="service in category.infraServices"
                            :key="service.id"
                            :data-service="{
                              serviceName: service.infraServiceName,
                              displayName: service.infraServiceDisplayName,
                              cloudType: service.cloudType,
                              type: service.type,
                              state: service.state,
                            }"
                          >
                            <template v-slot:tooltip>
                              <q-tooltip v-if="service?.errorMessage?.length">{{ service?.errorMessage }}</q-tooltip>
                            </template>
                            <template v-slot:loading>
                              <q-spinner v-if="loadService === service.id" color="grey-7" size="1.5em" />
                            </template>
                          </infra-service>
                        </div>
                      </template>
                    </div>
                  </q-scroll-area>
                </div>
              </q-menu>
              <q-scroll-area
                style="height: 70px; width: calc(100% - 30px); padding-right: 5px"
                :thumb-style="{ borderRadius: '5px', background: '#9f9f9f', width: '3px', opacity: 1, right: 0 }"
              >
                <span v-if="!newServices.length" class="q-pl-md block" style="font-size: 18px; color: #979ba4"
                  >Select one service that you need...</span
                >
                <infra-service
                  v-for="service in newServices"
                  :key="service.id"
                  :data-service="{
                    serviceName: service.infraServiceName,
                    displayName: service.infraServiceDisplayName,
                    cloudType: service.cloudType,
                    type: service.type,
                    state: service.state,
                  }"
                >
                  <template v-slot:buttons>
                    <q-btn
                      class="btn--no-hover q-ml-auto"
                      size="10px"
                      icon="remove"
                      flat
                      padding="0"
                      :ripple="false"
                      @click.stop="removeFromList(service)"
                    >
                      <q-tooltip style="z-index: 999999">Remove</q-tooltip>
                    </q-btn>
                  </template>
                </infra-service>
              </q-scroll-area>
              <span v-if="newServices.length" class="block" style="width: 30px">
                <q-icon name="close" size="22px" style="cursor: pointer" @click="newServices = []">
                  <q-tooltip>Clear</q-tooltip>
                </q-icon>
              </span>
            </div>
            <q-btn
              @click="createDraftRegistrations"
              class="block add-service-btn"
              :loading="sendRequest"
              :disable="!newServices.length || sendRequest || !!loadService"
              style="min-width: 200px"
              rounded
              unelevated
              color="primary"
              >Add</q-btn
            >
          </div>
        </div>
      </div>
    </div>

    <q-dialog v-model="removeDraftRegistration" class="single-service-page" persistent>
      <q-card style="min-width: 768px; border-radius: 0px">
        <q-toolbar class="shadow-2 q-py-sm q-px-md">
          <q-toolbar-title class="text-weight-bold">
            <strong>Confirm removing draft registration</strong>
          </q-toolbar-title>
          <q-btn icon="close" round flat class="btn--no-hover" @click="removeDraftRegistration = false"></q-btn>
        </q-toolbar>
        <q-card-actions class="q-pb-md">
          <q-btn
            color="primary"
            @click="deleteDraftRegistration"
            :disabled="deleteLoad"
            :loading="deleteLoad"
            size="md"
            class="q-ml-sm"
            no-caps
            label="Yes"
          ></q-btn>
          <q-btn
            flat
            size="md"
            class="btn--no-hover"
            style="height: 40px"
            no-caps
            @click="removeDraftRegistration = false"
            >No</q-btn
          >
        </q-card-actions>
      </q-card>
    </q-dialog>

    <feedback-dialog
      @sendFeedback="createFeedback($event)"
      :feedbacks="currentServiceFeedbacks"
      :loading="feedbackLoad"
      :show="feedbackDialog"
      :service-name="feedbackService?.displayName || feedbackService?.serviceName"
      @close="(feedbackDialog = false), (currentServiceFeedbacks = [])"
    ></feedback-dialog>
  </q-page>
</template>

<script>
import { marked } from 'marked';
import { mapActions, mapGetters } from 'vuex';
import { scroll } from 'quasar';
import apollo from '@/library/http/apollo';
import gql from 'graphql-tag';
import dayjs from 'dayjs';
import * as uiConfig from '@/shared/ui-config.json';
import { APP_ACTIONS, APP_GETTERS } from '@/store/modules/app';
import InfraServiceLogo from '@/shared/components/InfraServiceLogo';
import InfraService from '@/pages/digitalServices/components/InfraService.vue';
import CloudenteWidget from './components/CloudenteWidget.vue';
import DefaultWidget from './components/DefaultWidget.vue';
import DbaasWidget from './components/DbaasWidget.vue';
import NetworkWidget from './components/NetworkWidget.vue';
import MasWidget from './components/MasWidget.vue';
import CertifiedProductWidget from './components/CertifiedProductWidget.vue';
import { BANNERS_GETTERS } from '@/store/modules/banners';
import CostChart from '@/pages/digitalService/components/CostChart';
import FeedbackDialog from '@/shared/components/FeedbackDialog';
import { createDraft, preCreateDraft } from './api';
import { getAccessProducts, deleteDraft } from '@/pages/digitalService/api';

const { getScrollTarget, setVerticalScrollPosition } = scroll;

export default {
  name: 'DigitalService',
  marked,
  components: {
    FeedbackDialog,
    CostChart,
    InfraServiceLogo,
    InfraService,
    CloudenteWidget,
    DefaultWidget,
    DbaasWidget,
    NetworkWidget,
    MasWidget,
    CertifiedProductWidget,
  },
  dayjs,
  data() {
    return {
      deleteLoad: false,
      removeDraftRegistration: false,
      draftRegistrationToRemove: undefined,
      allAdrs: [],
      currentPage: 1,
      totalAdrs: 0,
      acccesProductsLoaindg: false,
      newRolesCount: 0,
      newRolesSet: new Set(),
      rolesToOrder: [],
      showConfirmDialog: false,
      selectedAccessProducts: [],
      existingRolesSet: [],
      widgetHeight: '100px',
      feedbackLoad: false,
      feedbackDialog: false,
      feedbackService: undefined,
      currentServiceFeedbacks: [],
      infoTab: 'costData',
      sendRequest: false,
      application: undefined,
      servicesList: [],
      allInfraservices: [],
      serviceSearch: '',
      wizardDialog: false,
      formData: {},
      schema: undefined,
      uiSchema: undefined,
      showServiceList: false,
      newServices: [],
      loadData: false,
      currentService: {},
      uiConfig: {},
      serviceCategories: [],
      infoWidgets: {},
      widgetAccessProducts: {},
      costDates: [],
      costDate: '',
      costData: undefined,
      loadCostData: false,
      showAccessProducts: false,
      adrs: [],
      comments: [],
      commentsPage: 1,
      showCommentsCount: 5,
      loadService: undefined,
    };
  },
  methods: {
    confirmRemoveDraftRegistration(registration) {
      this.removeDraftRegistration = true;
      this.draftRegistrationToRemove = registration;
    },
    deleteDraftRegistration() {
      this.deleteLoad = true;
      deleteDraft({ id: this.draftRegistrationToRemove.registrationId })
        .then(() => {
          const { categoryName, id } = this.draftRegistrationToRemove.infraService;

          this.serviceCategories.forEach((category) => {
            if (category.name === categoryName) {
              const index = category.infraServices.findIndex((service) => service.infraService.id === id);

              if (index !== -1) {
                category.infraServices.splice(index, 1);
              }
            }
          });

          const regIndex = this.application.registrations.findIndex(
            (reg) => reg.registrationId === this.draftRegistrationToRemove.registrationId,
          );
          if (regIndex !== -1) {
            this.application.registrations.splice(regIndex, 1);
          }

          this.$notify('positive', 'Success', 'Draft registration removed successfully');
        })
        .catch((e) => {
          this.$notify('negative', 'Error', e.message);
        })
        .finally(() => {
          (this.deleteLoad = false), (this.removeDraftRegistration = false);
          this.removeDraftRegistration = undefined;
        });
    },
    async goToRegistration(service) {
      this.$router.push(`/my-portfolio/${this.application.apmId}/registration/${service.registrationId}`);
    },
    ...mapActions('app', {
      getUserInfo: APP_ACTIONS.GET_USER_INFO,
    }),
    orderAccessProduct(role) {
      window.open(
        `https://swissreesm.service-now.com/contactone?id=request_access_rights&accessRightParam=${role}`,
        '_blank',
      );
    },
    openConfirmOrderDialog() {
      this.showConfirmDialog = true;
      this.rolesToOrder = this.selectedAccessProducts.filter((r) => !this.existingRolesSet.includes(r));
    },
    itemSelected(roleName) {
      // Ensure the checkbox is set/unset
      if (this.newRolesSet.has(roleName)) {
        this.newRolesCount--;
        this.newRolesSet.delete(roleName);
      } else {
        this.newRolesSet.add(roleName);
        this.newRolesCount++;
      }
    },
    async orderMassAccesses() {
      this.$showLoading();
      this.showConfirmDialog = false;

      await apollo.apolloClient
        .mutate({
          mutation: gql`
          mutation {
            createMassRequestOrder(accessRoles: [ ${this.rolesToOrder.map((role) => `"${role}"`)}])
          }
          `,
        })
        .then(() => {
          this.showAccessProducts = false;
          this.selectedAccessProducts = [];
          this.$notify('positive', 'Success', 'Access products ordered successfully');
        })
        .catch((e) => {
          this.$notify('negative', 'Error', e.message);
        })
        .finally(async () => {
          await this.getExistAccessProduct();
          this.$hideLoading();
        });
    },
    getWidgetInfo(service, reload = false) {
      if (service.kind === 'RegistrationDraft') return false;
      if (!this.infoWidgets[service.infraService.id] || reload) {
        this.infoWidgets[service.infraService.id] = {};
        this.widgetAccessProducts[service.infraService.id] = {};
        this.widgetAccessProducts[service.infraService.id].load = true;

        const payload = {
          digitalServiceApmId: this.apmId,
          infraServiceApmId: service.infraService.apmid,
        };

        getAccessProducts(payload)
          .then((response) => {
            this.widgetAccessProducts[service.infraService.id].data = response?.composition.length
              ? response.composition.filter(({ role }) => role.status === 'exists')
              : [];
          })
          .finally(() => {
            this.widgetAccessProducts[service.infraService.id].load = false;
          });
      }
    },
    widgetType(kind) {
      switch (kind) {
        case 'RegistrationCloudente':
          return 'CloudenteWidget';
        case 'RegistrationDBaaS':
          return 'DbaasWidget';
        case 'RegistrationNetwork':
          return 'NetworkWidget';
        case 'RegistrationMAS':
          return 'MasWidget';
        case 'RegistrationCertifiedProduct':
          return 'CertifiedProductWidget';
        default:
          return 'DefaultWidget';
      }
    },
    scrollToItem(service) {
      const anchor = service.infraService.apmid;
      const widgetRef = service.infraService.id;
      const element = this.$refs[anchor][0] || this.$refs[anchor];
      const target = getScrollTarget(element);
      const offset = element.offsetTop - 10;
      const duration = 1000;
      setVerticalScrollPosition(target, offset, duration);
      const widget = this.$refs[widgetRef][0];
      widget.toggle();
      this.$router.replace({ query: { infraservice: anchor } });
    },
    async getApplicationData() {
      await apollo.apolloClient
        .query({
          query: gql`
          query {
            getApplicationByAPMID(digitalServiceAPMID: "${this.apmId}") {
              id
              apmId
              majorIncidents
              assignmentGroup
              businessOwnerEmail
              businessOwnerId
              businessOwnerName
              createdAt
              createdByEmail
              createdById
              createdByName
              digitalServiceName
              status
              digitalServiceActive
              itCustodian
              itCustodianEmail
              itCustodianId
              itCustodianName
              itOwner
              itOwnerEmail
              itOwnerId
              businessOwnerCustodianId
              businessOwnerCustodianName
              businessOwnerCustodianEmail
              itOwnerName
              release
              url
              registrations {
                registrationId
                digitalServiceId
                status
                kind
                infraService {
                  id
                  categoryName
                  categoryId
                  categoryOrder
                  apmid
                  displayName
                  serviceName
                  serviceId
                  cloudType
                  type
                  icon
                  widgetDescription
                  kind
                  links{
                    id
                    type
                    label
                    url
                  }
                }
              }
            }
          }
        `,
        })
        .then((response) => {
          this.application = response?.data.getApplicationByAPMID;
          this.servicesList = this.application?.registrations
            ? this.application.registrations.map((registration) => {
                return {
                  infraService: registration.infraService,
                  kind: registration.kind,
                };
              })
            : [];
          if (this.servicesList.length) {
            const categories = [];
            this.application.registrations.forEach((registration) => {
              if (!categories.map((item) => item.name).includes(registration.infraService.categoryName)) {
                categories.push({
                  name: registration.infraService.categoryName,
                  order: registration.infraService.categoryOrder,
                });
              }
            });
            const serviceCategories = categories.map((category) => ({
              name: category.name,
              order: category.order,
              infraServices: [],
            }));
            this.servicesList.forEach((item) => {
              const cat = serviceCategories.find((category) => category.name === item.infraService.categoryName);
              cat.infraServices.push(item);
            });
            this.serviceCategories = serviceCategories.sort((a, b) => a.order - b.order);
            this.servicesList.forEach((registration) => {
              // Group links to display in separate columns. TODO: refactor after DBtable is normalized;
              registration.infraService.links = registration.infraService.links.reduce((r, link) => {
                const linkTypes = link.type.split(',').map((t) => t.toLowerCase().trim());
                linkTypes.forEach((linkType) => {
                  if (linkType !== 'details') {
                    // Details should not be displayed
                    r[linkType] = r[linkType] || [];
                    r[linkType].push(link);
                  }
                });
                return r;
              }, {});
            });
          }
        });
      if (this.servicesList.length) {
        await apollo.apolloClient
          .query({
            query: gql`
            query getADRs(
              $apmId: String
              $servicesArr: [String!]
          ) {
              getADRs(filter: {filterBy: {services: $servicesArr, apmId: $apmId }, includeFields: {services: false}}) {
                count
                adrs {
                  adrId
                  category
                  id
                  created_at
                  hasDigitalServiceAdherence(digitalServiceAPMId: "${this.application.apmId}")
                  name
                  scope
                  status
                  validDate
                  version
                  requirements
                  content
                }
              }
            }
          `,
            variables: {
              apmId: this.apmId,
              servicesArr: this.servicesList?.length ? this.servicesList.map((s) => s.infraService.serviceId) : [],
            },
          })
          .then((response) => {
            this.allAdrs = JSON.parse(JSON.stringify(response.data.getADRs.adrs));
            this.totalAdrs = response.data.getADRs.count;
            this.adrs = response.data.getADRs.adrs.splice(0, 6);
          });
      }
    },
    async loadAdrs() {
      await apollo.apolloClient
        .query({
          query: gql`
            query getADRs(
            $servicesArr: [String!]
            $apmId: String
          ) {
              getADRs(filter: {filterBy: {services: $servicesArr, apmId: $apmId }, includeFields: {services: true}}, limit: "${6}", skip: "${
            (this.currentPage - 1) * 6
          }") {
                count
                adrs {
                  adrId
                  category
                  id
                  created_at
                  hasDigitalServiceAdherence(digitalServiceAPMId: "${this.application.apmId}")
                  name
                  scope
                  status
                  validDate
                  version
                  requirements
                  content
                }
              }
            }
          `,
          variables: {
            apmId: this.apmId,
            servicesArr: this.servicesList?.length ? this.servicesList.map((s) => s.infraService.serviceId) : [],
          },
        })
        .then((response) => {
          this.adrs = response.data.getADRs.adrs;
        });
    },
    async getOrderableInfraservices() {
      const apms = this.servicesList.map((item) => item.infraService.apmid).filter((item) => item && item.length);
      await apollo.apolloClient
        .query({
          query: gql`
            query orderabelInfraServicesByCategories($apmsarr: [String!]) {
              orderabelInfraServicesByCategories(apms: $apmsarr) {
                categoryName
                infraServices {
                  cloudType
                  id
                  infraServiceAPMID
                  infraServiceDisplayName
                  infraServiceName
                  kind
                  state
                  type
                  parentServiceDisplayName
                  icon
                  parentServiceIcon
                }
              }
            }
          `,
          variables: {
            apmsarr: apms,
          },
        })
        .then((response) => {
          this.allInfraservices = response.data.orderabelInfraServicesByCategories;
        });
    },
    showServiceListPopup() {
      this.showServiceList = true;
      this.$refs.inputServices.click();
    },
    isDisable(service) {
      return (
        this.newServices.some((item) => item.infraServiceAPMID === service.infraServiceAPMID) ||
        service?.errorMessage?.length
      );
    },
    async addService(service) {
      if (
        this.newServices.some((item) => item.infraServiceAPMID === service.infraServiceAPMID) ||
        service?.errorMessage?.length
      ) {
        return false;
      }
      this.loadService = service.id;
      try {
        const data = {
          infraServiceApmId: service.infraServiceAPMID,
          digitalServiceApmId: this.apmId,
        };
        await preCreateDraft(data);
        this.newServices.unshift(service);
      } catch (error) {
        service.errorMessage = error.message;
        this.$notify('negative', 'Error', error.message);
      } finally {
        this.loadService = undefined;
      }
    },
    async createDraftRegistrations() {
      this.sendRequest = true;
      const draftPromises = this.newServices.map((service) => {
        const data = {
          infraServiceApmId: service.infraServiceAPMID,
          digitalServiceApmId: this.apmId,
        };
        return createDraft(data)
          .then(() => {
            this.$notify(
              'positive',
              'Positive',
              `Draft registrations for ${data.infraServiceApmId} on ${data.digitalServiceApmId} will be created successfull.`,
            );
          })
          .catch((error) => {
            this.$notify('negative', 'Error', error.message);
          });
      });
      try {
        await Promise.all([...draftPromises]);
      } finally {
        this.sendRequest = false;
        this.newServices = [];
        this.load();
      }
    },
    async load() {
      this.$showLoading();
      try {
        await this.getApplicationData();
        await this.getOrderableInfraservices();
      } catch (error) {
        this.$notify('negative', 'Error', error.message);
      } finally {
        this.$hideLoading();
      }
    },
    isEditDisable(service) {
      const { apmid } = service.infraService;
      return this.uiConfig.disableEditServices.includes(apmid);
    },
    dataMarkdown(data) {
      return marked(data);
    },
    removeFromList(service) {
      const index = this.newServices.findIndex((item) => item.id === service.id);
      this.newServices.splice(index, 1);
    },
    async setCostDate(date) {
      this.loadCostData = true;
      this.costDate = date;
      const dates = date.split(' ');
      apollo.apolloClient
        .query({
          query: gql`
          query {
            digitalServiceDetailedCost(apmId: "${this.apmId}", from: "${dates[0]}", to: "${dates[1]}") {
              detailed
            }
          }`,
        })
        .then((response) => {
          const costData = response.data.digitalServiceDetailedCost.detailed;
          const keys = Object.keys(costData).sort();
          const sortedItems = {};
          keys.forEach((key) => {
            sortedItems[key] = costData[key];
          });
          this.costData = sortedItems;
        })
        .finally(() => {
          this.loadCostData = false;
        });
    },
    async getAllAccessProducts() {
      if (this.showAccessProducts) {
        this.showAccessProducts = false;
        return false;
      }

      if (
        this.serviceCategories.find((category) =>
          category.infraServices.find((service) => service.accessProducts?.length),
        )
      ) {
        this.showAccessProducts = !this.showAccessProducts;
        return false;
      }

      const apmids = [];
      this.serviceCategories.forEach((category) => {
        category.infraServices.forEach((item) => {
          if (item.status !== 'PENDING') apmids.push({ apmid: item.infraService.apmid, id: item.infraService.id });
        });
      });

      let count = 0;
      if (apmids.length) {
        this.acccesProductsLoaindg = true;
        const promises = apmids.map((item) => {
          const payload = {
            digitalServiceApmId: this.apmId,
            infraServiceApmId: item.apmid,
          };

          return getAccessProducts(payload)
            .then((resp) => {
              const currentCat = this.serviceCategories.find((cat) =>
                cat.infraServices.find((i) => i.infraService.apmid === item.apmid),
              );
              const currentService = currentCat.infraServices.find((i) => i.infraService.apmid === item.apmid);
              const grouped = resp.composition.reduce((acc, compositionObject) => {
                const { role } = compositionObject;
                if (!acc[role._environmentName]) {
                  acc[role._environmentName] = [];
                }
                acc[role._environmentName].push(compositionObject);
                return acc;
              }, {});

              const roles = Object.values(grouped)
                .flat(1)
                .map((accessProduct) => ({
                  name: accessProduct.role.displayName,
                  status: accessProduct.role.status,
                }))
                .filter((role) => role.status === 'exists');

              currentService.accessProducts = roles;
            })
            .finally(() => {
              count += 1;
              if (count === apmids.length) {
                this.showAccessProducts = true;
                this.acccesProductsLoaindg = false;
              }
            });
        });
        await Promise.all(promises);
        await this.getExistAccessProduct();
      }
    },
    async getExistAccessProduct() {
      apollo.apolloClient
        .query({
          query: gql`
            query {
              accessRequestStatuses
            }
          `,
        })
        .then((resp) => {
          const {
            data: { accessRequestStatuses },
          } = resp;
          this.existingRolesSet = accessRequestStatuses.map((i) => i.u_access_product);
          this.selectedAccessProducts = [...this.existingRolesSet];
        });
    },
    goToAdr(id) {
      this.$router.push(`/adrs/${id}`);
    },
    async showFeedbackDialog(service) {
      await apollo.apolloClient
        .query({
          query: gql`
          query {
            feedbacks(infraServiceId: "${service.infraService.apmid}", digitalServiceId: "${this.apmId}") {
              id
              title
              description
              isLike
              stars
              createdAt
              createdBy {
                name
              }
            }
          }
        `,
        })
        .then((res) => {
          this.currentServiceFeedbacks = res.data.feedbacks;
        });
      this.feedbackService = service.infraService;
      this.feedbackDialog = true;
    },
    async createFeedback(feedback) {
      this.feedbackLoad = true;
      await apollo.apolloClient
        .mutate({
          mutation: gql`
            mutation createFeedback(
              $infraServiceId: String
              $digitalServiceId: String
              $feedback: FrontendFeedbackInput!
            ) {
              createFeedback(feedback: $feedback, infraServiceId: $infraServiceId, digitalServiceId: $digitalServiceId)
            }
          `,
          variables: {
            feedback: feedback,
            infraServiceId: this.feedbackService.apmid,
            digitalServiceId: this.apmId,
          },
        })
        .then((res) => {
          if (res.data.createFeedback) {
            this.$notify(
              'positive',
              'Success',
              `Feedback for ${
                this.feedbackService?.displayName || this.feedbackService?.serviceName
              } was sent successfully.`,
            );
          }
        })
        .catch((error) => {
          this.$notify('negative', 'Error', error.message);
        })
        .finally(() => {
          this.feedbackLoad = false;
          this.feedbackDialog = false;
          this.feedbackService = undefined;
        });
    },
  },
  computed: {
    ...mapGetters('app', {
      userInfo: APP_GETTERS.USER_INFO,
    }),
    ...mapGetters('banners', {
      currentBanner: BANNERS_GETTERS.BANNERS_SHOW,
    }),
    commentsPages() {
      return Math.ceil(this.comments.length / this.showCommentsCount);
    },
    showingComments() {
      return JSON.parse(JSON.stringify(this.comments)).splice(
        (this.commentsPage - 1) * this.showCommentsCount,
        this.showCommentsCount,
      );
    },
    apmId() {
      return this.$route.params.id.toUpperCase();
    },
    filteredItems() {
      return this.allInfraservices.map((element) => {
        return {
          ...element,
          infraServices: element.infraServices.filter(
            (subElement) =>
              !this.uiConfig.hideServices.includes(subElement.infraServiceAPMID) &&
              subElement.infraServiceDisplayName.toLowerCase().includes(this.serviceSearch.toLowerCase()),
          ),
        };
      });
    },
  },
  async mounted() {
    const currentMonth = dayjs().month() + 1;
    const month = currentMonth === 0 ? 12 : currentMonth;
    const year = dayjs().year();
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    this.costDates = [
      {
        label: `${months[0]} ${year} - ${months[month - 1]} ${year}`,
        value: `${year}-01 ${year}-${month}`,
      },
      {
        label: `${months[0]} ${year - 1} - ${months[11]} ${year - 1}`,
        value: `${year - 1}-01 ${year - 1}-12`,
      },
      {
        label: `${months[0]} ${year - 2} - ${months[11]} ${year - 2}`,
        value: `${year - 2}-01 ${year - 2}-12`,
      },
    ];
    await this.setCostDate(this.costDates[0].value);
    this.uiConfig = uiConfig[process.env.VUE_APP_ENV];
    await this.getUserInfo();
    await this.load();
    if (this.$route.query.infraservice) {
      const cat = this.serviceCategories.find((category) =>
        category.infraServices.find((item) => item.infraService.apmid === this.$route.query.infraservice),
      );
      const service = cat.infraServices.find((item) => item.infraService.apmid === this.$route.query.infraservice);
      this.scrollToItem(service);
    }
  },
};
</script>

<style lang="scss">
.q-scrollarea__content.absolute {
  max-width: 100%;
}
.single-service-page {
  ul.zones,
  ul.services-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .info-holder {
    border-radius: 15px;
  }

  .info-table {
    width: 100%;

    th {
      vertical-align: text-top;
    }
  }

  .services-category {
    display: block;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 1px;
    color: #9a9ba0;
  }

  .services-list {
    li {
      font-size: 16px;
      margin-bottom: 5px;
      cursor: pointer;

      strong {
        position: relative;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 25px;
        max-width: calc(100% - 15px);
        display: block;
      }

      .draft-icon {
        position: absolute;
        top: -4px;
        right: 0;
      }
    }

    .point {
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 10px;
      background-color: $grey-6;

      &.private {
        background-color: #428ce1;
      }

      &.public {
        background-color: #73e1a5;
      }

      &.onprem {
        background-color: #a88cd1;
      }

      &.na {
        background-color: #b3bb7e;
      }

      &.tool {
        background-color: #bfb9a7;
      }
    }
  }

  .block-holder {
    margin-bottom: 10px;
    padding: 20px;
    background-color: white;
    border-radius: 15px;
    overflow: hidden;

    .service-header {
      display: flex;
      align-items: center;
      width: 100%;

      .q-item__section i {
        color: $text-color;
      }

      strong {
        font-size: 20px;
      }
    }
  }

  .icon-service {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 2px solid;
    margin-right: 10px;

    &.private {
      border-color: #428ce1;
      color: #428ce1;
    }

    &.public {
      border-color: #73e1a5;
      color: #73e1a5;
    }

    &.onprem {
      border-color: #a88cd1;
      color: #a88cd1;
    }

    &.na {
      border-color: #b3bb7e;
      color: #b3bb7e;
    }

    &.tool {
      border-color: #bfb9a7;
      color: #bfb9a7;
    }
  }

  .service-actions {
    .q-item__section--avatar {
      min-width: 35px;
      padding-right: 5px;
    }
  }

  .label-block {
    display: block;
    font-weight: bold;
    font-size: 15px;
  }

  .actions {
    ul {
      column-count: 3;
    }

    i {
      transform: rotate(90deg);
      margin-right: 3px;
    }

    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: #2196f3;
    }
  }

  .info-block {
    li {
      font-weight: 300;

      span {
        font-weight: 400;
        text-transform: capitalize;
        margin-right: 5px;
      }
    }
  }

  .nav-holder {
    border-radius: 25px;
    padding: 10px 20px;
    background-color: #e7e9f1;

    .q-scrollarea__content {
      max-width: 100%;
    }
  }

  .info-table {
    border-collapse: collapse;

    th,
    td {
      border-spacing: 0;
      padding: 10px 0;
    }

    &.bordered {
      th,
      td {
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      }
    }
  }

  .service-list {
    margin-top: 20px;
  }

  .add-service-btn {
    text-transform: capitalize;
    padding: 20px 0;
    font-size: 24px;
  }

  .selected-services {
    width: calc(100% - 210px);
    margin-right: 10px;
    height: 80px;
    border: 1px solid #7e8286;
    border-radius: 10px;
    background-color: white;
    padding: 6px;

    .q-scrollarea__content.absolute {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
  }

  .orderable-services {
    span {
      display: block;
      font-size: 10px;
      text-transform: uppercase;
      font-weight: bold;
      color: #979ba4;
    }

    .infraservices-holder {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      max-width: 100%;
    }

    .infra-service {
      display: flex;
      cursor: pointer;
    }
  }
}
.widget-panels {
  .q-panel {
    overflow: hidden !important;
  }
}
.widget-tabs {
  .q-tab {
    min-height: 38px;
  }
  .q-tab__label {
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
  }
}
.sub-tabs {
  .q-tab {
    min-height: 30px;
  }
  .q-tab__label {
    text-transform: none;
    letter-spacing: normal;
  }
  .q-tab__indicator {
    height: 1px;
  }
}
.table-actions {
  &.table-info {
    border-bottom: none;
  }
  div:first-child {
    border-top-right-radius: 0px;
  }
  .q-table__top {
    position: absolute;
    bottom: 10px;
    left: 0;
    padding: 0;
  }
  .q-table thead {
    background-color: #edeff5;
    th {
      border-bottom-width: 0 !important;
    }
  }
}
.actions-filter-holder {
  background-color: #edeff5;
  padding: 10px 5px 5px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.digital-service-page {
  .service-description {
    max-width: 100%;
  }
}
.infra-service {
  &.disable {
    background-color: #d6d9dc !important;
    cursor: not-allowed;
  }
}

.links {
  display: flex;
  span {
    display: block;
    text-transform: capitalize;
  }
  ul {
    flex-direction: column;
    margin: 0 !important;
    padding: 0;
  }
  li {
    margin: 0 !important;
    list-style-type: none;
  }
  a {
    color: var(--q-info);
  }
}
.cost-select .q-field__native.items-center span {
  max-width: 100%;
  overflow: visible;
}
.access-product .q-item {
  justify-content: space-between;
  padding: 0 10px;
  min-height: 20px;
  font-size: 12px;
  margin-bottom: 3px;

  a {
    color: #3c4150;

    &:visited {
      color: #979ba4;
    }
  }
}
.confirm-dialog {
  span.block {
    font-weight: 500;
    color: #878788;

    b {
      color: #1d1d1d;
    }
  }
}

.adr {
  position: relative;
  height: 150px;
  border: 1px solid $grey-5;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;

  .adr-mark {
    position: absolute;
    right: 15px;
    top: -8px;
  }

  .scope {
    display: block;
    white-space: nowrap;
    max-width: calc(100% - 5px);
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .adr-title {
    display: block;
    font-size: 17px;
    font-weight: bold;
    white-space: nowrap;
    max-width: calc(100% - 5px);
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0;
  }

  .adr-content {
    height: 52px;
    font-size: 12px;
    margin-bottom: 5px;
    overflow: hidden;
  }

  .metadata {
    white-space: nowrap;
    max-width: calc(100% - 5px);
    overflow: hidden;
    font-weight: bold;
    font-size: 12px;

    span {
      margin-right: 5px;
    }
  }
}
.order-list {
  column-count: 2;
  list-style-type: none;
}
.warning-text {
  padding-left: 10px;
  margin: 5px 0;
  border-left: 5px solid $blue-5;
  p {
    margin: 0;
  }
}

.adr-holder {
  transition: all 0.5s;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1);
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
    content: '';
    opacity: 0;
    z-index: -1;
    border-radius: 10px;
    background-color: white;
  }
  &:hover,
  &:focus {
    transform: scale3d(1.006, 1.006, 1);

    .adr {
      border: none;
    }

    &::after {
      opacity: 1;
    }
  }
}
.indicator {
  display: flex;
  position: absolute;
  height: 5px;
  width: 100%;
  top: 0px;
}
.adherence {
  display: flex;
  align-items: center;

  span {
    display: block;
    width: 15px;
    height: 15px;
    margin-right: 5px;
    border-radius: 50%;
  }
}
.draft-label {
  position: absolute;
  right: -44px;
  transform: rotate(45deg);
  background: orange;
  width: 90px;
  text-align: center;
  font-size: 12px;
  top: -7px;
  color: #000;
  padding: 2px;
}
</style>
