<template>
  <span class="label-block q-mt-md" v-if="Object.values(infraService.links).length">Links:</span>
  <div class="links q-mb-md" v-if="Object.values(infraService.links).length">
    <Links :infraService="infraService" />
  </div>
  <div v-if="!loadData" class="flex items-center justify-center q-py-lg">
    <q-spinner-gears size="40px" color="primary"></q-spinner-gears>
  </div>
  <div
    v-else-if="loadData && !Object.values(registrationData).length"
    class="flex full-width column items-center q-py-lg"
    style="padding: 30px 0"
  >
    <q-icon name="hide_source" class="q-mb-sm" size="40px" color="primary"></q-icon>
    <span class="block" style="font-size: 16px">No data</span>
  </div>
  <div v-else>
    <EnvRegistration
      class="q-mb-md"
      :registrationId="registrationId"
      :registrationData="registrationData"
      :existEnvironments="environments"
    />
    <div class="q-mb-lg">
      <span class="label-block q-mb-xs">Additional Registration Info:</span>
      <ul v-if="details" class="details">
        <li>
          <b>Business Area:</b>
          <div class="q-ml-auto">
            {{ details?.businessArea || 'n/a' }}
          </div>
        </li>
        <li>
          <b>Data Classification:</b>
          <div class="q-ml-auto">
            {{ details?.dataClassification || 'n/a' }}
          </div>
        </li>
        <li>
          <b>IT Domain:</b>
          <div class="q-ml-auto">
            {{ details?.itDomain || 'n/a' }}
          </div>
        </li>
      </ul>
      <div v-else class="flex full-width column items-center q-py-xs" style="padding: 10px 0">
        <q-icon name="hide_source" class="q-mb-sm" size="40px" color="primary"></q-icon>
        <span class="block" style="font-size: 16px">No data</span>
      </div>
    </div>
  </div>
  <q-expansion-item class="access-products-item">
    <template v-slot:header>
      <strong>Access products</strong>
    </template>
    <div v-if="loadAccessProducts" class="flex items-center justify-center q-py-lg" style="padding: 30px 0">
      <q-spinner-gears size="40px" color="primary"></q-spinner-gears>
    </div>
    <div class="actions-table-holder q-mt-md" v-else-if="Object.keys(accessProducts).length">
      <access-products-table :accessProducts="accessProducts"></access-products-table>
    </div>
    <div v-else class="flex full-width column items-center q-py-lg" style="padding: 30px 0">
      <q-icon name="hide_source" class="q-mb-sm" size="40px" color="primary"></q-icon>
      <span class="block" style="font-size: 16px">No data</span>
    </div>
  </q-expansion-item>
</template>

<script>
import { getRegistration } from '@/pages/registrations/api/api';
import AccessProductsTable from '@/pages/digitalService/components/AccessProductsTable.vue';
import Links from './Links.vue';
import EnvRegistration from '@/pages/registrations/components/EnvRegistration.vue';

export default {
  name: 'DbaasWidget',
  components: {
    AccessProductsTable,
    Links,
    EnvRegistration,
  },
  props: {
    registrationId: { type: String },
    infraService: { type: Object },
    accessProducts: { type: Object },
    loadAccessProducts: { type: Boolean, default: () => false },
  },
  data() {
    return {
      loadData: false,
      environments: [],
      registrationData: {},
      details: undefined,
    };
  },
  async mounted() {
    try {
      const data = await getRegistration(this.registrationId, true, true)
        .finally(() => {
          this.loadData = true;
        })
        .catch((error) => {
          this.$notify('negative', 'Error', error.message);
        });
      this.details = data && data.details ? data?.details[0] : undefined;
      this.environments = data.environments;
      this.registrationData.digitalServiceApm = data.digitalService.apmId;
      this.registrationData.infrastructureServiceApm = data.infraService.apmId;
    } catch (error) {
      this.$notify('negative', 'Error', error.message);
    } finally {
      this.loadData = true;
    }
  },
};
</script>

<style lang="scss" scoped>
.details {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
  padding: 0;

  li {
    width: calc(33.333% - 10px);
    margin: 0 5px;
    padding: 20px;
    border: 1px solid $grey-4;
    border-radius: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    @media (max-width: $breakpoint-sm-max) {
      width: 100%;
    }
  }
}
</style>
