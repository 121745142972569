
import { ControlElement } from '@jsonforms/core';
import { rendererProps, RendererProps, useJsonFormsEnumControl } from '@jsonforms/vue';
import { defineComponent } from 'vue';

const controlRenderer = defineComponent({
  name: 'enum-control-renderer',
  components: {},
  props: {
    ...rendererProps<ControlElement>(),
  },
  setup(props: RendererProps<ControlElement>) {
    return useJsonFormsEnumControl(props);
  },
  data() {
    return {
      disable: false,
    };
  },
  mounted() {
    if (this.control?.data?.length && !this.control?.uischema?.options?.editable) {
      this.disable = true;
    }
    if (!this.control.data && this.control.uischema.options?.default) {
      this.control.data = this.control.uischema.options.default;
    }
    this.onChange(this.control.data);
  },
  computed: {
    options() {
      const key: any =
        this.control.uischema.options && this.control.uischema.options.key ? this.control.uischema.options.key : null;
      const options: any[] = [];
      this.control?.options?.forEach((item: any) => {
        if (
          this.control.uischema.options &&
          this.control.uischema.options.disabledOptions &&
          this.control.uischema.options.disabledOptions.includes(item.value)
        ) {
          item.disable = true;
        }
        options.push(item);
      });
      return options;
    },
  },
  methods: {
    onChange(value: any) {
      this.handleChange(this.control.path, value);
    },
  },
});

export default controlRenderer;
