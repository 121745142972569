<template>
  <q-expansion-item
    class="event bg-white btn--no-hover"
    :class="{
      error: eventData.isError,
      'bg-grey-3': eventStatus.status === 'cancelled',
      expanded: expand,
      collapsed: !expand,
    }"
    hide-expand-icon
    @update:model-value="expandItem"
    v-model="expand"
  >
    <template v-slot:header>
      <div class="full-width flex items-start">
        <div class="flex items-center event-name q-mr-lg">
          <q-icon :name="`engp:engp-ico-${eventData.type}`" size="16px" class="q-mr-xs"></q-icon>
          <b>{{ eventData.type }}</b>
          <q-icon v-if="eventData.isError" name="cancel" class="q-ml-xs" color="negative" size="xs"></q-icon>
        </div>
        <Status v-if="eventData?.subscriptions.length" :showTooltip="false" :workflow="eventStatus" class="q-mr-md" />
        <div
          v-if="eventData.type !== 'timer'"
          class="service-description-markdown event-message"
          v-html="dataMarkdown(eventData.message)"
        ></div>
        <div v-else class="timer-holder flex items-center">
          <div class="progress-container q-mr-md">
            <div class="progress-bar-background">
              <div class="progress-bar-foreground" :style="progressStyle"></div>
            </div>
          </div>
          <span>ends at {{ finishTime }}</span>
          <div class="service-description-markdown full-width q-mt-sm" v-html="dataMarkdown(eventData.message)"></div>
        </div>
        <div class="flex items-center q-ml-auto">
          <span class="date flex items-center">
            <q-icon name="engp:engp-ico-clock" size="16px" class="q-mr-xs"></q-icon>
            {{ $options.dayjs(eventData.createdAt).format('DD.MM.YYYY HH:mm') }}
          </span>
          <q-icon name="engp:engp-ico-copy" @click.stop="copyUuid(eventData.uuid)" size="16px" class="q-mx-md">
            <q-tooltip>Copy UUID</q-tooltip>
          </q-icon>
          <q-icon
            v-if="eventData.type === 'subscription' || eventData.events.length"
            class="event-arrow"
            size="sm"
            name="keyboard_arrow_down"
          ></q-icon>
          <sup>
            <q-icon v-if="eventData?.hasChildError" name="cancel" class="q-mb-md" color="negative" size="12px"></q-icon>
          </sup>
          <q-btn
            v-if="eventData?.events?.length && eventData?.events?.find((e) => e?.events?.length)"
            flat
            no-caps
            class="q-ml-auto btn--no-hover text-weight-regular"
            round
            :icon="mainExpand || (this.expand && someChildEXpand) ? 'sym_o_unfold_more' : 'sym_o_unfold_less'"
            size="15px"
            style="min-height: 30px"
            :ripple="false"
            @click.stop="expandChild"
          >
            <q-tooltip>{{
              mainExpand || (this.expand && someChildEXpand) ? 'Collapse all child' : 'Expand all child'
            }}</q-tooltip>
          </q-btn>
        </div>
      </div>
    </template>
    <div v-if="eventData?.subscriptions.length || eventData?.events.length" class="q-mb-sm q-px-md q-pb-md">
      <div
        v-for="subscription in eventData?.subscriptions"
        class="event-item q-mb-xs q-px-lg q-py-lg bg-white"
        :class="{ 'bg-grey-2': subscription.status === 'cancelled' }"
        :key="subscription.uuid"
      >
        <div>
          <action :actionData="subscription" @resolve="reload()" />
        </div>
      </div>
      <div v-if="eventData?.events?.length">
        <event
          @expanChild="checkIsChildExpand"
          v-for="event in eventData.events"
          :parentExpand="mainExpand"
          :key="event.uuid"
          class="child-event"
          :eventData="event"
        ></event>
      </div>
    </div>
  </q-expansion-item>
</template>

<script>
import dayjs from 'dayjs';
import { marked } from 'marked';
import { copyToClipboard } from 'quasar';
import emitter from 'tiny-emitter/instance';
import Action from './Action.vue';
import Status from './Status.vue';

const renderer = new marked.Renderer();

renderer.link = function (href, title, text) {
  return `<a href="${href}" target="_blank" rel="noopener noreferrer">${text}</a>`;
};

marked.setOptions({ renderer });

export default {
  dayjs,
  copyToClipboard,
  props: {
    eventData: {
      type: Object,
    },
    parentExpand: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    parentExpand(value) {
      this.expand = value;
      this.mainExpand = value;
    },
    expand(value) {
      if (this.eventData?.eventUuid && this.eventData?.events?.length) {
        this.$emit('expanChild', value);
      }
    },
  },
  name: 'EventItem',
  emits: ['resolve', 'reload'],
  components: {
    Action,
    Status,
  },
  created() {
    emitter.on('expandCollapseAll', (flag) => {
      this.expand = flag;
    });
  },
  unmounted() {
    emitter.off('expandCollapseAll');
  },
  data() {
    return {
      expand: false,
      progressInterval: undefined,
      mainExpand: false,
      someChildEXpand: false,
    };
  },
  mounted() {
    this.progressInterval = setInterval(() => {
      this.$forceUpdate();
    }, 1000);
  },
  beforeUnmount() {
    clearInterval(this.progressInterval);
  },
  computed: {
    timeElapsed() {
      const now = new Date();
      const createdAtDate = new Date(this.eventData.createdAt);
      return Math.max(Math.floor((now - createdAtDate) / 1000), 0);
    },
    finishTime() {
      const createdAtDate = new Date(this.eventData.createdAt);
      const finishTimestamp = createdAtDate.getTime() + this.eventData?.duration * 1000;
      const finishDate = new Date(finishTimestamp);
      return dayjs(finishDate).format('DD.MM.YYYY HH:mm');
    },
    progressPercentage() {
      return Math.min(Math.floor((this.timeElapsed / this.eventData?.duration) * 100), 100);
    },
    progressStyle() {
      return {
        width: `${this.progressPercentage}%`,
      };
    },
    eventStatus() {
      const eventStatus = {
        status: undefined,
      };
      if (this.eventData.subscriptions?.find((i) => i.cancelled)) {
        eventStatus.status = 'cancelled';
      }
      if (this.eventData.subscriptions?.find((i) => i.status === 'resolved')) {
        eventStatus.status = 'resolved';
      }
      if (this.eventData.subscriptions?.find((i) => i.status === 'pending')) {
        eventStatus.status = 'pending';
      }

      return eventStatus;
    },
  },
  methods: {
    checkIsChildExpand(flag) {
      this.someChildEXpand = flag;
    },
    expandItem() {
      if (this.eventData?.eventUuid && this.eventData?.events?.length) {
        this.$emit('expanChild', !this.expand);
      }
    },
    expandChild() {
      if (!this.expand) {
        this.mainExpand = !this.mainExpand;
        this.expand = true;
      } else {
        this.mainExpand = !this.mainExpand;
      }
    },
    copyUuid(uuid) {
      copyToClipboard(uuid).then(() => {
        this.$notify('info', 'Info', 'Subscription UUID was copied');
      });
    },
    reload() {
      this.$emit('reload');
    },
    dataMarkdown(data) {
      return marked(data);
    },
  },
};
</script>

<style lang="scss">
.progress-container {
  width: 100%;
  max-width: 320px;
  text-align: center;
}

.progress-bar-background {
  width: 100%;
  height: 16px;
  background-color: #e0e0e0;
  border-radius: 10px;
}

.progress-bar-foreground {
  height: 100%;
  background-color: #73e1a5;
  border-radius: 10px;
}
.event.expanded > .q-expansion-item__container {
  .event-arrow {
    transform: rotate(180deg);
  }
}
.event.collapsed > .q-expansion-item__container {
  .event-arrow {
    transform: rotate(0deg);
  }
}
.event {
  border-radius: 15px;
  &.error {
    background-color: #ffe8e8 !important;
  }

  .event-arrow {
    transition: all 0.5s;
  }

  .service-description-markdown {
    font-size: 14px;
  }

  .event-message,
  .timer-holder {
    max-width: 59%;
    width: 100%;

    @media (max-width: $breakpoint-md-max) {
      max-width: 100%;
      width: 100%;
      order: 4;
      margin-top: 10px;
      margin-left: 0;
    }
  }

  .q-item {
    padding: 24px;
  }

  .event-name {
    min-width: 100px;
    text-transform: capitalize;
  }

  .event-item,
  .child-event {
    border: 1px solid $grey-5;
    border-radius: 15px;
    margin-bottom: 8px;
  }
}
</style>
