<template>
  <q-page class="main-wrapper-page single-page" style="background-color: var(--q-accent)">
    <section class="widget-section q-mb-lg">
      <EnvRegistration :registrationId="registrationId" :registrationData="registrationData" :envs="envs" mode="edit" />
    </section>
  </q-page>
</template>
<script>
import EnvRegistration from './EnvRegistration.vue';

export default {
  name: 'Registration2Env',
  components: {
    EnvRegistration,
  },
  props: {
    registrationId: {
      type: String,
    },
    registrationData: {
      type: Object,
    },
  },
  data() {
    return {
      envs: ['PROD', 'NONPROD', 'DEV'],
    };
  },
};
</script>
