import { postBackbone, postServiceCatalog, deleteBackbone } from '@/library/http';

export const getUserBatchPermissions = (data: any) => postServiceCatalog(`/auth/batch`, data);
export const getUserPermissions = (data: any) => postServiceCatalog(`/auth`, data);
export const getAccessProducts = (data: any) => {
  const isCloudente = data.infraServiceApmId === 'CLOUDENTE';
  const url = `/role-composition${isCloudente ? '/CLOUDENTE' : ''}?status=true`;
  return postBackbone(url, data);
};
export const preCreateDraft = (data: any) => postBackbone('/registrations/preval/create-draft', data);
export const createDraft = (data: any) => postBackbone('/registrations/create-draft', data);
export const deleteDraft = (data: any) => deleteBackbone(`/registrations/delete-draft`, data);
