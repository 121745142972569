<template>
  <div
    class="infra-service"
    :class="[
      `${dataService.cloudType ? dataService.cloudType.toLowerCase() : 'na'}`,
      { tool: dataService.type === 'Tool' },
    ]"
  >
    <slot name="tooltip"></slot>
    <slot name="status">
      <span class="icon-service"></span>
    </slot>
    <span class="service-name">
      <slot name="loading"></slot>
      {{ dataService.parentServiceDisplayName || dataService.displayName || dataService.serviceName }}
    </span>
    <slot name="buttons"></slot>
  </div>
</template>

<script>
export default {
  name: 'InfraService',
  props: {
    dataService: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.infra-service {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 10px;
  height: 30px;
  max-width: 170px;
  margin-right: 5px;
  margin-bottom: 5px;

  .icon-service {
    display: flex;
    min-width: 10px;
    height: 10px;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    border-radius: 50%;
    width: 10px;
    border: none;
  }

  &.private {
    background-color: #e9f4ff;

    .action-block {
      background-color: #e9f4ff;
    }

    .icon-service {
      background-color: #428ce1;
    }

    .service-label {
      i {
        color: #428ce1;
      }
    }
  }

  &.public {
    background-color: #e3f9ed;

    .action-block {
      background-color: #e3f9ed;
    }

    .icon-service {
      background-color: #73e1a5;
    }

    .service-label {
      i {
        color: #73e1a5;
      }
    }
  }

  &.onprem {
    background-color: #eee6f8;

    .action-block {
      background-color: #eee6f8;
    }

    .icon-service {
      background-color: #a88cd1;
    }

    .service-label {
      i {
        color: #a88cd1;
      }
    }
  }

  &.na {
    background-color: #eff5c6;

    .action-block {
      background-color: #eff5c6;
    }

    .icon-service {
      background-color: #b3bb7e;
    }

    .service-label {
      i {
        color: #b3bb7e;
      }
    }
  }

  &.tool {
    background-color: #f4f0e6;

    .action-block {
      background-color: #f4f0e6;
    }

    .icon-service {
      background-color: #bfb9a7;
    }

    .service-label {
      i {
        color: #bfb9a7;
      }
    }
  }
}

.service-name {
  font-size: 12px;
  font-weight: bold;
  max-width: 120px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
