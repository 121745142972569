<template>
  <q-page class="main-wrapper-page single-page" style="background-color: var(--q-accent)">
    <section v-if="loadData" class="widget-section q-mb-lg">
      <EnvRegistration
        :registrationId="registrationId"
        :registrationData="registrationData"
        :envs="envs"
        :existEnvironments="environments"
        mode="edit"
      />
    </section>
    <section v-if="loadData" class="widget-section">
      <q-banner v-if="!environments.length" rounded style="min-height: 40px" class="bg-info text-white q-mb-md">
        Please register environment for VNETs creation.
      </q-banner>
      <div class="flex items-center">
        <span class="label-block">Zones:</span>
        <q-btn
          @click="showForm"
          flat
          no-caps
          class="q-ml-auto btn--no-hover text-weight-regular"
          round
          color="light-blue-5"
          size="15px"
          style="min-height: 30px"
          :ripple="false"
          :disabled="createZoneError?.length"
        >
          <q-tooltip v-if="createZoneError?.length">{{ createZoneError }}</q-tooltip>
          + Add Zone
        </q-btn>
      </div>
      <div v-if="showCreateForm">
        <div class="update-form">
          <q-banner v-if="generalErrors.length" class="text-white approval-error-banner q-mb-sm">
            <span class="block" v-for="(error, key) in generalErrors" :key="key">{{ error.message }}</span>
          </q-banner>
          <div class="flex full-width no-wrap">
            <JsonForms
              :renderers="renderers"
              :schema="schema"
              :data="formData"
              :uischema="uiSchema"
              :additionalErrors="formErrors"
              validationMode="ValidateAndShow"
              @change="validateForm"
            />
            <q-btn
              color="primary"
              size="md"
              class="q-ml-sm"
              :loading="loadSend"
              :disable="
                loadSend ||
                submitDisable ||
                Boolean(formErrors.length) ||
                Boolean(createErrors.length) ||
                Boolean(generalErrors.length)
              "
              style="height: 38px"
              @click="sendData()"
              no-caps
              label="Submit"
            ></q-btn>

            <q-btn flat size="md" class="btn--no-hover" style="height: 40px" no-caps @click="closeForm">Cancel</q-btn>
          </div>
        </div>
      </div>
      <Zones
        :registrationData="registrationData"
        :registrationId="registrationId"
        mode="edit"
        @confirmDeleteZone="confirmDeleteZone"
        @reassign="openReassignDialog"
      />
    </section>

    <q-dialog v-model="deleteZoneConfirm" class="single-service-page" persistent>
      <q-card style="min-width: 768px; border-radius: 0px">
        <q-toolbar class="shadow-2 q-py-sm q-px-md">
          <q-toolbar-title class="text-weight-bold">
            <strong>Confirm Delete Zone</strong>
          </q-toolbar-title>
          <q-btn icon="close" round flat class="btn--no-hover" @click="deleteZoneConfirm = false"></q-btn>
        </q-toolbar>
        <q-card-actions class="q-pb-md">
          <q-btn color="primary" size="md" class="q-ml-sm" @click="deleteZone" no-caps label="Yes"></q-btn>
          <q-btn flat size="md" class="btn--no-hover" style="height: 40px" no-caps @click="deleteZoneConfirm = false"
            >No</q-btn
          >
        </q-card-actions>
      </q-card>
    </q-dialog>

    <ReassignDialog
      :showReassignDialog="showReassignDialog"
      entity="zone"
      :newDigitalService="newDigitalService"
      :loading="loadReassign"
      :errors="reassignErrors"
      @submit="sendReassignRequest"
      @cancel="closeReassignDialog"
      @resetService="resetDigitalService"
      @setService="setDigitalService"
    />
  </q-page>
</template>

<script>
import { JsonForms } from '@jsonforms/vue';
import emitter from 'tiny-emitter/instance';
import { getRegistration, getBluePrint, validateWorkFlow, createWorkFlow, subValWorkflow } from '../../api/api';
import { quasarRenderers } from '@/shared/jsonformsQuasarRenderers/renderers';
import EnvRegistration from '../EnvRegistration.vue';
import Zones from './Zones.vue';
import ReassignDialog from '../ReassignDialog.vue';

const renderers = [...quasarRenderers];

export default {
  name: 'RegistrationCloudente',
  components: {
    JsonForms,
    Zones,
    EnvRegistration,
    ReassignDialog,
  },
  props: {
    registrationId: {
      type: String,
    },
    registrationData: {
      type: Object,
    },
  },
  data() {
    return {
      envs: ['PROD'],
      environments: [],
      createZoneError: undefined,
      deleteZoneConfirm: false,
      loadData: false,
      showCreateForm: false,
      formErrors: [],
      createErrors: [],
      generalErrors: [],
      loadSend: false,
      submitDisable: true,
      renderers: Object.freeze(renderers),
      validationTimeout: undefined,
      currentZone: undefined,
      formData: {
        digitalServiceApmId: this.registrationData.digitalServiceApm,
      },
      schema: {},
      uiSchema: {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Zone name',
            scope: '#/properties/zone_name',
          },
          {
            type: 'Control',
            label: 'License',
            scope: '#/properties/license',
          },
          {
            type: 'Control',
            label: 'DNS',
            scope: '#/properties/dns',
            class: 'col-2',
          },
        ],
      },
      showReassignDialog: false,
      newDigitalService: undefined,
      loadReassign: false,
      reassignErrors: undefined,
    };
  },
  methods: {
    async sendReassignRequest() {
      this.loadReassign = true;
      const data = {
        workflowId: 'engprt.create-cloudflare-zone',
        inputVariables: {
          digitalServiceApmId: this.newDigitalService.apmId,
          license: this.currentZone.license,
          dns: false,
          zone_name: this.currentZone.name,
          zoneReassignment: {
            id: this.currentZone.id,
          },
        },
      };
      createWorkFlow(data)
        .then(() => {
          this.$notify('positive', 'Success', `Zone will be reassign to ${this.newDigitalService.digitalServiceName}.`);
          emitter.emit('reloadZones');
        })
        .catch((error) => {
          this.$notify('negative', 'Error', error.message);
        })
        .finally(() => {
          this.closeReassignDialog();
        });
    },
    resetDigitalService() {
      this.newDigitalService = undefined;
      this.reassignErrors = undefined;
    },
    async setDigitalService(service) {
      this.reassignErrors = undefined;
      this.loadReassign = true;
      this.newDigitalService = service;
      const data = {
        workflowId: 'engprt.create-cloudflare-zone',
        inputVariables: {
          digitalServiceApmId: this.newDigitalService.apmId,
          license: this.currentZone.license,
          dns: false,
          zone_name: this.currentZone.name,
          zoneReassignment: {
            id: this.currentZone.id,
          },
        },
      };
      validateWorkFlow(data)
        .then(() => {
          this.reassignErrors = undefined;
        })
        .catch((error) => {
          this.reassignErrors = error.message;
        })
        .finally(() => {
          this.loadReassign = false;
        });
    },
    closeReassignDialog() {
      this.newDigitalService = undefined;
      this.currentZone = undefined;
      this.showReassignDialog = false;
      this.reassignErrors = undefined;
    },
    openReassignDialog(zone) {
      this.currentZone = zone;
      this.showReassignDialog = true;
    },
    async validateForm(value) {
      console.log(value.errors);
      if (JSON.stringify(value.data) !== JSON.stringify(this.formData) && !value.errors.length) {
        this.formErrors = value.errors;
        this.formData = value.data;
        this.loadSend = true;
        if (this.validationTimeout) clearTimeout(this.validationTimeout);
        this.validationTimeout = setTimeout(async () => {
          const workflowData = {
            workflowId: 'engprt.create-cloudflare-zone',
            inputVariables: this.formData,
          };
          try {
            await validateWorkFlow(workflowData);
            this.createErrors = [];
            this.generalErrors = [];
            this.submitDisable = false;
          } catch (error) {
            if (error?.response?.data?.errors?.length) {
              this.createErrors = error.response.data.errors.filter((error) => error.instancePath.length);
              this.generalErrors =
                error.response.data.errors.filter((error) => !error.instancePath.length && error?.params.toDisplay) ||
                [];
            } else {
              this.$notify('negative', 'Error', error.message);
              this.createErrors = [];
              this.generalErrors = [];
              clearTimeout(this.validationTimeout);
            }
          } finally {
            this.loadSend = false;
          }
        }, 3000);
      }
    },
    closeForm() {
      this.showCreateForm = false;
    },
    confirmDeleteZone(zone) {
      this.currentZone = zone;
      this.deleteZoneConfirm = true;
    },
    async deleteZone() {
      const deleteZoneWorkflow = {
        workflowId: 'engprt.delete-cloudflare-zone',
        inputVariables: {
          registration_id: this.registrationId,
          digitalServiceApmId: this.registrationData.digitalServiceApm,
          id: this.currentZone.id,
        },
      };
      try {
        await createWorkFlow(deleteZoneWorkflow);
        this.$notify('positive', 'Success', 'Zone will be deleted.');
        emitter.emit('reloadZones');
        emitter.emit('reloadEnvironments');
      } catch (error) {
        this.$notify('negative', 'Error', error.message);
      }
    },
    async sendData() {
      try {
        const workflowData = {
          workflowId: 'engprt.create-cloudflare-zone',
          inputVariables: this.formData,
        };
        this.loadSend = true;
        await createWorkFlow(workflowData);
        this.$notify('positive', 'Success', 'Zone will be created.');
        emitter.emit('reloadZones');
        emitter.emit('reloadEnvironments');
        this.closeForm();
      } catch (error) {
        this.$notify('negative', 'Error', error.message);
      } finally {
        this.loadSend = false;
      }
    },
    showForm() {
      this.showCreateForm = true;
    },
  },
  async mounted() {
    const { inputVariablesSchema: schema } = await getBluePrint('engprt.create-cloudflare-zone');
    delete schema.$async;
    delete schema.$schema;
    this.schema = schema;
    this.loadData = false;
    this.$showLoading();
    try {
      const dataPromise = getRegistration(this.registrationId, true, false).then(async (response) => {
        this.environments = response.environments.map((env) => ({ status: env.status, value: env.value, id: env.id }));
      });
      const subvalCreateZone = {
        workflowId: 'engprt.create-cloudflare-zone',
        inputVariables: {
          registration_id: this.registrationId,
          digitalServiceApmId: this.registrationData.digitalServiceApm,
        },
        subSchemaId: 'add-button',
      };
      const subValPromise = subValWorkflow(subvalCreateZone)
        .then(() => {
          this.createZoneError = undefined;
        })
        .catch((error) => {
          this.createZoneError = error.message;
        });
      await Promise.all([dataPromise, subValPromise]);
    } catch (error) {
      this.$notify('negative', 'Error', error.message);
    } finally {
      this.loadData = true;
      this.$hideLoading();
    }
  },
};
</script>

<style lang="scss">
.update-form-cell {
  .q-banner {
    min-height: 38px;
    margin-bottom: 5px;
  }
}
.update-form {
  .q-field__bottom {
    padding: 3px 0 0 3px;
    white-space: pre;
  }
}
</style>
<style lang="scss" scoped>
.env-form {
  width: 50%;
}
.label-block {
  font-size: 20px;
}
.widget-section {
  border-radius: 15px;
  background-color: white;
  padding: 30px;
}
</style>
