import { getBackbone, postBackbone, putBackbone } from '@/library/http';

export const getWorkFlows = async (
  limit: number,
  skip: number,
  sortBy: string,
  descending: boolean,
  itemId: string,
  workflowId: string,
  status: string,
  cancelling: string,
) => {
  let filter = '';
  filter += `&order=${descending ? 'ASC' : 'DESC'}`;
  sortBy?.length ? (filter += `&orderBy=${sortBy}`) : false;
  itemId?.length ? (filter += `&itemId=${itemId}`) : false;
  workflowId?.length ? (filter += `&workflowId=${workflowId}`) : false;
  status?.length ? (filter += `&status=${status}`) : false;
  cancelling?.length ? (filter += `&cancelling=${cancelling === 'cancelling' ? true : false}`) : false;

  return getBackbone(`/workflows/?limit=${limit}&skip=${skip}${filter}`);
};

export const getWorkflowById = (id: string) => getBackbone(`/workflows/${id}`);

export const preResolveAction = (data: any) => postBackbone('/engine/preval/resolve', data);

export const resolveAction = (data: any) => postBackbone('/engine/resolve', data);

export const authResolveAction = (data: any) => postBackbone('/engine/preauth/resolve', data);

export const getRelationsByWorkflow = (id: string) => getBackbone(`/relations/by-workflow/${id}`);

export const refreshStatus = (id: string) => putBackbone(`/workflows/refresh-workflow-status/${id}`);

export const abandonWorkflow = (id: string) => putBackbone(`/workflows/abandon/${id}`);
