<template>
  <div class="flex">
    <q-btn
      class="filter-icon q-ml-auto btn--no-hover"
      :class="{ active: showFilters }"
      icon="filter_alt"
      flat
      :ripple="false"
      @click="showFilters = !showFilters"
    >
      <q-tooltip>{{ showFilters ? 'Hide filters' : 'Show filters' }}</q-tooltip>
    </q-btn>
  </div>
  <q-table
    :columns="cols"
    :rows="rows"
    class="external-dns-table"
    ref="externalDnsTable"
    row-key="id"
    flat
    v-model:pagination="pagination"
    :loading="loading"
    binary-state-sort
    :rows-per-page-options="[5, 7, 10, 15, 20]"
    @request="loadDnsRecords"
    :expanded="expanded"
  >
    <template v-slot:no-data>
      <div class="flex full-width column items-center q-py-lg">
        <q-icon name="mdi-cloud-search" class="q-mb-sm" size="40px" color="primary"></q-icon>
        <span class="block" style="font-size: 14px">No items were found.</span>
      </div>
    </template>
    <template v-slot:body-cell-status="props">
      <q-td style="width: 30px" :props="props">
        <StatusCell :status="props.row.status" />
      </q-td>
    </template>
    <template v-slot:top>
      <div v-if="showFilters" class="row no-wrap full-width q-mt-sm q-mb-xs q-col-gutter-sm">
        <div class="col">
          <q-select
            v-model="filters.type"
            :options="['A', 'AAAA', 'CNAME', 'CNAME-WITH-REDIRECT', 'TXT', 'NS', 'MX', 'SRV']"
            options-dense
            label="Type"
            outlined
            dense
            clearable
            @update:model-value="reloadDnsTable()"
            @clear="reloadDnsTable()"
            class="q-mb-sm"
          ></q-select>
        </div>
        <div class="col">
          <q-input
            v-model="filters.name"
            label="Record name"
            outlined
            dense
            @update:model-value="filterTable()"
            @clear="reloadDnsTable()"
            clearable
            class="q-mb-sm"
          ></q-input>
        </div>
        <div class="col">
          <q-input
            v-model="filters.domain"
            label="Domain"
            outlined
            dense
            clearable
            @update:model-value="filterTable()"
            @clear="reloadDnsTable()"
            class="q-mb-sm"
          ></q-input>
        </div>
        <div class="col">
          <q-input
            v-model="filters.value"
            label="Value"
            outlined
            @update:model-value="filterTable()"
            @clear="reloadDnsTable()"
            dense
            clearable
            class="q-mb-sm"
          ></q-input>
        </div>
      </div>
    </template>
    <template v-slot:body="props">
      <q-tr :props="props">
        <q-td v-for="col in props.cols" :key="col.name" :props="props" :align="col.align">
          <StatusCell v-if="col.name === 'status'" :status="col.value" />
          <ActionsCell
            v-else-if="col.name === 'actions'"
            @edit="$emit('toggleExpanded', props.row)"
            @remove="$emit('confirmDeleteDnsRecord', props.row)"
            @showWorkflows="showWorkflows(props.row)"
            @reassign="$emit('reassign', props.row)"
            :record="props.row"
            :disableEditDnsIds="disableEditDnsIds"
            :disableReassignDnsIds="disableReassignDnsIds"
            :loading="loading"
            :mode="mode"
            :disableDeleteDnsIds="disableDeleteDnsIds"
          />
          <span v-else-if="col.name === 'lastChangeLink'">
            <a v-if="props.row.lastChangeLink" target="_blank" :href="props.row.lastChangeLink">{{
              props.row.lastChangeNumber || 'Change Request'
            }}</a>
            <span v-else>-</span>
          </span>
          <div v-else>{{ col.value }}</div>
        </q-td>
      </q-tr>
      <q-tr v-if="expanded?.length ? props.row.id === expanded[0] : false" :props="props"
        ><slot name="expand"></slot
      ></q-tr>
    </template>
  </q-table>
</template>

<script>
import emitter from 'tiny-emitter/instance';
import dayjs from 'dayjs';
import { getExternalDnsRecords, subValWorkflow, validateWorkFlow } from '@/pages/registrations/api/api';
import StatusCell from './StatusCell.vue';
import ActionsCell from './ActionsCell.vue';

export default {
  name: 'ExternalDns',
  components: {
    StatusCell,
    ActionsCell,
  },
  props: {
    registrationId: {
      type: String,
    },
    registrationData: {
      type: Object,
    },
    expanded: {
      type: Array,
      default: () => [],
    },
    mode: {
      type: String,
      default: () => 'read',
    },
  },
  data() {
    return {
      cols: [
        {
          label: 'Status',
          name: 'status',
          field: 'status',
          align: 'left',
        },
        {
          label: 'Name',
          name: 'name',
          field: 'name',
          align: 'left',
        },
        {
          label: 'Type',
          name: 'type',
          field: 'type',
          align: 'left',
        },
        {
          label: 'Value',
          name: 'value',
          field: 'value',
          align: 'left',
        },
        {
          label: 'Domain',
          name: 'domain',
          field: 'domain',
          align: 'left',
        },
        {
          label: 'Created At',
          name: 'createdAt',
          field: 'createdAt',
          align: 'left',
          format: (val) => dayjs(val).format('DD.MM.YYYY HH:mm'),
          sortable: true,
        },
        {
          label: 'Updated At',
          name: 'updatedAt',
          field: 'updatedAt',
          align: 'left',
          format: (val) => dayjs(val).format('DD.MM.YYYY HH:mm'),
          sortable: true,
        },
        {
          label: 'Change Request',
          name: 'lastChangeLink',
          field: 'lastChangeLink',
          align: 'center',
        },
        {
          label: '',
          name: 'actions',
          field: 'actions',
          align: 'right',
        },
      ],
      rows: [],
      pagination: {
        sortBy: 'createdAt',
        descending: false,
        page: 1,
        rowsPerPage: 5,
      },
      loading: false,
      showFilters: false,
      timeout: undefined,
      disableEditDnsIds: [],
      disableDeleteDnsIds: [],
      disableReassignDnsIds: [],
      filters: {
        domain: undefined,
        type: undefined,
        name: undefined,
        value: undefined,
      },
    };
  },
  methods: {
    reloadDnsTable() {
      this.$refs.externalDnsTable.requestServerInteraction();
    },
    filterTable() {
      this.loading = true;
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        this.reloadDnsTable();
      }, 1500);
    },
    showWorkflows(record) {
      this.$router.push(`/workflows?itemId=${record.id}`);
    },
    async loadDnsRecords(props) {
      this.loading = true;
      try {
        const limit = props.pagination.rowsPerPage;
        const { sortBy, descending } = props.pagination;
        const skip = props.pagination.page === 1 ? 0 : (props.pagination.page - 1) * props.pagination.rowsPerPage;
        const { domain, type, name, value } = this.filters;
        const { count, data } = await getExternalDnsRecords(
          this.registrationId,
          limit,
          skip,
          sortBy,
          descending,
          domain,
          type,
          name,
          value,
        );
        this.pagination.rowsNumber = count;
        this.rows = data;
        this.pagination.page = props.pagination.page;
        this.pagination.sortBy = sortBy;
        this.pagination.descending = descending;
        this.pagination.rowsPerPage = props.pagination.rowsPerPage;
        if (this.mode === 'edit') {
          const deletePrevalPromises = this.rows.map((record) => {
            const data = {
              workflowId: 'engprt.delete-network-external-dns',
              inputVariables: {
                registration_id: this.registrationId,
                digitalServiceApmId: this.registrationData.digitalServiceApm,
                id: record.id,
              },
            };
            return validateWorkFlow(data).catch((res) => {
              this.disableDeleteDnsIds.push({ id: record.id, message: res.message });
            });
          });
          const movePrevalPromises = this.rows.map((record) => {
            const { id } = record;
            const data = {
              workflowId: 'engprt.move-network-external-dns',
              subSchemaId: 'move-button',
              inputVariables: {
                digitalServiceApmId: this.registrationData.digitalServiceApm,
                id,
              },
            };
            return subValWorkflow(data).catch((res) => {
              this.disableReassignDnsIds.push({ id: data.inputVariables.id, message: res.message });
            });
          });
          const editDnsPrevalPromises = this.rows.map((record) => {
            const { id, name, type, domain, value } = record;
            const data = {
              workflowId: 'engprt.update-network-external-dns',
              subSchemaId: 'edit-button',
              inputVariables: {
                registration_id: this.registrationId,
                digitalServiceApmId: this.registrationData.digitalServiceApm,
                id,
                name,
                type,
                domain,
                value,
              },
            };
            return subValWorkflow(data).catch((res) => {
              this.disableEditDnsIds.push({ id: data.inputVariables.id, message: res.message });
            });
          });
          await Promise.all([...editDnsPrevalPromises, ...deletePrevalPromises, ...movePrevalPromises]);
        }
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.reloadDnsTable();
  },
  created() {
    emitter.on('reloadDnsTable', () => {
      this.reloadDnsTable();
    });
  },
  unmounted() {
    emitter.off('reloadDnsTable');
  },
};
</script>
