import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createSlots as _createSlots, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b8508972"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "flex no-wrap items-center all-pointer-events"
}
const _hoisted_2 = { class: "hint" }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!

  return (_openBlock(), _createBlock(_component_q_input, {
    type: "number",
    outlined: "",
    "error-messages": _ctx.computedErrors,
    label: _ctx.control.uischema.label || _ctx.control.label,
    rules: [
      (val) => !!val || 'Field is required',
      (v) => new RegExp(_ctx.control?.schema?.pattern).test(v) || 'No valid value',
      (v) => new RegExp(_ctx.control.errors).test(v) || _ctx.control.errors
    ],
    "model-value": _ctx.control.data,
    class: "input-form",
    readonly: _ctx.control.uischema.readOnly,
    disable: _ctx.disable,
    dense: "",
    autofocus: "",
    "reactive-rules": "",
    "bg-color": "white",
    "onUpdate:modelValue": _ctx.onChange
  }, _createSlots({
    hint: _withCtx(() => [
      (_ctx.control.schema?.description?.length > 35)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_q_icon, {
              class: "q-mr-xs",
              size: "12px",
              name: "info"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_tooltip, {
                  class: "bg-grey-8",
                  anchor: "top left",
                  self: "bottom left",
                  offset: [0, 8]
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.control.schema.description), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.control.schema.description), 1)
          ]))
        : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.control.schema.description), 1))
    ]),
    _: 2
  }, [
    (_ctx.control.uischema?.options?.tooltip)
      ? {
          name: "append",
          fn: _withCtx(() => [
            _createVNode(_component_q_icon, {
              name: "help_outline",
              class: "cursor-pointer"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_tooltip, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.control.uischema?.options?.tooltip), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        }
      : undefined
  ]), 1032, ["error-messages", "label", "rules", "model-value", "readonly", "disable", "onUpdate:modelValue"]))
}