
import { ControlElement } from '@jsonforms/core';
import { defineComponent } from 'vue';
import { rendererProps, useJsonFormsControl, RendererProps } from '@jsonforms/vue';

const controlRenderer = defineComponent({
  name: 'string-control-renderer',
  components: {},
  props: {
    ...rendererProps<ControlElement>(),
    isNewEl: { type: Boolean, default: false },
  },
  data() {
    return {
      disable: false,
    };
  },
  mounted() {
    if (!this.control.uischema.options?.editable && this.control.data) {
      this.disable = true;
    }
    this.onChange(this.control.data);
  },
  methods: {
    onChange(value: any) {
      this.handleChange(this.control.path, value ? Number(value.trim(' ')) : undefined);
    },
  },
  computed: {
    computedErrors() {
      return this.control.errors;
    },
  },
  setup(props: RendererProps<ControlElement>) {
    return useJsonFormsControl(props);
  },
});

export default controlRenderer;
