
import { composePaths, createDefaultValue, ControlElement, JsonSchema, Resolve } from '@jsonforms/core';
import startCase from 'lodash/startCase';
import { defineComponent } from 'vue';
import { DispatchRenderer, rendererProps, useJsonFormsArrayControl, RendererProps } from '@jsonforms/vue';
import { useQuasarArrayControl } from '../util';

const controlRenderer = defineComponent({
  name: 'array-control-renderer',
  components: {
    DispatchRenderer,
  },
  props: {
    ...rendererProps<ControlElement>(),
  },
  setup(props: RendererProps<ControlElement>) {
    return useQuasarArrayControl(useJsonFormsArrayControl(props));
  },
  computed: {
    arraySchema(): JsonSchema | undefined {
      return Resolve.schema(this.control.rootSchema, this.control.uischema.scope, this.control.rootSchema);
    },
    dataLength(): number {
      return this.control.data ? this.control.data.length : 0;
    },
  },
  methods: {
    composePaths,
    createDefaultValue,
    addButtonClick() {
      this.addItem(this.control.path, createDefaultValue(this.control.schema))();
    },
    removeItemsClick(event: Event, toDelete: number[]): void {
      event.stopPropagation();
      this.removeItems?.(this.control.path, toDelete)();
    },
    getValidColumnProps(scopedSchema: JsonSchema) {
      if (scopedSchema.type === 'object' && typeof scopedSchema.properties === 'object') {
        return Object.keys(scopedSchema.properties).filter((prop) => scopedSchema.properties![prop].type !== 'array');
      }
      // primitives
      return [''];
    },
    title(prop: string) {
      return this.control.schema.properties?.[prop]?.title ?? startCase(prop);
    },
    resolveUiSchema(propName: string) {
      return this.control.schema.properties
        ? this.controlWithoutLabel(`#/properties/${propName}`)
        : this.controlWithoutLabel('#');
    },
    controlWithoutLabel(scope: string): ControlElement {
      return { type: 'Control', scope: scope, label: false };
    },
  },
});

export default controlRenderer;
