<template>
  <q-page class="main-wrapper-page single-page" style="background-color: var(--q-accent)">
    <section v-if="loadData" class="widget-section q-mb-lg">
      <EnvRegistration
        :registrationId="registrationId"
        :registrationData="registrationData"
        :envs="envs"
        :existEnvironments="environments"
        mode="edit"
      />
    </section>
    <section v-if="loadData" class="widget-section q-mb-lg">
      <q-banner v-if="!environments.length" rounded style="min-height: 40px" class="bg-info text-white q-mb-md">
        Please register environment for VNETs creation.
      </q-banner>
      <div class="flex items-center q-mb-md">
        <span class="label-block">VNETs:</span>
        <q-btn
          flat
          no-caps
          class="q-ml-auto btn--no-hover text-weight-regular"
          round
          color="light-blue-5"
          size="15px"
          :loading="vnetCreateLoading"
          :disabled="createVnetError?.length || vnetCreateLoading || uiConfig?.disableVnetCrearion"
          style="min-height: 30px"
          :ripple="false"
          @click="showVnetForm"
        >
          <q-tooltip v-if="createVnetError?.length">{{ createVnetError }}</q-tooltip>
          + Add VNET for {{ envTab }} environment
        </q-btn>
      </div>
      <div v-if="showCreateVnetForm">
        <div class="update-form">
          <q-banner v-if="updateGeneralErrors.length" class="text-white approval-error-banner q-mb-sm">
            <span class="block" v-for="(error, key) in updateGeneralErrors" :key="key">{{ error.message }}</span>
          </q-banner>
          <div class="flex full-width no-wrap">
            <JsonForms
              :renderers="renderers"
              :schema="createVnetSchema"
              :data="formData"
              :uischema="vnetUiSchema"
              :additionalErrors="updateErrors"
              validationMode="ValidateAndShow"
              @change="validateForm"
            />
            <q-btn
              color="primary"
              size="md"
              class="q-ml-sm"
              :loading="loadSend"
              :disable="
                loadSend ||
                submitDisable ||
                Boolean(formErrors.length) ||
                Boolean(updateErrors.length) ||
                Boolean(updateGeneralErrors.length)
              "
              style="height: 38px"
              @click="sendData()"
              no-caps
              label="Submit"
            ></q-btn>

            <q-btn flat size="md" class="btn--no-hover" style="height: 40px" no-caps @click="closeEditForm"
              >Cancel</q-btn
            >
          </div>
        </div>
      </div>

      <Vnets
        :environments="environments"
        :currentEnv="env"
        @changeEnv="changeEnv"
        :registrationData="registrationData"
        :registrationId="registrationId"
        mode="edit"
      />
    </section>

    <section v-if="loadData" class="widget-section q-mb-lg">
      <q-banner
        v-if="!environments.find((env) => env.value === 'PROD')"
        rounded
        style="min-height: 40px"
        class="bg-info text-white q-mb-md"
      >
        Please register <b>PROD</b> environment for external dns records creation.
      </q-banner>
      <div class="flex items-center">
        <span class="label-block">External DNS Records:</span>
        <q-btn
          flat
          no-caps
          class="q-ml-auto btn--no-hover text-weight-regular"
          round
          color="light-blue-5"
          size="15px"
          :disabled="createDnsError?.length"
          style="min-height: 30px"
          :ripple="false"
          @click="showCreateDnsForm"
        >
          + Add DNS record
          <q-tooltip v-if="createDnsError?.length">{{ createDnsError }}</q-tooltip>
        </q-btn>
      </div>
      <div v-if="showCreateForm">
        <div class="update-form">
          <q-banner v-if="updateGeneralErrors.length" class="text-white approval-error-banner q-mb-sm">
            <span class="block" v-for="(error, key) in updateGeneralErrors" :key="key">{{ error.message }}</span>
          </q-banner>
          <div class="flex full-width no-wrap">
            <JsonForms
              :renderers="renderers"
              :schema="createDnsSchema"
              :data="formData"
              :uischema="dnsUiSchema"
              :additionalErrors="updateErrors"
              validationMode="ValidateAndShow"
              @change="validateForm"
            />
            <q-btn
              color="primary"
              size="md"
              class="q-ml-sm"
              :loading="loadSend"
              :disable="
                loadSend ||
                submitDisable ||
                Boolean(formErrors.length) ||
                Boolean(updateErrors.length) ||
                Boolean(updateGeneralErrors.length)
              "
              style="height: 38px"
              @click="sendData()"
              no-caps
              label="Submit"
            ></q-btn>

            <q-btn flat size="md" class="btn--no-hover" style="height: 40px" no-caps @click="closeEditForm"
              >Cancel</q-btn
            >
          </div>
        </div>
      </div>
      <ExternalDns
        :registrationData="registrationData"
        :registrationId="registrationId"
        :expanded="expanded"
        mode="edit"
        @toggleExpanded="toggleExpanded"
        @confirmDeleteDnsRecord="confirmDeleteDnsRecord"
        @reassign="openReassignDialog"
      >
        <template #expand>
          <q-td colspan="100%" class="update-form-cell">
            <q-banner v-if="updateGeneralErrors.length" class="text-white approval-error-banner">
              <span class="block" v-for="(error, key) in updateGeneralErrors" :key="key">{{ error.message }}</span>
            </q-banner>
            <div class="flex full-width no-wrap update-form q-pb-sm q-pt-sm">
              <JsonForms
                :renderers="renderers"
                :schema="updateDnsSchema"
                :data="formData"
                :uischema="dnsUiSchema"
                :additionalErrors="updateErrors"
                validationMode="ValidateAndShow"
                @change="validateForm"
              />
              <q-btn
                color="primary"
                size="md"
                class="q-ml-sm"
                :loading="loadSend"
                :disable="
                  loadSend ||
                  submitDisable ||
                  Boolean(formErrors.length) ||
                  Boolean(updateErrors.length) ||
                  Boolean(updateGeneralErrors.length)
                "
                style="height: 38px"
                @click="sendData()"
                no-caps
                label="Submit"
              ></q-btn>

              <q-btn flat size="md" class="btn--no-hover" style="height: 40px" no-caps @click="closeEditForm"
                >Cancel</q-btn
              >
            </div>
          </q-td>
        </template>
      </ExternalDns>
    </section>

    <section v-if="loadData && uiConfig?.showFirewallRules" class="widget-section q-mb-lg">
      <q-banner
        v-if="!environments.find((env) => env.value === 'PROD')"
        rounded
        style="min-height: 40px"
        class="bg-info text-white q-mb-md"
      >
        Please register <b>PROD</b> environment for firewall rules creation.
      </q-banner>
      <div class="flex items-center q-mb-md">
        <span class="label-block">Firewall rules:</span>
        <q-btn
          flat
          no-caps
          class="q-ml-auto btn--no-hover text-weight-regular"
          round
          color="light-blue-5"
          size="15px"
          :disabled="createFirewallError?.length"
          style="min-height: 30px"
          :ripple="false"
          @click="showCreateFirewallRules"
        >
          + Add Firewall rule
          <q-tooltip v-if="createFirewallError?.length">{{ createFirewallError }}</q-tooltip>
        </q-btn>
      </div>
      <div v-if="showCreateFirewallRulesForm">
        <div class="update-form">
          <q-banner v-if="updateGeneralErrors.length" class="text-white approval-error-banner q-mb-sm">
            <span class="block" v-for="(error, key) in updateGeneralErrors" :key="key">{{ error.message }}</span>
          </q-banner>
          <div class="flex full-width no-wrap">
            <JsonForms
              :renderers="renderers"
              :schema="firewallRulesSchema"
              :data="formData"
              :uischema="firewallRulesUiSchema"
              :additionalErrors="updateErrors"
              validationMode="ValidateAndShow"
              @change="validateForm"
            />
            <q-btn
              color="primary"
              size="md"
              class="q-ml-sm"
              :loading="loadSend"
              :disable="
                loadSend ||
                submitDisable ||
                Boolean(formErrors.length) ||
                Boolean(updateErrors.length) ||
                Boolean(updateGeneralErrors.length)
              "
              style="height: 38px"
              @click="sendData()"
              no-caps
              label="Submit"
            ></q-btn>

            <q-btn flat size="md" class="btn--no-hover" style="height: 40px" no-caps @click="closeEditForm"
              >Cancel</q-btn
            >
          </div>
        </div>
      </div>
      <FirewallRules :registrationData="registrationData" firewallRulesType="source" />
      <FirewallRules :registrationData="registrationData" firewallRulesType="destination" />
    </section>
  </q-page>

  <q-dialog v-model="deleteDnsConfirm" class="single-service-page" persistent>
    <q-card style="min-width: 768px; border-radius: 0px">
      <q-toolbar class="shadow-2 q-py-sm q-px-md">
        <q-toolbar-title class="text-weight-bold">
          <strong>Confirm External DNS deletion</strong>
        </q-toolbar-title>
        <q-btn icon="close" round flat class="btn--no-hover" @click="deleteDnsConfirm = false"></q-btn>
      </q-toolbar>
      <q-card-actions class="q-pb-md">
        <q-btn
          color="primary"
          :loading="delelteDnsLoading"
          @click="deleteDnsRecord"
          size="md"
          class="q-ml-sm"
          no-caps
          label="Yes"
        ></q-btn>
        <q-btn flat size="md" class="btn--no-hover" style="height: 40px" no-caps @click="deleteDnsConfirm = false"
          >No</q-btn
        >
      </q-card-actions>
    </q-card>
  </q-dialog>
  <ReassignDialog
    :showReassignDialog="showReassignDialog"
    entity="external-dns"
    :newDigitalService="newDigitalService"
    :loading="loadReassign"
    :errors="reassignErrors"
    @submit="sendReassignRequest"
    @cancel="closeReassignDialog"
    @resetService="resetDigitalService"
    @setService="setDigitalService"
  />
</template>

<script>
import { JsonForms } from '@jsonforms/vue';
import emitter from 'tiny-emitter/instance';
import { quasarRenderers } from '@/shared/jsonformsQuasarRenderers/renderers';
import * as uiConfig from '@/shared/ui-config.json';
import { getRegistration, validateWorkFlow, createWorkFlow, getBluePrint, subValWorkflow } from '../../api/api';
import EnvRegistration from '../EnvRegistration.vue';
import FirewallRules from './components/FirewallRules.vue';
import ExternalDns from './components/ExternalDns.vue';
import Vnets from './components/Vnets.vue';
import ReassignDialog from '../ReassignDialog.vue';

const renderers = [...quasarRenderers];

export default {
  name: 'RegistrationNetwork',
  components: {
    ExternalDns,
    JsonForms,
    EnvRegistration,
    FirewallRules,
    Vnets,
    ReassignDialog,
  },
  props: {
    registrationId: {
      type: String,
    },
    registrationData: {
      type: Object,
    },
  },
  data() {
    return {
      uiConfig: {},
      envs: ['PROD', 'NONPROD', 'DEV'],
      renderers: Object.freeze(renderers),
      createDnsSchema: {},
      createVnetSchema: {},
      updateDnsSchema: {},
      showCreateVnetForm: false,
      vnetUiSchema: {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Name',
            scope: '#/properties/v_net_name',
          },
          {
            type: 'Control',
            label: 'Region',
            scope: '#/properties/region',
          },
          {
            type: 'Control',
            label: 'Resource group',
            scope: '#/properties/resource_group',
          },
          {
            type: 'Control',
            label: 'Size',
            scope: '#/properties/size',
          },
        ],
      },
      dnsUiSchema: {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Domain',
            scope: '#/properties/domain',
            options: {
              editable: false,
            },
          },
          {
            type: 'Control',
            label: 'Type',
            scope: '#/properties/type',
            options: {
              editable: false,
            },
          },
          {
            type: 'Control',
            label: 'Name',
            scope: '#/properties/name',
            options: {
              editable: false,
            },
          },
          {
            type: 'Control',
            label: 'Value',
            scope: '#/properties/value',
            options: {
              editable: true,
            },
            rule: {
              effect: 'ENABLE',
              condition: {
                scope: '#/properties/type',
                schema: {
                  enum: ['A', 'AAAA', 'CNAME', 'TXT', 'NS', 'MX', 'CNAME-WITH-REDIRECT', 'SRV'],
                },
              },
            },
          },
        ],
      },
      showCreateFirewallRulesForm: false,
      firewallRulesSchema: {},
      firewallRulesUiSchema: {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'Control',
            label: 'Source',
            scope: '#/properties/source',
          },
          {
            type: 'Control',
            label: 'Source APMID',
            scope: '#/properties/sourceApmId',
            options: {
              editable: false,
            },
          },
          {
            type: 'Control',
            label: 'Destination',
            scope: '#/properties/destination',
          },
          {
            type: 'Control',
            label: 'Destination APMID',
            scope: '#/properties/destinationApmId',
            options: {
              toUppercase: true,
            },
          },
          {
            type: 'Control',
            label: 'Port',
            scope: '#/properties/port',
          },
          {
            type: 'Control',
            label: 'Protocol',
            scope: '#/properties/protocol',
          },
          {
            type: 'Control',
            label: 'Details',
            scope: '#/properties/details',
          },
        ],
      },
      expanded: [],
      envTab: undefined,
      vnetCreateLoading: false,
      environments: [],
      delelteDnsLoading: false,
      updatedDnsRecord: {},
      updateErrors: [],
      formErrors: [],
      updateGeneralErrors: [],
      formData: {
        registration_id: this.registrationId,
        digitalServiceApmId: this.registrationData.digitalServiceApm,
      },
      loadSend: false,
      submitDisable: true,
      deleteDnsConfirm: false,
      showCreateForm: false,
      validationTimeout: false,
      workFlowAction: undefined,
      currentDnsRecord: undefined,
      createDnsError: undefined,
      createVnetError: 'No onborded environments',
      createFirewallError: undefined,
      loadData: false,
      showReassignDialog: false,
      newDigitalService: undefined,
      loadReassign: false,
      reassignErrors: undefined,
    };
  },
  methods: {
    async sendReassignRequest() {
      this.loadReassign = true;
      const data = {
        workflowId: 'engprt.move-network-external-dns',
        inputVariables: {
          newApmId: this.newDigitalService.apmId,
          id: this.currentDnsRecord.id,
        },
      };
      createWorkFlow(data)
        .then(() => {
          this.$notify(
            'positive',
            'Success',
            `DNS record will be reassign to ${this.newDigitalService.digitalServiceName}.`,
          );
          emitter.emit('reloadDnsTable');
        })
        .catch((error) => {
          this.$notify('negative', 'Error', error.message);
        })
        .finally(() => {
          this.closeReassignDialog();
        });
    },
    resetDigitalService() {
      this.newDigitalService = undefined;
      this.reassignErrors = undefined;
    },
    async setDigitalService(service) {
      this.reassignErrors = undefined;
      this.loadReassign = true;
      this.newDigitalService = service;
      const data = {
        workflowId: 'engprt.move-network-external-dns',
        inputVariables: {
          newApmId: this.newDigitalService.apmId,
          id: this.currentDnsRecord.id,
        },
      };
      validateWorkFlow(data)
        .then(() => {
          this.reassignErrors = undefined;
        })
        .catch((error) => {
          this.reassignErrors = error.message;
        })
        .finally(() => {
          this.loadReassign = false;
        });
    },
    closeReassignDialog() {
      this.newDigitalService = undefined;
      this.currentDnsRecord = undefined;
      this.showReassignDialog = false;
      this.reassignErrors = undefined;
    },
    openReassignDialog(dns) {
      this.currentDnsRecord = dns;
      this.showReassignDialog = true;
    },
    showVnetForm() {
      this.closeEditForm();
      this.showCreateVnetForm = true;
      this.formData.environment = this.envTab;
      this.workFlowAction = 'engprt.create-network-vnet';
    },
    showCreateFirewallRules() {
      this.closeEditForm();
      this.formData.sourceApmId = this.registrationData.digitalServiceApm;
      this.showCreateFirewallRulesForm = true;
      this.workFlowAction = 'engprt.update-firewall-rules';
    },
    showCreateDnsForm() {
      this.closeEditForm();
      this.expanded = [];
      this.showCreateForm = true;
      this.workFlowAction = 'engprt.create-network-external-dns';
    },
    confirmDeleteDnsRecord(dns) {
      this.currentDnsRecord = dns;
      this.deleteDnsConfirm = true;
    },
    async deleteDnsRecord() {
      this.delelteDnsLoading = true;
      const deleteDnsWorkflow = {
        workflowId: 'engprt.delete-network-external-dns',
        inputVariables: {
          registration_id: this.registrationId,
          digitalServiceApmId: this.registrationData.digitalServiceApm,
          id: this.currentDnsRecord.id,
        },
      };
      try {
        await createWorkFlow(deleteDnsWorkflow);
        this.$notify('positive', 'Success', 'DNS record will be deleted.');
        emitter.emit('reloadDnsTable');
        emitter.emit('reloadEnvironments');
      } catch (error) {
        this.$notify('negative', 'Error', error.message);
      } finally {
        this.delelteDnsLoading = false;
      }
    },
    closeEditForm() {
      if (this.validationTimeout) clearTimeout(this.validationTimeout);
      this.showCreateForm = false;
      this.showCreateVnetForm = false;
      this.showCreateFirewallRulesForm = false;
      this.formData = {
        registration_id: this.registrationId,
        digitalServiceApmId: this.registrationData.digitalServiceApm,
      };
      this.expanded = [];
      this.formErrors = [];
      this.updateErrors = [];
      this.updateGeneralErrors = [];
    },
    async sendData() {
      try {
        const workFlowData = {
          workflowId: this.workFlowAction,
          inputVariables: this.formData,
        };
        this.loadSend = true;
        await createWorkFlow(workFlowData);
        if (
          this.workFlowAction === 'engprt.create-network-external-dns' ||
          this.workFlowAction === 'engprt.update-network-external-dns'
        ) {
          this.$notify(
            'positive',
            'Success',
            `DNS record will be ${
              this.workFlowAction === 'engprt.create-network-external-dns' ? 'created' : 'updated'
            }.`,
          );
          emitter.emit('reloadDnsTable');
          emitter.emit('reloadEnvironments');
        }
        if (this.workFlowAction === 'engprt.create-network-vnet') {
          this.$notify('positive', 'Success', 'VNET will be created.');
          emitter.emit('reloadVnetsTable');
          emitter.emit('reloadEnvironments');
        }
        if (this.workFlowAction === 'engprt.update-firewall-rules') {
          this.$notify('positive', 'Success', 'Firewall rule will be created.');
          emitter.emit('reloadFirewallRules');
          emitter.emit('reloadEnvironments');
        }
        this.closeEditForm();
      } catch (error) {
        this.$notify('negative', 'Error', error.message);
      } finally {
        this.loadSend = false;
      }
    },
    validateForm(value) {
      this.formErrors = value.errors;
      if (JSON.stringify(value.data) !== JSON.stringify(this.formData) && !value.errors.length) {
        this.formErrors = value.errors;
        this.formData = value.data;
        this.loadSend = true;
        if (this.validationTimeout) clearTimeout(this.validationTimeout);
        this.validationTimeout = setTimeout(async () => {
          try {
            const workFlowData = {
              workflowId: this.workFlowAction,
              inputVariables: this.formData,
            };
            await validateWorkFlow(workFlowData);
            this.updateErrors = [];
            this.updateGeneralErrors = [];
            this.submitDisable = false;
          } catch (error) {
            if (error?.response?.data?.errors?.length) {
              this.updateErrors = error.response.data.errors.filter((error) => error.instancePath.length);
              this.updateGeneralErrors =
                error.response.data.errors.filter((error) => !error.instancePath.length && error?.params.toDisplay) ||
                [];
            } else {
              this.$notify('negative', 'Error', error.message);
              this.updateErrors = [];
              this.updateGeneralErrors = [];
              clearTimeout(this.validationTimeout);
            }
          } finally {
            this.loadSend = false;
          }
        }, 3000);
      }
    },
    toggleExpanded(row) {
      this.formErrors = [];
      this.updateErrors = [];
      this.updateGeneralErrors = [];
      this.showCreateForm = false;
      this.workFlowAction = 'engprt.update-network-external-dns';
      const { id, name, type, domain, value } = row;
      Object.assign(this.formData, { id, name, type, domain, value });
      this.expanded = this.expanded[0] === row.id ? [] : [row.id];
    },
    async changeEnv(env) {
      this.envTab = env;
      this.showCreateVnetForm = false;
      await this.checkVnetCreatePermissions(this.envTab);
    },
    async checkVnetCreatePermissions(env) {
      this.vnetCreateLoading = true;
      try {
        const subValCreateVnet = {
          workflowId: 'engprt.create-network-vnet',
          inputVariables: {
            registration_id: this.registrationId,
            digitalServiceApmId: this.registrationData.digitalServiceApm,
            environment: env,
          },
          subSchemaId: 'add-button',
        };
        const subValPromise = subValWorkflow(subValCreateVnet)
          .then(() => {
            this.createVnetError = undefined;
          })
          .catch((error) => {
            this.createVnetError = error.message;
          });
        await Promise.all([subValPromise]);
      } finally {
        this.vnetCreateLoading = false;
      }
    },
  },
  async mounted() {
    this.uiConfig = uiConfig[process.env.VUE_APP_ENV];
    const { inputVariablesSchema: updateSchema } = await getBluePrint('engprt.update-network-external-dns');
    delete updateSchema.$async;
    delete updateSchema.$schema;
    this.updateDnsSchema = updateSchema;
    const { inputVariablesSchema: vnetSchema } = await getBluePrint('engprt.create-network-vnet');
    delete vnetSchema.$async;
    delete vnetSchema.$schema;
    this.createVnetSchema = vnetSchema;
    const { inputVariablesSchema: createSchema } = await getBluePrint('engprt.create-network-external-dns');
    delete createSchema.$async;
    delete createSchema.$schema;
    this.createDnsSchema = createSchema;
    const { inputVariablesSchema: firewallRulesSchema } = await getBluePrint('engprt.update-firewall-rules');
    delete firewallRulesSchema.$async;
    delete firewallRulesSchema.$schema;
    this.firewallRulesSchema = firewallRulesSchema;
    this.loadData = false;
    this.$showLoading();
    const dataPromise = getRegistration(this.registrationId, true, false).then(async (response) => {
      this.environments = response.environments.map((env) => ({ status: env.status, value: env.value, id: env.id }));
    });
    const subValData = {
      inputVariables: {
        registration_id: this.registrationId,
        digitalServiceApmId: this.registrationData.digitalServiceApm,
      },
      subSchemaId: 'add-button',
    };
    const subvalCreateDns = {
      workflowId: 'engprt.create-network-external-dns',
      ...subValData,
    };
    const subValCreateFirewallRule = {
      workflowId: 'engprt.update-firewall-rules',
      ...subValData,
    };
    const subvalDnsPromise = subValWorkflow(subvalCreateDns)
      .then(() => {
        this.createDnsError = undefined;
      })
      .catch((error) => {
        this.createDnsError = error.message;
      });
    const subvalFirewallRulePromise = subValWorkflow(subValCreateFirewallRule)
      .then(() => {
        this.createFirewallError = undefined;
      })
      .catch((error) => {
        this.createFirewallError = error.message;
      });
    try {
      await Promise.all([subvalDnsPromise, subvalFirewallRulePromise, dataPromise]);
    } finally {
      this.$hideLoading();
      this.loadData = true;
    }
  },
};
</script>

<style lang="scss">
.external-dns-table {
  .q-table__top {
    padding: 0 4px 0;
    background-color: #edeff5;
  }
  thead tr:first-child {
    height: 30px;

    th {
      height: 20px;
      text-transform: uppercase;
      background-color: #edeff5;
      color: #a29a9a;
      font-size: 10px;
    }
  }
}
.update-form-cell {
  .q-banner {
    min-height: 38px;
    margin-bottom: 5px;
  }
}
.update-form {
  background-color: var(--q-accent);
  padding: 18px 6px 10px;
  margin-bottom: 15px;
  border-top: 1px solid $grey-4;
  border-bottom: 1px solid $grey-4;
  .q-field__bottom {
    padding: 3px 0 0 3px;
    white-space: pre;
  }
}
</style>
<style lang="scss" scoped>
.env-form {
  width: 50%;
}
.widget-section {
  border-radius: 15px;
  background-color: white;
  padding: 30px;
}
.label-block {
  font-size: 20px;
  font-weight: bold;
}

.environments {
  ul {
    display: flex;
    margin: 0 -10px;
    padding: 0;
  }

  li {
    margin: 0 10px;

    i {
      margin-right: 5px;
    }

    display: flex;
    align-items: center;
  }
}

.widget-section {
  border-radius: 15px;
  background-color: white;
  padding: 30px;
}
</style>
