import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-956f0800"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row q-px-sm" }
const _hoisted_2 = { class: "label-block" }
const _hoisted_3 = { class: "row q-mb-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_checkbox = _resolveComponent("q-checkbox")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.control.uischema.label || _ctx.control.label), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.control.options, (o, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "col",
          key: o.value
        }, [
          _createVNode(_component_q_checkbox, {
            label: o.label,
            "model-value": _ctx.dataHasEnum(o.value),
            id: _ctx.control.id + `-input-${index}`,
            path: _ctx.composePaths(_ctx.control.path, `${index}`),
            "error-messages": _ctx.control.errors,
            color: "primary",
            disable: _ctx.isDisabled(o.value),
            "indeterminate-value": _ctx.control.data === undefined,
            "onUpdate:modelValue": (value) => _ctx.toggle(o.value, value)
          }, null, 8, ["label", "model-value", "id", "path", "error-messages", "disable", "indeterminate-value", "onUpdate:modelValue"])
        ]))
      }), 128))
    ])
  ], 64))
}