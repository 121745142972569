<template>
  <div class="status" :class="{ 'table-view': tableView }">
    <q-icon
      v-if="workflow?.cancelling"
      name="r_fast_rewind"
      class="q-mr-xs cursor-pointer"
      size="22px"
      :class="workflow.status ? workflow.status.toLowerCase() : ''"
      :color="iconColor"
    >
      <q-tooltip anchor="top right"
        >Workflow was cancelled,
        {{ workflow.status === 'completed' ? 'changes reverted' : 'reverting changes' }}</q-tooltip
      >
    </q-icon>
    <span v-else :class="workflow.status ? workflow.status.toLowerCase() : ''"></span>
    <p class="q-mb-none cursor-pointer">
      {{ workflow.status || '' }}
      <q-tooltip v-if="showTooltip && statusDescription[workflow.status]" style="z-index: 999999">{{
        statusDescription[workflow.status]
      }}</q-tooltip>
    </p>
  </div>
</template>

<script>
export default {
  name: 'WorkflowStatus',
  props: {
    showTooltip: {
      type: Boolean,
      default: true,
    },
    workflow: {
      type: Object,
    },
    tableView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      statusDescription: {
        'not started': 'Process instance failed to create. Please contact Engineering Portal support.',
        inprogress: 'Workflow is running.',
        pending: 'Workflow has active subscriptions. Refer to log for details.',
        completed: 'Workflow is finished.',
        error: 'Workflow has unresolved errors. Refer to log for details.',
        incident: 'Process instance has unresolved incidents. Please contact Engineering Portal support',
        terminated: 'Process instance was terminated by Engineering Portal administrator',
        abandoned: 'Process instance failed to start and workflow was abandoned. No changes were applied.',
      },
    };
  },
  computed: {
    iconColor() {
      switch (this.workflow.status) {
        case 'completed':
          return 'green';
        case 'inprogress':
          return 'orange';
        case 'pending':
          return 'yellow-7';
        case 'terminated':
          return 'grey-6';
        case 'abandoned':
          return 'grey-6';
        default:
          return 'red';
      }
    },
  },
};
</script>

<style scoped lang="scss">
.status {
  display: flex;
  align-items: center;
  padding-top: 3px;

  &.table-view {
    padding-top: 0;
    span {
      margin-right: 14px;
    }
  }

  p {
    text-transform: capitalize;
    line-height: 1;
  }

  span {
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 4px;
    background-color: $red;

    &.cancelled,
    &.terminated,
    &.abandoned {
      background-color: $grey-6;
    }

    &.inprogress {
      background-color: $orange;
    }

    &.pending {
      background-color: $yellow-7;
    }

    &.completed,
    &.resolved {
      background-color: $green;
    }
  }
}
</style>
