import dayjs from 'dayjs';

export const NOTIFICATIONS_GETTERS = {
  ALL: 'notifications',
};

export const NOTIFICATIONS_ACTIONS = {
  ADD_NOTIFICATION: 'addNotification',
  REMOVE_NOTIFICATION: 'removeNotification',
  REMOVE_ALL_NOTIFICATIONS: 'removeAllNotification',
};

const NOTIFICATIONS_MUTATIONS = {
  ADD_NOTIFICATION: 'addNotification',
  REMOVE_NOTIFICATION: 'removeNotification',
  REMOVE_ALL_NOTIFICATIONS: 'removeAllNotification',
};

export default {
  namespaced: true,
  state: {
    notifications: [],
  },
  getters: {
    [NOTIFICATIONS_GETTERS.ALL]: (state: any) => {
      state.notifications.slice().reverse();
    },
  },
  mutations: {
    [NOTIFICATIONS_MUTATIONS.ADD_NOTIFICATION]: (state: any, notification: any) => {
      const notificationData = {
        ...notification,
        time: notification.time
          ? dayjs(new Date(Number(notification.time))).format('DD.MM.YYYY HH:mm')
          : dayjs(new Date()).format('DD.MM.YYYY HH:mm'),
        icon:
          notification.type === 'negative'
            ? 'sym_o_close'
            : notification.type === 'positive'
            ? 'sym_o_check'
            : 'sym_o_exclamation',
      };
      state.notifications.unshift(notificationData);
    },
    [NOTIFICATIONS_MUTATIONS.REMOVE_NOTIFICATION]: (state: any, notification: object) =>
      state.notifications.splice(state.notifications.indexOf(notification), 1),
    [NOTIFICATIONS_MUTATIONS.REMOVE_ALL_NOTIFICATIONS]: (state: any) => {
      state.notifications = [];
    },
  },
  actions: {
    [NOTIFICATIONS_ACTIONS.ADD_NOTIFICATION]: (context: any, notification: object) => {
      context.commit(NOTIFICATIONS_MUTATIONS.ADD_NOTIFICATION, notification);
    },
    [NOTIFICATIONS_ACTIONS.REMOVE_NOTIFICATION]: (context: any, notification: object) => {
      context.commit(NOTIFICATIONS_MUTATIONS.REMOVE_NOTIFICATION, notification);
    },
    [NOTIFICATIONS_ACTIONS.REMOVE_ALL_NOTIFICATIONS]: (context: any) => {
      context.commit(NOTIFICATIONS_MUTATIONS.REMOVE_ALL_NOTIFICATIONS);
    },
  },
};
