<template>
  <q-btn
    flat
    round
    size="12px"
    icon="sym_r_work_history"
    style="width: 30px; height: 40px"
    class="btn--no-hover"
    @click="$emit('showWorkflows')"
  >
    <q-tooltip>Show related workflows</q-tooltip>
  </q-btn>
  <q-btn
    v-if="mode === 'edit'"
    flat
    round
    size="12px"
    icon="sym_r_edit"
    style="width: 30px; height: 40px"
    class="btn--no-hover"
    :loading="loading"
    :disabled="loading || disableEditDnsIds.find((item) => item.id === record?.id)"
    @click="$emit('edit')"
  >
    <q-tooltip v-if="!loading" max-width="480px" style="white-space: pre-line">{{
      !!disableEditDnsIds.find((item) => item.id === record?.id)
        ? disableEditDnsIds.find((item) => item.id === record?.id)?.message
        : 'Edit'
    }}</q-tooltip>
  </q-btn>
  <q-btn
    v-if="mode === 'edit'"
    flat
    round
    size="13px"
    icon="sym_o_compare_arrows"
    style="width: 30px; height: 40px"
    class="btn--no-hover"
    :loading="loading"
    :disabled="loading || disableReassignDnsIds.find((item) => item.id === record?.id)"
    @click="$emit('reassign')"
  >
    <q-tooltip v-if="!loading" max-width="480px" style="white-space: pre-line">{{
      !!disableReassignDnsIds.find((item) => item.id === record?.id)
        ? disableReassignDnsIds.find((item) => item.id === record?.id)?.message
        : 'Reassign record'
    }}</q-tooltip>
  </q-btn>
  <q-btn
    v-if="mode === 'edit'"
    flat
    round
    size="12px"
    icon="sym_r_delete"
    style="width: 30px; height: 40px"
    class="btn--no-hover"
    :loading="loading"
    :disabled="loading || disableDeleteDnsIds.find((item) => item.id === record?.id)"
    @click="$emit('remove')"
  >
    <q-tooltip v-if="!loading" max-width="480px" style="white-space: pre-line">{{
      !!disableDeleteDnsIds.find((item) => item.id === record?.id)
        ? disableDeleteDnsIds.find((item) => item.id === record?.id)?.message
        : 'Delete'
    }}</q-tooltip>
  </q-btn>
</template>

<script>
export default {
  name: 'ActionsCell',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    record: {
      type: Object,
    },
    disableEditDnsIds: {
      type: Array,
    },
    disableDeleteDnsIds: {
      type: Array,
    },
    disableReassignDnsIds: {
      type: Array,
    },
    mode: {
      type: String,
    },
  },
};
</script>
