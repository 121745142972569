<template>
  <component v-if="componentLayout" :is="componentLayout" :class="{ showBanner: currentBanner && showBanners }">
    <q-page-container>
      <router-view></router-view>
    </q-page-container>
  </component>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import { useQuasar } from 'quasar';
import { mapActions, mapGetters } from 'vuex';
import appInsights from '@/library/applicationInsights';
import { BANNERS_GETTERS } from '@/store/modules/banners';
import apollo from '@/library/http/apollo';
import gql from 'graphql-tag';
import { NOTIFICATIONS_ACTIONS } from '@/store/modules/notifications';
import axios from 'axios';
import { APP_ACTIONS, APP_GETTERS } from '@/store/modules/app';
import { SERVICE_STATUS_ACTIONS } from '@/store/modules/service-status';

export default {
  name: 'AppComponent',
  watch: {
    layout: {
      handler() {
        this.componentLayout = defineAsyncComponent(() => import(`@/layouts/${this.layout}.vue`));
      },
      immediate: true,
    },
  },
  computed: {
    layout() {
      return this.$route.meta.layout || 'DefaultLayout';
    },
    ...mapGetters('banners', {
      currentBanner: BANNERS_GETTERS.CURRENT,
      showBanners: BANNERS_GETTERS.BANNERS_SHOW,
    }),
    ...mapGetters('app', {
      userInfo: APP_GETTERS.USER_INFO,
    }),
  },
  data() {
    return {
      componentLayout: undefined,
      eventSource: null,
      subscribeForNotifications:{
        retryCount: 0,
        maxRetries: 5,
      }
    };
  },
  methods: {
    ...mapActions('app', {
      getUserInfo: APP_ACTIONS.GET_USER_INFO,
    }),
    ...mapActions('serviceStatus', {
      getServiceStatus: SERVICE_STATUS_ACTIONS.GET_SERVICE_STATUS,
    }),
    subscribeForUserNotifications() {
      const { VUE_APP_API_HOST } = process.env;
      const subscribeForNotificationsUrl = `${VUE_APP_API_HOST}/notifications/subscribe`;
      if (!this.userInfo.email) {
        throw new Error('No email found');
      }

      const connect = () => {
      this.eventSource = new EventSource(`${subscribeForNotificationsUrl}?userEmail=${this.userInfo.email}`);

      this.eventSource.onmessage = (event) => {
        const eventData = JSON.parse(event.data);

        // Other event type is 'PING' to keep connection open
        if (eventData.type === 'NOTIFICATION') {
          const { type, title: caption, description: message } = eventData.payload;
          this.$notify(type, caption, message);
        }
      };

      this.eventSource.onerror = (error) => {
        console.error('EventSource failed:', error);
        // Close the connection on error
        this.eventSource.close();

        // If retry count has reached max, stop retrying
        if (this.subscribeForNotifications.retryCount >= this.subscribeForNotifications.maxRetries) {
          console.error('Max retry attempts reached. Giving up.');
          return;
        }

        // Increase retry delay using exponential backoff
        this.subscribeForNotifications.retryCount++;
        const nextRetryDelay = Math.pow(2, this.subscribeForNotifications.retryCount) * 1000; // Exponential backoff (1, 2, 4, 8 seconds...)

        console.log(`SSE error. Reconnecting in ${nextRetryDelay / 1000} seconds...`);
        setTimeout(connect, nextRetryDelay); // Retry connection with backoff
      };
    };
    connect();
    },
  },
  setup() {
    const $q = useQuasar();
    $q.iconMapFn = (iconName) => {
      if (iconName.startsWith('engp:') === true) {
        const name = iconName.substring(5);
        return {
          cls: 'icomoon ' + name,
        };
      }
    };
    appInsights.loadAppInsights();
    appInsights.trackPageView();
  },
  async mounted() {
    await this.getUserInfo();
    await apollo.apolloClient
      .query({
        query: gql`
          query {
            getUserNotifications {
              id
              userNotificationId
              title
              description
              is_active
              created_at
              type: notification_type
            }
          }
        `,
      })
      .then((response) => {
        const { getUserNotifications: userNotifications } = response.data;

        for (const userNotification of userNotifications) {
          const { userNotificationId, type, title: caption, description: message, created_at: time } = userNotification;

          this.$store.dispatch(`notifications/${NOTIFICATIONS_ACTIONS.ADD_NOTIFICATION}`, {
            userNotificationId,
            type,
            caption,
            message,
            time,
          });
        }
      });
    await this.getServiceStatus();
    this.subscribeForUserNotifications();
  },

  beforeUnmount() {
    this.eventSource.close();
  },
};
</script>
