<template>
  <div class="flex items-start">
    <div class="flex items-center event-name q-mr-lg">
      <q-icon :name="`engp:engp-ico-${actionData.type}`" size="16px" class="q-mr-xs"></q-icon>
      <b>{{ actionData.type }}</b>
    </div>
    <Status :workflow="actionData" :showTooltip="false" />
    <q-btn
      v-if="!actionData.variablesSchema && actionData.type === 'action' && actionData.status === 'pending'"
      color="primary"
      class="action-btn q-ml-lg"
      text-color="dark"
      @click="submit"
      :label="actionData.message || 'Act'"
      no-caps
      :loading="loading"
      :disable="submitDisable || formErrors.length || generalErrors.length || formError?.length"
      ><q-tooltip max-width="480px" style="white-space: pre-line" v-if="formError?.length">{{
        formError
      }}</q-tooltip></q-btn
    >
    <div class="event-message q-ml-lg">
      <div
        v-if="actionData.status !== 'pending' || actionData.type === 'notification'"
        class="service-description-markdown full-width"
        v-html="dataMarkdown(actionData.message)"
      ></div>
      <div v-if="actionData.variables && Object.keys(actionData?.variables)?.length">
        <ul>
          <li v-for="key in Object.keys(actionData?.variables)" :key="key">
            <b>{{ key }}:</b> {{ JSON.stringify(actionData?.variables[key]).replace(/"/g, '') }}
          </li>
        </ul>
      </div>
    </div>

    <div class="flex items-center q-ml-auto">
      <span v-if="actionData.status === 'resolved'" class="date flex items-center">
        <q-icon name="engp:engp-ico-updated" size="15px" class="q-mr-xs"></q-icon>
        {{ $options.dayjs(actionData.updatedAt).format('DD.MM.YYYY HH:mm') }}
      </span>
      <q-icon
        name="engp:engp-ico-copy"
        @click.stop="copyUuid(actionData.uuid)"
        size="16px"
        class="q-mx-md cursor-pointer"
      >
        <q-tooltip>Copy UUID</q-tooltip>
      </q-icon>
    </div>
    <div
      class="full-width q-mt-md"
      v-if="actionData.variablesSchema && actionData.type === 'action' && actionData.status === 'pending'"
    >
      <q-banner v-if="generalErrors.length" class="text-white approval-error-banner">
        <span class="block" v-for="(error, key) in generalErrors" :key="key">{{ error.message }}</span>
      </q-banner>
      <JsonForms
        :schema="actionData.variablesSchema"
        :uiSchema="uiSchema"
        :data="formData"
        :additionalErrors="formErrors"
        @change="onChange"
        :renderers="renderers"
      />
      <q-btn
        color="primary"
        text-color="dark"
        class="action-btn q-mt-lg"
        @click="submit"
        :label="actionData.message || 'Act'"
        :loading="loading"
        no-caps
        :disable="submitDisable || formErrors.length || generalErrors.length || formError?.length"
      >
        <q-tooltip max-width="480px" style="white-space: pre-line" v-if="formError?.length">{{ formError }}</q-tooltip>
      </q-btn>
    </div>
  </div>
</template>

<script>
import { marked } from 'marked';
import { JsonForms } from '@jsonforms/vue';
import { copyToClipboard } from 'quasar';
import dayjs from 'dayjs';
import { quasarRenderers } from '@/shared/jsonformsQuasarRenderers/renderers';
import { preResolveAction, resolveAction } from '../api';
import Status from './Status.vue';

const renderer = new marked.Renderer();

renderer.link = function (href, title, text) {
  return `<a href="${href}" target="_blank" rel="noopener noreferrer">${text}</a>`;
};

marked.setOptions({ renderer });

const renderers = [...quasarRenderers];

export default {
  name: 'ActionItem',
  dayjs,
  copyToClipboard,
  props: {
    actionData: {
      type: Object,
    },
  },
  emits: ['resolve'],
  components: {
    JsonForms,
    Status,
  },
  computed: {
    formErrors() {
      return this.errors.length ? this.errors : [];
    },
  },
  methods: {
    copyUuid(uuid) {
      copyToClipboard(uuid).then(() => {
        this.$notify('info', 'Info', 'Action UUID was copied');
      });
    },
    dataMarkdown(data) {
      return marked(data);
    },
    async onChange(data) {
      if (JSON.stringify(data.data) !== JSON.stringify(this.formData)) {
        this.formData = data.data;
        this.submitDisable = true;
        await this.validateForm();
      }
    },
    async validateForm() {
      if (this.validationTimeout) clearTimeout(this.validationTimeout);
      this.validationTimeout = setTimeout(async () => {
        const data = {
          subscriptionUuid: this.actionData.uuid,
          variables: this.formData,
        };
        try {
          await preResolveAction(data);
          this.submitDisable = false;
          this.errors = [];
          this.generalErrors = [];
        } catch (error) {
          this.submitDisable = true;
          if (error?.response?.data?.errors?.length) {
            this.errors = error.response.data.errors.filter((error) => error.instancePath.length);
            this.generalErrors = error.response.data.errors.filter((error) => !error.instancePath.length) || [];
          } else {
            this.errors = [];
            this.generalErrors = [];
          }
        }
      }, 1500);
    },
    async submit() {
      this.loading = true;
      try {
        const data = {
          subscriptionUuid: this.actionData.uuid,
          variables: this.formData,
        };
        await resolveAction(data);
        this.$notify('positive', 'Success', 'Subscription was resolved successfull.');
        this.$emit('resolve');
      } catch (error) {
        this.$notify('negative', 'Error', error.message);
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    const authData = {
      subscriptionUuid: this.actionData.uuid,
      variables: this.actionData?.variables || null,
    };
    preResolveAction(authData)
      .then(() => {
        this.formError = undefined;
      })
      .catch((error) => {
        this.formError = error.message;
      });
    if (!this.actionData.variablesSchema) {
      await this.validateForm();
    }
  },
  data() {
    return {
      formError: undefined,
      errors: [],
      generalErrors: [],
      validationTimeout: undefined,
      renderers: Object.freeze(renderers),
      formData: {},
      loading: false,
      submitDisable: true,
      uiSchema: {
        type: 'HorizontalLayout',
        elements: [
          {
            type: 'control',
            scope: '#',
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped>
ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.event-message {
  max-width: 45%;
}
.q-banner {
  min-height: 38px;
  margin-bottom: 5px;
}
.q-field__bottom {
  padding: 2px 0 0 2px;
}
.status {
  display: flex;
  text-transform: capitalize;
  align-items: center;

  span {
    display: block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 5px;
    background-color: $grey-6;

    &.resolved {
      background-color: $green;
    }

    &.pending {
      background-color: $orange;
    }

    &.cancelled {
      background-color: $grey-6;
    }
  }
}
.action-btn {
  border-radius: 10px;
}
</style>
