
import { Layout } from '@jsonforms/core';
import { defineComponent } from 'vue';
import { DispatchRenderer, rendererProps, useJsonFormsLayout, RendererProps } from '@jsonforms/vue';

const layoutRenderer = defineComponent({
  name: 'group-renderer',
  components: {
    DispatchRenderer,
  },
  props: {
    ...rendererProps<Layout>(),
    manifest: { type: Object, default: () => ({}) },
    freezeManifest: { type: Object, default: () => ({}) },
    currentEnv: { type: String, default: () => '' },
    networkPermissions: { type: Object },
    disableUpdateOpass: { type: Boolean, default: false },
    cloudentePermissions: { type: Object },
    approvalGroupState: { type: String },
    dense: { type: Boolean, default: false },
    showFilters: { type: Boolean, default: false },
    uiConfig: { type: Object, default: () => ({}) },
  },
  setup(props: RendererProps<Layout>) {
    return useJsonFormsLayout(props);
  },
});

export default layoutRenderer;
