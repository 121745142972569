<template>
  <component
    v-if="load"
    :is="registrationType"
    :registrationData="registrationData"
    :registrationId="$route.params.regId"
  ></component>
</template>

<script>
import { mapActions } from 'vuex';
import { getRegistration } from './api/api';
import { APP_ACTIONS } from '@/store/modules/app';
import Registration1Env from './components/Registration1Env.vue';
import Registration2Env from './components/Registration2Env.vue';
import Registration3Env from './components/Registration3Env.vue';
import RegistrationNetwork from './components/registration-network/RegistrationNetwork.vue';
import RegistrationCloudente from './components/registration-cloudente/RegistrationCloudente.vue';
import RegistrationDBaaS from './components/RegistrationDBaaS.vue';
import RegistrationMas from './components/RegistrationMAS.vue';

export default {
  name: 'RegistrationPage',
  components: {
    Registration1Env,
    Registration2Env,
    Registration3Env,
    RegistrationNetwork,
    RegistrationCloudente,
    RegistrationDBaaS,
    RegistrationMas,
  },
  data() {
    return {
      registration: undefined,
      registrationData: {},
      load: false,
    };
  },
  methods: {
    ...mapActions('app', {
      setPageTitle: APP_ACTIONS.SET_PAGE_TITLE,
    }),
  },
  computed: {
    registrationType() {
      switch (this.registrationData.kind) {
        case 'Registration1Env':
          return 'Registration1Env';
        case 'Registration2Env':
          return 'Registration2Env';
        case 'Registration3Env':
          return 'Registration3Env';
        case 'RegistrationNetwork':
          return 'RegistrationNetwork';
        case 'RegistrationCloudente':
          return 'RegistrationCloudente';
        case 'RegistrationDBaaS':
          return 'RegistrationDBaaS';
        case 'RegistrationMAS':
          return 'RegistrationMas';
        default:
          return 'Registration3Env';
      }
    },
  },
  async mounted() {
    try {
      const data = await getRegistration(this.$route.params.regId, false, false);
      this.registration = data;
      this.registrationData.digitalServiceApm = data.digitalService.apmId;
      this.registrationData.infrastructureServiceApm = data.infraService.apmId;
      this.registrationData.kind = data.infraService.kind;
      this.setPageTitle(
        `Registration ${this.registration.infraService.displayName || this.registration.infraService.serviceName} on ${
          this.registration.digitalService.digitalServiceName || this.registration.digitalService.apmId
        }`,
      );
    } catch (error) {
      if (error.response.status === 404) {
        this.$router.push(`/my-portfolio/${this.$route.params.dsId}`);
      }
    } finally {
      this.load = true;
    }
  },
};
</script>

<style lang="scss">
.widget-section {
  border-radius: 15px;
  background-color: white;
  padding: 30px;
}
</style>
