<template>
  <q-page class="main-wrapper-page single-page" style="background-color: var(--q-accent)">
    <div v-if="workflow" class="row no-wrap q-col-gutter-md">
      <div class="col col-xl-2 col-lg-3 col-md-4">
        <q-scroll-area
          style="width: 100%; padding-right: 10px"
          :style="`height: calc(100vh - ${currentBanner ? '200px' : '150px'})`"
          :thumb-style="{ borderRadius: '5px', background: '#9f9f9f', width: '3px', opacity: 1, right: 0 }"
        >
          <h3>Info</h3>
          <div class="info-holder">
            <ul>
              <li><b>Name:</b> {{ workflow.workflowId }}</li>
              <li>
                <b>UUID:</b>
                <span
                  >{{ workflow.uuid }}
                  <q-icon
                    name="engp:engp-ico-copy"
                    @click.stop="copyUuid(workflow.uuid)"
                    size="14px"
                    class="q-ml-xs cursor-pointer"
                  >
                    <q-tooltip>Copy UUID</q-tooltip>
                  </q-icon>
                </span>
              </li>
              <li><b>Actor:</b>{{ workflow?.actor }}</li>
              <li class="flex">
                <b>Status:</b>
                <Status :workflow="workflow" />
              </li>
              <li><b>Created at:</b> {{ $options.dayjs(workflow.createdAt).format('DD.MM.YYYY HH:mm') }}</li>
              <li v-if="workflow.deletedAt">
                <b>Deleted at:</b> {{ $options.dayjs(workflow.deletedAt).format('DD.MM.YYYY HH:mm') }}
              </li>
              <li>
                <b>Process Instance ID: </b>
                <span class="instance-key cursor-pointer" @click="goToSco(workflow.processInstanceKey)">
                  {{ workflow.processInstanceKey }}</span
                >
              </li>
            </ul>
          </div>
          <h3 v-if="Object.keys(workflow.inputVariables).length">Input Variables</h3>
          <div v-if="Object.keys(workflow.inputVariables).length" class="info-holder">
            <ul>
              <li v-for="key in Object.keys(workflow.inputVariables)" :key="key">
                <b>{{ key }}:</b> {{ JSON.stringify(workflow.inputVariables[key]).replace(/"/g, '') }}
              </li>
            </ul>
          </div>
          <!--TODO add relations-->
          <!--<h3 v-if="relations.length">Relations</h3>
          <div v-if="relations.length" class="info-holder">
            <ul>
              <li v-for="item in relations" :key="item.uuid">
                <b>{{ item.itemType }}:</b>
                {{ item.itemId }}
              </li>
            </ul>
          </div>-->
        </q-scroll-area>
      </div>
      <div class="col col-xl-10 col-lg-9 col-md-8">
        <q-scroll-area
          style="width: 100%; padding-right: 10px"
          :style="`height: calc(100vh - ${currentBanner ? '200px' : '150px'})`"
          :thumb-style="{ borderRadius: '5px', background: '#9f9f9f', width: '3px', opacity: 1, right: 0 }"
        >
          <div class="q-px-md">
            <div class="flex items-center justify-between">
              <h3>Workflow Events</h3>
              <q-btn
                flat
                no-caps
                class="q-ml-auto btn--no-hover text-weight-regular"
                size="15px"
                style="min-height: 30px; padding: 0"
                :ripple="false"
                @click="sortByAsc = !sortByAsc"
              >
                <span>Sort by date</span>
                <q-icon :name="sortByAsc ? 'arrow_drop_down' : 'arrow_drop_up'"></q-icon>
              </q-btn>
              <q-btn
                flat
                no-caps
                class="q-ml-xs btn--no-hover text-weight-regular"
                round
                :icon="!expandAll ? 'sym_o_unfold_more' : 'sym_o_unfold_less'"
                size="15px"
                style="min-height: 30px"
                :ripple="false"
                @click="expandCollapseAll"
              >
                <q-tooltip>{{ expandAll ? 'Collapse all' : 'Expand all' }}</q-tooltip>
              </q-btn>
            </div>

            <event
              v-for="event in sortedEvents"
              :key="event.uuid"
              class="q-mb-md"
              @reload="load"
              :eventData="event"
            ></event>
          </div>
        </q-scroll-area>
      </div>
    </div>
  </q-page>
</template>

<script>
import dayjs from 'dayjs';
import { copyToClipboard } from 'quasar';
import { mapGetters } from 'vuex';
import emitter from 'tiny-emitter/instance';
import { BANNERS_GETTERS } from '@/store/modules/banners';
import { getWorkflowById, getRelationsByWorkflow } from './api';
import Event from './components/Event.vue';
import Status from './components/Status.vue';

export default {
  name: 'WorkFlow',
  components: {
    Event,
    Status,
  },
  emits: ['reload'],
  copyToClipboard,
  dayjs,
  data() {
    return {
      sortByAsc: false,
      expandAll: false,
      workflow: undefined,
      events: [],
      relations: [],
    };
  },
  computed: {
    ...mapGetters('banners', {
      currentBanner: BANNERS_GETTERS.BANNERS_SHOW,
    }),
    sortedEvents() {
      return this.sortEvents(this.events, this.sortByAsc);
    },
  },
  methods: {
    sortEvents(events, sort) {
      return events
        .sort((a, b) => {
          const dateA = new Date(a.createdAt);
          const dateB = new Date(b.createdAt);

          if (sort) {
            return dateA - dateB;
          } else {
            return dateB - dateA;
          }
        })
        .map((event) => {
          if (event.events && Array.isArray(event.events)) {
            event.events = this.sortEvents(event.events, sort);
          }
          return event;
        });
    },
    expandCollapseAll() {
      this.expandAll = !this.expandAll;
      emitter.emit('expandCollapseAll', this.expandAll);
    },
    buildEventsTree(events) {
      const map = {};
      const roots = [];
      const mapStatus = {
        resolved: 3,
        pending: 2,
        cancelled: 1,
      };

      events.forEach((event) => {
        if (event.subscriptions.length) {
          event.subscriptions.sort((a, b) => mapStatus[b.status] - mapStatus[a.status]);
        }
        map[event.uuid] = { ...event, events: [], hasChildError: false };
      });

      events.forEach((event) => {
        if (event.eventUuid) {
          if (map[event.eventUuid]) {
            map[event.eventUuid].events.push(map[event.uuid]);
          }
        } else {
          roots.push(map[event.uuid]);
        }
      });

      const setHasChildError = (node) => {
        if (node.events.length > 0) {
          node.events.forEach((child) => setHasChildError(child));
          node.hasChildError = node.events.some((child) => child.isError || child.hasChildError);
        }
      };

      roots.forEach((root) => setHasChildError(root));

      return roots;
    },
    copyUuid(uuid) {
      copyToClipboard(uuid).then(() => {
        this.$notify('info', 'Info', 'Workflow UUID was copied');
      });
    },
    goToSco(id) {
      window.open(`${process.env.VUE_APP_OASIS_URL}/${id}`, '_blank');
    },
    async load() {
      this.$showLoading();
      try {
        this.workflow = await getWorkflowById(this.$route.params.id);
        this.events = this.buildEventsTree(this.workflow.events);
        this.relations = await getRelationsByWorkflow(this.$route.params.id);
      } catch (error) {
        this.$notify('negative', 'Error', error.message);
      } finally {
        this.$hideLoading();
      }
    },
  },
  async mounted() {
    await this.load();
  },
};
</script>

<style lang="scss" scoped>
h3 {
  margin: 0;
  font-size: 1.7em;
}
.info-holder {
  background-color: #e7e9f1;
  padding: 15px;
  border-radius: 15px;
  margin-bottom: 10px;
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
      display: flex;
      align-items: flex-start;
      padding: 10px 0;
      border-bottom: 1px solid #d4d8e8;

      &:last-child {
        border-bottom: none;
      }

      b {
        display: block;
        text-transform: capitalize;
        min-width: 135px;
        width: 135px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 5px;
      }
    }
  }
  .instance-key {
    color: $blue;
  }
}
</style>
