<template>
  <q-page
    padding
    class="main-wrapper-page single-page"
    :class="{ 'design-open': isServiceDesignOpen }"
    style="background-color: var(--q-accent)"
  >
    <div v-if="filterLoading" class="loading">
      <q-spinner-gears color="primary" size="6em" />
    </div>
    <PageView :pageView="pageView" @changeView="changePageView" />
    <CatalogFilter
      :filters="filters"
      :canReset="canReset"
      @filterByType="filterByType"
      @filterByAttribute="filterByAttribute"
      @resetFilters="resetFilters"
    />
    <transition-group
      v-if="catalogServices && catalogServices.length && pageView === 'grid'"
      appear
      enter-active-class="animated fadeIn"
    >
      <template v-for="(service, key) in catalogServices" :key="key">
        <div
          v-if="
            (service.infraServices &&
              service.infraServices.length &&
              service.infraServices.filter((infra) => infra.isCatalogPart).length) ||
            hasInfraServices(service)
          "
          class="service-holder q-mb-lg q-px-md q-py-md bg-white"
        >
          <div class="heading-service flex justify-between content-center">
            <q-toolbar-title :ref="service.categoryId" class="q-my-none text-weight-bold"
              >{{ service.name }}
              <sup v-if="service.description">
                <q-btn
                  push
                  flat
                  round
                  size="sm"
                  color="white"
                  text-color="text-color"
                  class="q-px-none"
                  icon="mdi-information-outline"
                >
                  <q-popup-proxy>
                    <q-banner class="service-description service-reasons" v-html="$options.marked(service.description)">
                    </q-banner>
                  </q-popup-proxy>
                </q-btn>
              </sup>
            </q-toolbar-title>
            <div class="btn-group">
              <q-btn
                @click="goToService(service, 'tree')"
                :disable="!hasWizard(service) || canReset"
                class="service-action-btn"
                icon="account_tree"
                outline
                round
                size="12px"
                v-ripple="{ color: 'primary' }"
              >
                <q-tooltip>Decision Tree</q-tooltip>
              </q-btn>
              <q-btn
                v-if="uiConfig.showPavedRoad"
                @click="goToService(service, 'wizard')"
                :disable="!hasWizard(service) || canReset"
                class="service-action-btn"
                icon="mdi-stairs"
                outline
                round
                size="12px"
                v-ripple="{ color: 'primary' }"
              >
                <q-tooltip>Step by step<br />assistant</q-tooltip>
              </q-btn>
              <q-btn
                v-if="isAuth && userInfo.applicationsInOwn"
                @click="goToNewService(service)"
                class="service-action-btn"
                icon="add"
                outline
                round
                size="12px"
                v-ripple="{ color: 'primary' }"
              >
                <q-tooltip>Add Infrastructure Service</q-tooltip>
              </q-btn>
            </div>
          </div>
          <ServiceCategory :service="service" :design="design" />
        </div>
      </template>
    </transition-group>
    <div v-if="catalogServices && catalogServices.length && pageView === 'list'">
      <div class="row q-col-gutter-sm q-mb-sm">
        <div class="col">
          <q-input
            dense
            outlined
            bg-color="white"
            v-model="tableFilters.displayName"
            clearable
            label="Display name"
          ></q-input>
        </div>
        <div class="col">
          <q-input dense outlined bg-color="white" v-model="tableFilters.apmid" clearable label="APMID"></q-input>
        </div>
        <div class="col">
          <q-select
            dense
            outlined
            bg-color="white"
            :options="categories"
            options-dense
            clearable
            v-model="tableFilters.categoryName"
            label="Category"
          ></q-select>
        </div>
      </div>
      <q-table
        :rows="filteredInfraServices"
        :columns="cols"
        flat
        class="digital-service-table infra-table"
        hide-bottom
        :pagination="{
          rowsPerPage: 0,
        }"
      >
        <template v-slot:body-cell-cloudType="props">
          <td>
            <span>{{ props.row.cloudType || 'n/a' }}</span>
          </td>
        </template>
        <template v-slot:body-cell-attributes="props">
          <td>
            <q-icon v-if="props.row.type === 'Shared Service'" name="share" size="14px"></q-icon>
            <q-icon v-if="props.row.type === 'Stratum'" name="engp:engp-ico-stratum" size="14px" class="q-ml-xs" />
            <q-icon
              v-if="props.row.type === 'Certified Product'"
              name="engp:engp-ico-cert_product"
              size="14px"
              class="q-ml-xs"
            />
            <q-icon v-if="props.row.state === 'Planned'" name="engp:engp-ico-planned" size="14px" class="q-ml-xs" />
            <q-icon
              v-if="props.row.state === 'Deprecated'"
              name="engp:engp-ico-deprecated"
              size="14px"
              class="q-ml-xs"
            />
          </td>
        </template>
        <template v-slot:body-cell-actions="props">
          <td>
            <q-btn
              v-if="notCanBeDeleted(props.row)"
              class="btn--no-hover"
              size="12px"
              style="cursor: not-allowed"
              icon="lock"
              flat
              padding="0"
              :ripple="false"
            >
              <q-tooltip>Can not be removed from service design</q-tooltip>
            </q-btn>
            <span v-else>
              <q-spinner v-if="addServiceLoadId === props.row.id" size="1.5em" class="q-mr-xs"></q-spinner>
              <q-btn
                v-else-if="props.row.orderable ? !isInDesign(props.row) : false"
                @click="addToDesign(props.row)"
                class="btn--no-hover"
                :icon="isInDesign(props.row) ? 'check' : 'add'"
                flat
                :disable="
                  !currentService ||
                  serviceErrors[props.row.id] ||
                  uiConfig?.disableEditServices?.includes(props.row.apmid) ||
                  uiConfig?.hideServices?.includes(props.row.apmid)
                "
                padding="0"
                :ripple="false"
              >
                <q-tooltip v-if="serviceErrors[props.row.id]">{{ serviceErrors[props.row.id] }}</q-tooltip>
                <q-tooltip v-else-if="!currentService">Please choose digital service</q-tooltip>
                <q-tooltip v-else-if="!isInDesign(props.row)">Add to Service Design</q-tooltip>
              </q-btn>
              <q-btn
                v-else-if="props.row.orderable ? isInDesign(props.row) : false"
                @click="removeFromServiceDesign(props.row)"
                class="btn--no-hover"
                icon="remove"
                flat
                padding="0"
                :ripple="false"
              >
                <q-tooltip>Remove from Service Design</q-tooltip>
              </q-btn>
            </span>
            <q-btn
              @click="goToInfraService(props.row)"
              class="btn--no-hover"
              icon="more_horiz"
              flat
              padding="0"
              :ripple="false"
            >
              <q-tooltip>More Info</q-tooltip>
            </q-btn>
          </td>
        </template>
      </q-table>
    </div>
  </q-page>
</template>

<script>
import { marked } from 'marked';
import { mapGetters, mapActions } from 'vuex';
import emitter from 'tiny-emitter/instance';
import { scroll } from 'quasar';
import { SERVICES_ACTIONS, SERVICES_GETTERS } from '@/store/modules/services';
import { SERVICE_DESIGN_ACTIONS, SERVICE_DESIGN_GETTERS } from '@/store/modules/service-design';
import { DIGITAL_SERVICES_GETTERS } from '@/store/modules/digital-services';
import { BANNERS_GETTERS } from '@/store/modules/banners';
import { APP_ACTIONS, APP_GETTERS } from '@/store/modules/app';
import { getUserPermissions } from '@/pages/digitalService/api';
import ServiceCategory from './components/ServiceCategory.vue';
import PageView from './components/PageView.vue';
import CatalogFilter from './components/CatalogFilter.vue';
import * as uiConfig from '@/shared/ui-config.json';
import { preCreateDraft } from '@/pages/digitalService/api';

const { getScrollTarget, setVerticalScrollPosition } = scroll;

export default {
  name: 'ServiceCatalog',
  components: { ServiceCategory, PageView, CatalogFilter },
  marked,
  data() {
    return {
      uiConfig: {},
      isServiceDesignOpen: false,
      isAuth: false,
      initLoad: false,
      title: this.$route.meta.title,
      breadcrumbs: [],
      filterLoading: false,
      filters: {
        attributes: {
          sharedServices: false,
          stratum: false,
          certified: false,
          planned: false,
          deprecated: false,
        },
        serviceTypes: {
          public: false,
          private: false,
          onPrem: false,
          tools: false,
          independent: false,
          notAvailable: false,
        },
        pavedRoad: false,
      },
      pageView: 'grid',
      tableFilters: {
        displayName: undefined,
        apmid: undefined,
        categoryName: undefined,
      },
      cols: [
        {
          label: 'Name',
          name: 'displayName',
          field: 'displayName',
          align: 'left',
          sortable: true,
        },
        {
          label: 'APMID',
          name: 'apmid',
          field: 'apmid',
          align: 'left',
          sortable: true,
        },
        {
          label: 'Category',
          name: 'categoryName',
          field: 'categoryName',
          align: 'left',
          sortable: true,
        },
        {
          label: 'Cloud type',
          name: 'cloudType',
          field: 'cloudType',
          align: 'left',
          sortable: true,
        },
        {
          label: 'Attributes',
          name: 'attributes',
          field: 'attributes',
          align: 'left',
        },
        {
          label: '',
          name: 'actions',
          field: 'actions',
          align: 'left',
        },
      ],
      addServiceLoadId: undefined,
      serviceErrors: {},
    };
  },
  created() {
    const savedView = localStorage.getItem('pageView');
    if (savedView) {
      this.pageView = savedView;
    }
    emitter.on('isServiceDesignOpen', (flag) => {
      this.isServiceDesignOpen = flag;
    });
    emitter.on('goToCategory', (apmId) => {
      const element = this.$refs[apmId][0].$el;
      const target = getScrollTarget(element);
      const offset = element.offsetTop - (this.currentBanner ? 95 : 45);
      const duration = 1000;
      setVerticalScrollPosition(target, offset, duration);
    });
  },

  async mounted() {
    this.uiConfig = uiConfig[process.env.VUE_APP_ENV];
    if (!this.catalogServices.length) {
      await this.load();
    }
    const authData = {
      userEmail: this.userInfo.email,
      scope: {},
      resource: {
        type: 'management',
        name: 'service.catalog.manage',
      },
    };
    this.isAuth = await getUserPermissions(authData);
    this.initLoad = true;
    this.breadcrumbs = this.$route.meta.breadcrumbs;
  },
  unmounted() {
    emitter.off('goToCategory');
    emitter.off('isServiceDesignOpen');
  },
  computed: {
    ...mapGetters('services', {
      catalogServices: SERVICES_GETTERS.ALL,
      wizardCategories: SERVICES_GETTERS.WIZARD_CATEGORIES,
      catalogCategories: SERVICES_GETTERS.CATEGORIES,
    }),
    ...mapGetters('digitalServices', {
      currentService: DIGITAL_SERVICES_GETTERS.CURRENT_SERVICE,
    }),
    ...mapGetters('serviceDesign', {
      design: SERVICE_DESIGN_GETTERS.ALL,
    }),
    ...mapGetters('banners', {
      currentBanner: BANNERS_GETTERS.CURRENT,
    }),
    ...mapGetters('app', {
      userInfo: APP_GETTERS.USER_INFO,
    }),
    canReset() {
      return (
        Object.values(this.filters.attributes).includes(true) ||
        Object.values(this.filters.serviceTypes).includes(true) ||
        this.filters.pavedRoad
      );
    },
    categories() {
      return this.catalogCategories.map((i) => i.name);
    },
    filteredInfraServices() {
      return Object.values(this.allInfraServices).filter((service) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const prop in this.tableFilters) {
          if (this.tableFilters[prop]) {
            if (!new RegExp(this.tableFilters[prop], 'i').test(service[prop])) {
              return false;
            }
          }
        }
        return true;
      });
    },
    allInfraServices() {
      const allInfraServices = [];
      this.catalogServices.forEach((category) => {
        if (category?.infraServices.length) {
          category.infraServices.forEach((infra) => {
            infra.categoryName = category.name;
            infra.categoryId = category.categoryId;
            allInfraServices.push(infra);
          });
        }
        if (category?.serviceCategories.length) {
          category.serviceCategories.forEach((subCategory) => {
            if (subCategory.infraServices.length) {
              subCategory.infraServices.forEach((infra) => {
                infra.categoryName = category.name + '. ' + subCategory.name;
                infra.categoryId = category.categoryId;
                allInfraServices.push(infra);
              });
            }
          });
        }
      });
      return allInfraServices;
    },
  },
  methods: {
    ...mapActions('services', {
      getCatalog: SERVICES_ACTIONS.GET_SERVICES,
    }),
    ...mapActions('app', {
      setEditGroup: APP_ACTIONS.SET_INFRA_SERVICE_EDIT_GROUP,
    }),
    ...mapActions('serviceDesign', {
      setInfraService: SERVICE_DESIGN_ACTIONS.SET_INFRASERVICE,
      removeInfraService: SERVICE_DESIGN_ACTIONS.REMOVE_INFRASERVICE,
    }),
    changePageView(view) {
      this.pageView = view;
      localStorage.setItem('pageView', view); // Save the view to local storage
    },
    notCanBeDeleted(service) {
      const category = this.design.find((category) => category.categoryId === service.categoryId);
      return category.infraServices.some((infra) => infra.apmid === service.apmid && infra.notCanBeDeleted);
    },
    isInDesign(service) {
      const category = this.design.find((category) => category.categoryId === service.categoryId);
      return category ? category.infraServices.some((infra) => infra.apmid === service.apmid) : false;
    },
    async addToDesign(service) {
      this.addServiceLoadId = service.id;
      try {
        const data = {
          infraServiceApmId: service.apmid,
          digitalServiceApmId: this.currentService.apmId,
        };
        await preCreateDraft(data);
        this.setInfraService({ category: { categoryId: service.categoryId }, infraService: service });
        this.$notify(
          'info',
          'Info',
          `${
            service.displayName || service.serviceName
          } was added to your service design. Please choose digital service if you would like to onboard onto it.`,
        );
      } catch (error) {
        this.serviceErrors[service.id] = error.message;
        this.$notify('negative', 'Error', error.message);
      } finally {
        this.addServiceLoadId = undefined;
      }
    },
    removeFromServiceDesign(service) {
      this.removeInfraService({ category: { categoryId: service.categoryId }, infraService: service });
      this.$notify(
        'info',
        'Info',
        `${this.dataService.displayName || this.dataService.serviceName} was removed from your service design`,
      );
    },
    goToInfraService(service) {
      this.$router.push(`/infra-services/${service.id}`);
    },
    goToNewService(category) {
      this.$router.push({ name: 'newInfraService', params: { category: category.categoryId } });
    },
    hasInfraServices(service) {
      return (
        service.serviceCategories &&
        service.serviceCategories.length &&
        !!service.serviceCategories.filter((category) => category.infraServices.length).length
      );
    },
    filterByType(type) {
      this.filters.serviceTypes[type] = !this.filters.serviceTypes[type];
      this.filterServices();
    },
    filterByAttribute(attr) {
      this.filters.attributes[attr] = !this.filters.attributes[attr];
      this.filterServices();
    },
    resetFilters() {
      this.filters.attributes = {
        stratum: false,
        certified: false,
        planned: false,
        deprecated: false,
      };
      this.filters.serviceTypes = {
        public: false,
        private: false,
        onPrem: false,
        tools: false,
        independent: false,
        notAvailable: false,
      };
      this.filters.pavedRoad = false;
      this.filterServices();
    },
    async filterServices() {
      this.filterLoading = true;
      try {
        await this.getCatalog(this.filters);
      } catch (error) {
        this.$notify('negative', 'Error', error.message);
      } finally {
        this.filterLoading = false;
      }
    },
    hasWizard(service) {
      return this.wizardCategories.includes(service.categoryId);
    },
    goToService(service, view) {
      this.$router.push({
        name: 'service',
        params: { id: service.categoryId, pageView: view },
      });
    },
    async load() {
      this.$showLoading();
      try {
        await this.getCatalog(this.filters);
      } catch (error) {
        this.$notify('negative', 'Error', error.message);
      } finally {
        this.$hideLoading();
        this.initLoad = true;
      }
    },
  },
};
</script>

<style lang="scss">
.infra-table {
  border-radius: 8px;
}
.service-holder {
  border-radius: 15px;
}
.start-btn {
  font-size: 30px;
  text-transform: capitalize !important;
}
.btn-group {
  margin: 0 -3px;
}
.service-action-btn {
  margin: 0 3px;
}
.service-action-btn:before {
  border: 2px solid var(--q-primary) !important;
}
.service-description {
  max-width: 480px;
}

.loading {
  position: fixed;
  left: 57px;
  top: 50px;
  right: 0;
  bottom: 0;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 7;
}
.infra-table {
  .display-name-cell {
    display: flex;
    align-items: center;
    .icon-service {
      display: flex;
      min-width: 45px;
      height: 45px;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      border-radius: 50%;
    }
  }
}
.design-open {
  .page-view {
    right: 483px;
  }
  .filter-holder {
    right: 500px;
  }
}
</style>
