<template>
  <span class="label-block q-mt-md" v-if="Object.values(infraService.links).length">Links:</span>
  <div class="links" v-if="Object.values(infraService.links).length">
    <Links :infraService="infraService" />
  </div>
  <div>
    <div v-if="!loadData" class="flex items-center justify-center q-py-lg">
      <q-spinner-gears size="40px" color="primary"></q-spinner-gears>
    </div>
    <div
      v-else-if="loadData && !Object.values(registrationData).length"
      class="flex full-width column items-center q-py-lg"
      style="padding: 30px 0"
    >
      <q-icon name="hide_source" class="q-mb-sm" size="40px" color="primary"></q-icon>
      <span class="block" style="font-size: 16px">No data</span>
    </div>
    <div v-else class="q-pt-xs">
      <EnvRegistration
        class="q-mt-lg q-mb-lg"
        :registrationId="registrationId"
        :registrationData="registrationData"
        :existEnvironments="environments"
      />
      <q-tabs
        v-model="infoTab"
        inline-label
        content-class="justify-start"
        class="q-mb-sm widget-tabs"
        indicator-color="primary"
      >
        <q-tab name="vnets" label="VNETs" />
        <q-tab name="zones" label="External DNS records" />
        <q-tab v-if="uiConfig?.showFirewallRules" name="rules" label="Firewall rules" />
      </q-tabs>
      <q-tab-panels v-model="infoTab" animated>
        <q-tab-panel name="vnets" class="q-px-none q-py-none">
          <Vnets :environments="environments" :registrationData="registrationData" :registrationId="registrationId" />
        </q-tab-panel>
        <q-tab-panel name="zones" class="q-px-none q-py-none">
          <ExternalDns :registrationData="registrationData" :registrationId="registrationId" />
        </q-tab-panel>
        <q-tab-panel v-if="uiConfig?.showFirewallRules" name="rules" class="q-px-none q-py-none">
          <FirewallRules :registrationData="registrationData" firewallRulesType="source" />
          <FirewallRules :registrationData="registrationData" firewallRulesType="destination" />
        </q-tab-panel>
      </q-tab-panels>
    </div>
  </div>
  <q-expansion-item class="access-products-item">
    <template v-slot:header>
      <strong>Access products</strong>
    </template>
    <div v-if="loadAccessProducts" class="flex items-center justify-center q-py-lg" style="padding: 30px 0">
      <q-spinner-gears size="40px" color="primary"></q-spinner-gears>
    </div>
    <div class="actions-table-holder q-mt-md" v-else-if="Object.keys(accessProducts).length">
      <access-products-table :accessProducts="accessProducts"></access-products-table>
    </div>
    <div v-else class="flex full-width column items-center q-py-lg" style="padding: 30px 0">
      <q-icon name="hide_source" class="q-mb-sm" size="40px" color="primary"></q-icon>
      <span class="block" style="font-size: 16px">No data</span>
    </div>
  </q-expansion-item>
</template>

<script>
import AccessProductsTable from '@/pages/digitalService/components/AccessProductsTable.vue';
import * as uiConfig from '@/shared/ui-config.json';
import { getRegistration } from '@/pages/registrations/api/api';
import Vnets from '@/pages/registrations/components/registration-network/components/Vnets.vue';
import ExternalDns from '@/pages/registrations/components/registration-network/components/ExternalDns.vue';
import FirewallRules from '@/pages/registrations/components/registration-network/components/FirewallRules.vue';
import EnvRegistration from '@/pages/registrations/components/EnvRegistration.vue';
import Links from './Links.vue';

export default {
  name: 'NetworkWidget',
  components: {
    AccessProductsTable,
    Vnets,
    ExternalDns,
    FirewallRules,
    EnvRegistration,
    Links,
  },
  props: {
    infraService: { type: Object },
    accessProducts: { type: Object },
    loadAccessProducts: { type: Boolean, default: () => false },
    registrationId: { type: String },
  },
  data() {
    return {
      tab: 'info',
      infoTab: 'vnets',
      uiConfig: {},
      registrationData: {},
      envTab: undefined,
    };
  },
  async mounted() {
    this.uiConfig = uiConfig[process.env.VUE_APP_ENV];
    try {
      const data = await getRegistration(this.registrationId, true, false);
      this.environments = data.environments;
      this.registrationData.digitalServiceApm = data.digitalService.apmId;
      this.registrationData.infrastructureServiceApm = data.infraService.apmId;
    } catch (error) {
      this.$notify('negative', 'Error', error.message);
    } finally {
      this.loadData = true;
    }
  },
};
</script>

<style scoped lang="scss">
.table-info {
  border-bottom: none;
}
.active-tab {
  background-color: #edeff5;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.scrollable-table {
  overflow-x: auto; /* Enables horizontal scrolling */
  max-width: 100%; /* Prevents unnecessary stretching */
}
</style>
