<template>
  <span class="label-block q-mt-md" v-if="Object.values(infraService.links).length">Links:</span>
  <div class="links" v-if="Object.values(infraService.links).length">
    <Links :infraService="infraService" />
  </div>
  <div v-if="!loadData" class="flex items-center justify-center q-py-lg">
    <q-spinner-gears size="40px" color="primary"></q-spinner-gears>
  </div>
  <div v-else class="q-pt-lg ccoe-widget">
    <q-expansion-item
      v-for="artifact in artifacts"
      :key="artifact.artifactName"
      group="artifact"
      @before-show="openPanel(artifact.artifactName)"
    >
      <template v-slot:header>
        <strong>{{ artifact.certifiedProductName }}</strong>
      </template>
      <div class="flex items-center">
        <span class="label-block">Certtified Products</span>
        <q-btn
          class="filter-icon q-ml-auto btn--no-hover q-px-none"
          :class="{ active: showFilters }"
          icon="filter_alt"
          flat
          :ripple="false"
          @click="showFilters = !showFilters"
        >
          <q-tooltip>{{ showFilters ? 'Hide filters' : 'Show filters' }}</q-tooltip>
        </q-btn>
      </div>
      <q-table
        :rows="rows"
        :columns="cols"
        :ref="`cloudenteZones-${artifact.artifactName}`"
        v-model:pagination="pagination"
        :loading="loading"
        binary-state-sort
        :rows-per-page-options="[5, 7, 10, 15, 20]"
        @request="loadProducts"
        flat
        row-key="resourceName"
        class="table-info external-dns-table"
      >
        <template v-slot:no-data>
          <div class="flex full-width column items-center q-py-lg">
            <q-icon name="mdi-cloud-search" class="q-mb-sm" size="40px" color="primary"></q-icon>
            <span class="block" style="font-size: 14px">No items were found.</span>
          </div>
        </template>
        <template v-slot:top>
          <div v-if="showFilters" class="row no-wrap full-width q-mt-sm q-mb-xs q-col-gutter-sm">
            <div class="col">
              <q-input
                v-model="filters.resourceName"
                label="Resource name"
                @update:model-value="filterTable"
                @clear="reLoadProducts()"
                outlined
                dense
                clearable
                class="q-mb-sm"
              ></q-input>
            </div>
            <div class="col">
              <q-input
                v-model="filters.resourceGroup"
                label="Resource group"
                @update:model-value="filterTable"
                @clear="reLoadProducts()"
                outlined
                dense
                clearable
                class="q-mb-sm"
              ></q-input>
            </div>
            <div class="col">
              <q-input
                v-model="filters.environment"
                label="Environment"
                @update:model-value="filterTable"
                @clear="reLoadProducts()"
                outlined
                dense
                clearable
                class="q-mb-sm"
              ></q-input>
            </div>
          </div>
        </template>
        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td v-for="col in props.cols" :key="col.name" :props="props">
              <q-btn
                v-if="col.name === 'actions'"
                size="sm"
                flat
                round
                dense
                @click="props.expand = !props.expand"
                icon="tag"
              >
                <q-tooltip>{{ props.expand ? 'Close tags info' : 'Show tags info' }}</q-tooltip>
              </q-btn>

              <span v-else>{{ col.value }}</span>
            </q-td>
          </q-tr>
          <q-tr v-show="props.expand" :props="props">
            <q-td colspan="100%" style="background: rgba(0, 0, 0, 0.03)">
              <div class="row">
                <div class="col col-6">
                  <table class="expand-table">
                    <tbody>
                      <tr>
                        <th class="text-left">Environment:</th>
                        <td class="text-left">{{ props.row.tags.environment }}</td>
                      </tr>
                      <tr>
                        <th class="text-left">Bundle Version:</th>
                        <td class="text-left">{{ props.row.tags.bundleVersion }}</td>
                      </tr>
                      <tr>
                        <th class="text-left">Created At:</th>
                        <td class="text-left">
                          {{ $options.dayjs(props.row.tags.resourceCreatedAt).format('DD.MM.YYYY HH:mm') }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col col-6">
                  <table class="expand-table">
                    <tbody>
                      <tr>
                        <th class="text-left">Creator:</th>
                        <td class="text-left">{{ props.row.tags.creator }}</td>
                      </tr>
                      <tr>
                        <th class="text-left">Product Version:</th>
                        <td class="text-left">{{ props.row.tags.productVersion }}</td>
                      </tr>
                      <tr>
                        <th class="text-left">Artifact Name:</th>
                        <td class="text-left">{{ props.row.tags.artifactName }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </q-expansion-item>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import Links from './Links.vue';
import { getCertifiedProducts, getCertifiedProductsArtifacts } from '@/pages/registrations/api/api';

export default {
  name: 'CertifiedProductWidget',
  components: {
    Links,
  },
  dayjs,
  props: {
    infraService: { type: Object },
    registrationId: { type: String },
  },
  data() {
    return {
      loadData: false,
      loading: false,
      showFilters: false,
      currentArtifact: undefined,
      artifacts: [],
      rows: [],
      timeout: undefined,
      filters: {
        resourceName: undefined,
        resourceGroup: undefined,
        environment: undefined,
      },
      pagination: {
        sortBy: 'createdAt',
        descending: false,
        page: 1,
        rowsPerPage: 5,
      },
      cols: [
        {
          label: 'Resource Name',
          name: 'resourceName',
          field: 'resourceName',
          align: 'left',
        },
        {
          label: 'Resource Group',
          name: 'resourceGroup',
          field: 'resourceGroup',
          align: 'left',
        },
        {
          label: 'Subscription Name',
          name: 'subscriptionName',
          field: 'subscriptionName',
          align: 'left',
        },
        {
          label: '',
          name: 'actions',
          field: 'actions',
        },
      ],
    };
  },
  methods: {
    filterTable() {
      this.loading = true;
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        this.reLoadProducts();
      }, 1500);
    },
    async loadProducts(props) {
      this.loading = true;
      try {
        const limit = props.pagination.rowsPerPage;
        const { sortBy, descending } = props.pagination;
        const skip = props.pagination.page === 1 ? 0 : (props.pagination.page - 1) * props.pagination.rowsPerPage;
        const { resourceGroup, resourceName, environment } = this.filters;
        const { count, data } = await getCertifiedProducts(
          this.registrationId,
          this.currentArtifact,
          limit,
          skip,
          sortBy,
          descending,
          resourceName,
          resourceGroup,
          environment,
        );
        this.pagination.rowsNumber = count;
        this.rows = data;
        this.pagination.sortBy = sortBy;
        this.pagination.descending = descending;
        this.pagination.page = props.pagination.page;
        this.pagination.rowsPerPage = props.pagination.rowsPerPage;
      } finally {
        this.loading = false;
      }
    },
    openPanel(artifact) {
      this.showFilters = false;
      this.filters.resourceGroup = undefined;
      this.filterTable.resourceName = undefined;
      this.filters.environment = undefined;
      this.currentArtifact = artifact;
      this.reLoadProducts();
    },
    reLoadProducts() {
      this.$refs[`cloudenteZones-${this.currentArtifact}`][0].requestServerInteraction();
    },
  },
  async mounted() {
    try {
      const { data } = await getCertifiedProductsArtifacts(this.registrationId);
      this.artifacts = data;
    } finally {
      this.loadData = true;
    }
  },
};
</script>

<style lang="scss">
.ccoe-widget {
  .label-block {
    font-size: 12px !important;
  }
  strong {
    display: block;
    font-size: 14px;
  }
  .q-table__bottom {
    border-top: none;
  }
  .table-info {
    border-bottom: none;

    .expand-table {
      tbody {
        th {
          padding: 0 10px;
        }
        td {
          border-bottom-width: 0 !important;
          height: 20px !important;
        }
      }
    }

    tbody td {
      height: 40px !important;
    }
  }

  .q-expansion-item {
    .q-item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 30px;
      border-radius: 8px;
      margin-bottom: 5px;
      padding: 8px !important;
      background-color: #f7faff;
    }
    .q-expansion-item__content {
      padding: 0 8px;
    }
  }
}
</style>
