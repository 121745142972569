<template>
  <ul
    v-for="(group, key) in Object.keys(infraService.links)"
    :key="key"
    :style="`width:${100 / Object.values(infraService.links).length}%`"
  >
    <span>{{ group }}</span>
    <li v-for="link in infraService.links[group]" :key="link.id">
      <a :href="link.url" target="_blank">{{ link.label }}</a>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'ServiceLinks',
  props: {
    infraService: {
      type: Object,
    },
  },
};
</script>
